import { Dispatch, Middleware, MiddlewareAPI } from "redux";
import { AppAction, AsyncActionType } from "../types";
import { API_REQUEST, DECREMENT_LOADING, INCREMENT_LOADING } from "../actionTypes/actionsTypes";

export const server = process.env.REACT_APP_INCOME_SERVICE_API || "http://localhost:4040";

const apiMiddleware: Middleware = (api: MiddlewareAPI) => (next: Dispatch) => (action: AppAction): AppAction => {
  const handleResponse = (response: Record<string, unknown>) => {
    const { ...rest } = action.payload;

    if (response.message && response.message === "Token Expired") {
      window.localStorage.clear();
      console.log("Token expired");
      // api.dispatch({
      //     type: LOGIN_AUTH.SUCCESS,
      //     payload: { token: null, user_profile_id: null, success: false }
      // })
    }
    api.dispatch({ type: DECREMENT_LOADING });
    api.dispatch({
      type: (action.payload.next as AsyncActionType).SUCCESS,
      payload: { ...rest, ...response, "status": response.status },
    });
  };

  if (action.type === API_REQUEST) {

    const doAuth = action.payload.authorization;
    let authHeader = {};
    if (doAuth) {
      // Registration token not stored in local storage
      if(typeof(doAuth) == "string"){
        authHeader = {
          "Authorization": doAuth
        }
      // Normal Authentication Token
      } else {
        const isLocalVariable = process.env.REACT_APP_IS_LOCAL;
        // eslint-disable-next-line
        if (isLocalVariable == "1") {
          authHeader = {
            "Authorization": process.env.REACT_APP_LOCAL_TOKEN
          }
        }
        else {
          authHeader = {
            "Authorization": window.localStorage.getItem("authToken")
          }
        }
      }
    }

    const requestParams: RequestInit  = {
        method: action.payload.method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            ...authHeader
        },
        body: JSON.stringify(action.payload.data),
        redirect: 'follow', // manual, *follow, error
    }

    fetch(`${server}/${action.payload.url}`, requestParams)
    .then(response => {
      return new Promise<Record<string, unknown>>((resolve, reject) => {
        response.json().then((responseObj: Record<string, unknown>) => {
          resolve({...responseObj, "status": response.status, "response": {...responseObj}} as Record<string, unknown>)
        }).catch(error => reject(error))
      })
    })
    .then(handleResponse)
    .catch(error => {
        const errorRes = {
          status: error.status,
          body: error
        }
        api.dispatch({ type: DECREMENT_LOADING });
        api.dispatch({ type: (action.payload.next as AsyncActionType).ERROR, errorRes });
    });
    api.dispatch({ type: INCREMENT_LOADING });
    api.dispatch({ type: (action.payload.next as AsyncActionType).PENDING });
  }
  console.log(action);
  return next(action);
};

export default apiMiddleware; 
