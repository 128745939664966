import { GET_PROFILE_ATTRIBUTES, RESET_GET_PROFILE_ATTRIBUTES } from "../../../actionTypes/actionsTypes";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../../types";
import { initialProfileAttributes, ProfileAttributes } from "../../../../types/profile/ProfileAttributes";

export interface ProfileAttributesState extends BaseAsyncReducerState {
    success: boolean,
    message: string,
    data: ProfileAttributes
}


const initialState: ProfileAttributesState = {
    success: false,
    message: "",
    data: initialProfileAttributes,
    loading: false,
    error: false,
}

function getProfileAttributesReducer(state = initialState, action: AppAction | LocalAction) {
    switch (action.type) {
        case GET_PROFILE_ATTRIBUTES.PENDING:
            return {
                loading: true,
                error: false,
                success: false,
            }
        case GET_PROFILE_ATTRIBUTES.SUCCESS:
            return {
                ...state,
                success: (action as AppAction).payload.success,
                data: (action as AppAction).payload.data,
                loading: false,
                error: false,
            }
        case GET_PROFILE_ATTRIBUTES.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
            }

        case RESET_GET_PROFILE_ATTRIBUTES:
            return {
                ...initialState
            }
        default:
            return state
    }
}

export default getProfileAttributesReducer