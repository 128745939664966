import React from 'react'
import './AnchorAsButton.css'

interface AnchorAsButtonProps {
    onClick?: () => void;
    children?: React.ReactNode;
    className?: string;
}

function AnchorAsButton(props: AnchorAsButtonProps): JSX.Element {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(props.onClick) {
            props.onClick()
        }

        const buttonEle = event.target as HTMLButtonElement
        buttonEle.blur()
    }
    const originalClassName = "anchor-to-button"
    const combinedClassName = props.className ? `${originalClassName} ${props.className}` : originalClassName
    return (
        <button onClick={handleClick} className={combinedClassName}>{props.children}</button>
    )
}

export default AnchorAsButton