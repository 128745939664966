import { createStore, applyMiddleware} from 'redux';
import thunk from "redux-thunk";
import apiMiddleware from './middleware/api';
import { rootReducer } from './reducers/root';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'pier-persist-store',
    storage,
    whitelist: ['lookupsReducer']
}

const middleware = [apiMiddleware, thunk];
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(...middleware));

// if (process.env.NODE_ENV !== 'production') {
//     middleware = [...middleware, logger];
// } else {
//     middleware = [...middleware];
// }
const persistedStore = persistStore(store);
export default store;
export { persistedStore };