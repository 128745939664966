import React, { PropsWithChildren } from "react";
import "./LoadingSpinner.css";

interface LoadingSpinnerProps extends PropsWithChildren {
    loading: boolean
}

export default function LoadingSpinner(props: LoadingSpinnerProps): JSX.Element {
    return (
        <div className="spinner-container">
            {props.children}
            <div className={`loading-spinner-parent ${props.loading ? "" : "not-loading"}`}>
                <div className={props.loading ? "loading-spinner" : ""}>
                </div>
            </div>
        </div>
    );
}
