import { CampaignListByRoll } from "../../../types/profile/CampaignListByRoll";
import { RESET_SEARCH_PROFILE_BY_ROLL, SEARCH_PROFILE_BY_ROLL } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types";

export interface ProfileByRollState extends BaseAsyncReducerState {
  success: boolean,
  message: string,
  campaigns?: CampaignListByRoll[],
  profileID: number,
  profileTypeCode: string
}

const initialState: ProfileByRollState = {
  success: false,
  message: "",
  loading: false,
  error: false,
  profileID: 0,
  profileTypeCode: ""
}

function getProfileByRollReducer(state = initialState, action: AppAction | LocalAction) {
  if (action.type === RESET_SEARCH_PROFILE_BY_ROLL) {
    return initialState;
  } else {
    return defaultAsyncReducer(state, action as AppAction, SEARCH_PROFILE_BY_ROLL)
  }
}

export default getProfileByRollReducer;