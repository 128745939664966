import React from 'react'
import { useTranslation } from 'react-i18next';
import TableRow, { TableCellData } from '../TableRow/TableRow';
import DataTable from '../DataTable/DataTable';


interface RentInclusionsProps {
    data: Record<string, boolean | null>;
    onChange: (name: string, value: string | number | boolean | null) => void;
    title: string;
}

function RentInclusions(props: RentInclusionsProps) {
    const { t } = useTranslation(['rent_inclusions']); 

    const inclusionsHeadings = [
        <th id='inclusions-vertical-header' key="inclusions-column-1-header" className='pier-datatable-centered-th'>{t(props.title)}</th>,
        <th title={t("titleHeat")} id='heat' key="inclusions-column-2-header" className='pier-datatable-centered-th'>{t("headerHeat")}</th>,
        <th title={t("titleHydro")} id='hydro' key="inclusions-column-3-header" className='pier-datatable-centered-th'>{t("headerHydro")}</th>,
        <th title={t("titleWater")} id='water' key="inclusions-column-4-header" className='pier-datatable-centered-th'>{t("headerWater")}</th>,
        <th title={t("titleCable")} id='cable' key="inclusions-column-5-header" className='pier-datatable-centered-th'>{t("headerCable")}</th>,
        <th title={t("titleInternet")} id='internet' key="inclusions-column-6-header" className='pier-datatable-centered-th'>{t("headerInternet")}</th>,
        <th title={t("titleFood")} id='food' key="inclusions-column-7-header" className='pier-datatable-centered-th'>{t("headerFood")}</th>,
        <th title={t("titleHousekeeping")} id='housekeeping' key="inclusions-column-8-header" className='pier-datatable-centered-th'>{t("headerHousekeeping")}</th>,
        <th title={t("titleActivities")} id='activities' key="inclusions-column-9-header" className='pier-datatable-centered-th'>{t("headerActivities")}</th>,
        <th title={t("titleCare")} id='care' key="inclusions-column-10-header" className='pier-datatable-centered-th'>{t("headerCare")}</th>
    ];
    const keys = Object.keys(props.data);
    const inclusions: TableCellData[] = [
        { name: keys.filter((key) => key.includes("Heat"))[0], inputType: "checkbox", header: "heat" },
        { name: keys.filter((key) => key.includes("Hydro"))[0], inputType: "checkbox", header: "hydro" },
        { name: keys.filter((key) => key.includes("Water"))[0], inputType: "checkbox", header: "water" },
        { name: keys.filter((key) => key.includes("Cable"))[0], inputType: "checkbox", header: "cable" },
        { name: keys.filter((key) => key.includes("Internet"))[0], inputType: "checkbox", header: "internet" },
        { name: keys.filter((key) => key.includes("Food"))[0], inputType: "checkbox", header: "food" },
        { name: keys.filter((key) => key.includes("Housekeeping"))[0], inputType: "checkbox", header: "housekeeping" },
        { name: keys.filter((key) => key.includes("Activity"))[0], inputType: "checkbox", header: "activities" },
        { name: keys.filter((key) => key.includes("CareService"))[0], inputType: "checkbox", header: "care" },
    ]


    return (
        <DataTable headings={inclusionsHeadings}>
            <TableRow rowLabel={t("verticalHeaderRentIncl")} cellData={inclusions} dataType={props.data} onChange={props.onChange}/>
        </DataTable>
    )
}

export default RentInclusions