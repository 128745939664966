import React from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import Checkbox from '../common/Checkbox/Checkbox';

interface OperatingExpensesWizardProps {
    updateWizardFunction : (e: React.ChangeEvent<Element>, i: number, f: string, n?: boolean, c?: boolean) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    // for pagination
    totalPages: number | undefined;
    currentPage: number;
    changePage: (e: number) => void
}

export default function OperatingExpensesWizard(props: OperatingExpensesWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const operatingExpensesList = [
        {"value": "includeInsuranceFlag", "text": t("operating-expenses-wizard.insurance")},
        {"value": "includeManagementFlag", "text": t("operating-expenses-wizard.management")},
        {"value": "includeMaintenanceFlag", "text": t("operating-expenses-wizard.maintenance")},
        {"value": "includeHydroFlag", "text": t("operating-expenses-wizard.hydro")},
        {"value": "includeWaterFlag", "text": t("operating-expenses-wizard.water")},
        {"value": "includeHvacFlag", "text": t("operating-expenses-wizard.hvac")}
    ];
    const headings = [<th key="operating-expenses-wizard-heading-1">{t("unit-heading.floor-level")}</th>,
        <th key="operating-expenses-wizard-heading-2">{t("unit-heading.tenant-name")}</th>,
        <th key="operating-expenses-wizard-heading-3">{t("unit-heading.unit-number")}</th>,
        <th key="operating-expenses-wizard-heading-4">{t("unit-heading.operating-expenses") + " " + t("unit-heading.select-multiple")}</th>]

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            for (let index = 10 * (props.currentPage - 1); index < Math.min(props.tenantList.length, 10 * props.currentPage); index++) {
                tableBody.push (<tr key={`operating-expenses-wizard-row-${index}`}>
                    <td className='no-input-cell'><label>{props.floorDescriptionLookup[props.tenantList[index]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell tenant-name-cell'><p>{props.tenantList[index]["tenantName"] as string}</p></td>
                    <td className='no-input-cell tenant-unit-cell'><p>{props.tenantList[index]["tenantUnitNumber"] as string}</p></td>
                    <td className="wizard-grey-out-cell">
                        {(props.tenantList[index]["leaseTypeCode"] !== "1" && props.tenantList[index]["leaseStatusCode"] !== "1") &&<div className='wizard-radio-cell'>
                        {operatingExpensesList.map((operatingExpense) => 
                            <><Checkbox key={`operating-expenses-wizard-${index}-${operatingExpense["value"]}`} name={`operating-expenses-wizard-${index}-${operatingExpense["value"]}`} id={`operating-expenses-wizard-${index}-${operatingExpense["value"]}`}
                                defaultChecked={props.tenantList[index][operatingExpense["value"]] === true} onChange={(e) => props.updateWizardFunction(e, index, operatingExpense["value"], false, true)}/>
                            <label htmlFor={`operating-expenses-wizard-${index}-${operatingExpense["value"] as string}`}>{operatingExpense["text"] as string}</label></>)
                        }
                        </div>}
                    </td>
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <p>{t("operating-expenses-wizard.operating-expenses-description")}</p>
        <DataTable headings={headings} className='wizard-table' totalPages={props.totalPages} currentPage={props.currentPage} changePage={props.changePage}>
            {buildTableContent()}
        </DataTable>
    </div>)
}