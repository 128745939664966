import { SET_SAVING, RESET_SAVING, RESET_SAVEDATA, RESET_SAVE_RESULT, SET_SAVE_RESULT_SUCCESS } from "../../actionTypes/actionsTypes";
import { LocalAction } from "../../types";

// export const setChangesMade = (): LocalAction => ({
//     type: CHANGES_MADE
// });

// export const resetChangesMade = (): LocalAction => ({
//     type: RESET_CHANGES_MADE
// });

export const setSaving = (): LocalAction => ({
    type: SET_SAVING
});

export const resetSaving = (): LocalAction => ({
    type: RESET_SAVING
});

export const resetSaveData = (): LocalAction => ({
    type: RESET_SAVEDATA
});

// export const setValid = (valid: boolean): LocalAction => ({
//     type: SET_VALID,
//     data: valid
// });

export const resetSaveResult = (): LocalAction => ({
    type: RESET_SAVE_RESULT
});

export const setSaveResultSuccess = (): LocalAction => ({
    type: SET_SAVE_RESULT_SUCCESS
});