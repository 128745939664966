import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../common/Checkbox/Checkbox';
import ConfirmDialog from './ConfirmDialog';
import { allOtherOptions, farmingOption, noFormSelectedOption } from '../../pages/BusinessOperations/BusinessOperations';

export default function Overview(props: {
    assessmentInformation: Record<string, Record<string, unknown>>,
    changeSelection: (operationKey: string, data: Record<string, unknown>) => void,
    validating: boolean,
    validate: (operationKey: string, valid: boolean) => void,
    errors: Record<string, string>,
    setErrors: (operationKey: string, operationErrors: Record<string, string>) => void,
}): JSX.Element {
    const { t } = useTranslation('gev')
    const [disables, setDisables] = useState<Record<string, boolean>>({})
    const [changingOperation, setChangingOperation] = useState("")
    const [showConfirmSelectDialog, setShowConfirmSelectDialog] = useState(false)
    const [showConfirmUnselectDialog, setShowConfirmUnselectDialog] = useState(false)

    useEffect(() => {
        if (props.validating) {
            if (localStorage.getItem("isInternalUser")) {
                props.validate("overview", true)
                return
            }

            let valid = false
            if (props.assessmentInformation) {
                valid = allOtherOptions.concat(farmingOption).concat(noFormSelectedOption).some((key) => props.assessmentInformation[key] && props.assessmentInformation[key]["selected"])
            }
            if (valid === false) {
                props.setErrors("overview", { "operations": t("missingRequiredField") })
            }
            props.validate("overview", valid)
        }
    }, [props, t])

    const selectOperation = (operationKey: string) => {
        const data = structuredClone(props.assessmentInformation[operationKey])
        if (!data["selected"]) {
            data["selected"] = true
            props.changeSelection(operationKey, data)
        }
    }

    const unselectOperation = (operationKey: string) => {
        const data = structuredClone(props.assessmentInformation[operationKey])
        if (data["selected"]) {
            data["selected"] = false
            props.changeSelection(operationKey, data)
        }
    }

    const changeSelection = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target
        const targetName = target.name
        setChangingOperation(targetName)
        if (target.checked) {
            if (targetName === noFormSelectedOption
                && allOtherOptions.concat(farmingOption).some((key) => props.assessmentInformation[key]["selected"])) {
                setShowConfirmSelectDialog(true)
            } else {
                selectOperation(targetName)
            }
        } else {
            setShowConfirmUnselectDialog(true)
        }
    }

    useEffect(() => {

        const calcSelection = () => {
            const disables: Record<string, boolean> = {}
            Object.keys(props.assessmentInformation).forEach((key) => {
                disables[key] = false
            })

            if (props.assessmentInformation[noFormSelectedOption]["selected"]) {
                allOtherOptions.concat(farmingOption).forEach((key) => {
                    disables[key] = true
                    unselectOperation(key)
                })
                setDisables(disables)
                return
            }

            if (props.assessmentInformation[farmingOption]["selected"]) {
                allOtherOptions.forEach((key) => {
                    disables[key] = true
                    unselectOperation(key)
                })
            } else if (allOtherOptions.some((key) => props.assessmentInformation[key]["selected"])) {
                disables[farmingOption] = true
                unselectOperation(farmingOption)
            } else {
                //
            }
            setDisables(disables)
        }

        if (Object.keys(props.assessmentInformation).length) {
            calcSelection()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.assessmentInformation])

    return (
        <>
            <div>
                <p className='strong'>{t("overview.heading")}</p>
                <form>
                    {
                        Object.keys(props.assessmentInformation).length > 0 &&
                        <table role="none">
                            <tbody>
                                {
                                    allOtherOptions.concat(farmingOption).concat(noFormSelectedOption).map((key) => {
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <div className='flex flex-row align-items-center'>
                                                        <Checkbox name={key}
                                                            checked={props.assessmentInformation[key]["selected"] as boolean}
                                                            onChange={(e) => changeSelection(e)}
                                                            disabled={disables[key]}
                                                        />
                                                        <div className='padding-left-10'>
                                                            {t('overview.' + key)}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                    <div className="input-error-message">
                        {props.errors && props.errors["operations"]}
                    </div>
                </form>
            </div>
            {
                showConfirmUnselectDialog &&
                <ConfirmDialog onClose={() => setShowConfirmUnselectDialog(false)}
                    onSubmit={() => { unselectOperation(changingOperation); setShowConfirmUnselectDialog(false); }}
                    title={t('overview.areYouSureUnselect') + "'" + t('overview.' + changingOperation) + "'?"}
                    body={t('overview.ifUnselect')}
                />
            }
            {
                showConfirmSelectDialog &&
                <ConfirmDialog onClose={() => setShowConfirmSelectDialog(false)}
                    onSubmit={() => { selectOperation(changingOperation); setShowConfirmSelectDialog(false); }}
                    title={t('overview.areYouSureSelect') + "'" + t('overview.' + changingOperation) + "'?"}
                    body={t('overview.ifSelect')}
                />
            }
        </>
    )
}
