import { API_REQUEST, VALIDATE_INTERNAL_TOKEN, VALIDATE_TOKEN } from "../../actionTypes/actionsTypes";
import { AppAction } from "../../types";

export const validateToken = (token: string): AppAction => ({
    type: API_REQUEST,
    payload: {
        url: '/login',
        next: VALIDATE_TOKEN,
        method: 'POST',
        data: {
            "token": token
        }
    }
});

export const validateInternalToken = (token: string): AppAction => ({
    type: API_REQUEST,
    payload: {
        url: '/verify-internal-token',
        next: VALIDATE_INTERNAL_TOKEN,
        method: 'POST',
        data: {
            "token": token
        }
    }
});