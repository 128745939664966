
import { parseISO } from 'date-fns';
import { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';

const isRequiredValid = (field: string, data: Record<string, unknown>) => {
    let value = data[field];
    if (typeof value === "string" || value instanceof String) {
        value = value.trim();
    }

    if (localStorage.getItem("isInternalUser")) {
        return true;
    }

    if (data[field]) {
        return true;
    } else {
        return false;
    }
}

const validateRequired = (field: string, data: Record<string, unknown>, errors: Record<string, string>, t: TFunction) => {
    if (isRequiredValid(field, data)) {
        errors = { ...errors, [field]: "" };
    } else {
        errors = { ...errors, [field]: t('validation.' + field + 'Required') };
    }
    return errors;
}

const validateRequiredAndMaxLength = (field: string, data: Record<string, unknown>, errors: Record<string, string>, t: TFunction, maxLength: number) => {
    if (isRequiredValid(field, data)) {
        if (localStorage.getItem("isInternalUser")) {
            errors = { ...errors, [field]: "" };
        } else {
            if (data[field] && (data[field] as string).length > maxLength) {
                errors = { ...errors, [field]: t('validation.' + field + 'Required') };
            } else {
                errors = { ...errors, [field]: "" };
            }
        }
    } else {
        errors = { ...errors, [field]: t('validation.' + field + 'Required') };
    }
    return errors;
}

const validateRequiredAndNegative = (field: string, data: Record<string, unknown>, errors: Record<string, string>, t: TFunction) => {
    if (isRequiredValid(field, data)) {
        if (data[field] < 0) {
            errors = { ...errors, [field]: t('validation.' + field + 'Negative') };
        } else {
            errors = { ...errors, [field]: "" };
        }
    } else {
        errors = { ...errors, [field]: t('validation.' + field + 'Required') };
    }
    return errors;
}

const validateDates = (data: Record<string, unknown>, errors: Record<string, string>, t: TFunction) => {
    const dateErrors = { ...errors, "startDate": "", "endDate": "", "negotiatedDate": "" };
    if (localStorage.getItem("isInternalUser")) {
        return dateErrors;
    }

    if (!data.startDate) {
        dateErrors.startDate = t('validation.startDateRequired');
    } else if ((parseISO(data.startDate as string)).getFullYear() > (new Date().getFullYear())) {
        dateErrors.startDate = t('validation.startDateCurrentYear');
    }
    if (data.endDate) {
        if (parseISO(data.endDate as string) <= parseISO("2019-06-01")) {
            dateErrors.endDate = t('validation.endDateBeforeCampaignDate');
        }
    }
    if (data.startDate && data.endDate) {
        if (parseISO(data.endDate as string) <= parseISO(data.startDate as string)) {
            dateErrors.endDate = t('validation.endDateNotValid');
        }
    }
    if (data.startDate && data.negotiatedDate) {
        if (parseISO(data.negotiatedDate as string) >= parseISO(data.startDate as string)) {
            dateErrors.negotiatedDate = t('validation.negotiatedDateNotValid');
        }
    }
    return dateErrors;
}

const validateNegative = (field: string, data: Record<string, unknown>, errors: Record<string, string>, t: TFunction) => {
    if (data[field] < 0) {
        errors = { ...errors, [field]: t('validation.' + field + 'Negative') };
    } else {
        errors = { ...errors, [field]: "" };
    }
    return errors;
}

export function validateToastVacant(data: Record<string, unknown>, t: TFunction) {
    if (data["leaseStatusCode"] === "1" && data["startDate"] === undefined) {
        toast.warn(t("toastMessage.startDateEntryReminder") as string);
    }
}

export function validateToastNet(data: Record<string, unknown>, t: TFunction) {
    if (data["leaseTypeCode"] === "1"
        && (data["propertyTaxRecovery"] === undefined || data["operatingRecovery"] === undefined)
    ) {
        toast.info(t("toastMessage.leaseTypeNetReminder") as string);
    }
}

export function validateField(field: string, data: Record<string, unknown>, errors: Record<string, string>, t: TFunction) {
    if (field) {
        switch (field) {
            case "tenantName":
                errors = validateRequiredAndMaxLength(field, data, errors, t, 100);
                break
            case "tenantUnitNumber":
                errors = validateRequiredAndMaxLength(field, data, errors, t, 50);
                break;
            case "netLeasableArea":
                errors = validateRequiredAndNegative(field, data, errors, t);
                break;
            case "annualRent":
            case "currentBaseRent":
                if (data["leaseStatusCode"] !== "1") {
                    errors = validateRequiredAndNegative(field, data, errors, t);
                } else {
                    errors = { ...errors, [field]: ""};
                }
                break;
            case "unitSubtypeCode":
            case "leaseTypeCode":
                if (data["leaseStatusCode"] !== "1") {
                    errors = validateRequired(field, data, errors, t);
                } else {
                    errors = { ...errors, [field]: ""};
                }
                break;
            case "leaseStatusCode":
                errors = validateRequired(field, data, errors, t);
                if (data["leaseStatusCode"] !== "1") {
                    errors = validateRequiredAndNegative("annualRent", data, errors, t);
                    errors = validateRequiredAndNegative("currentBaseRent", data, errors, t);
                    errors = validateRequired("unitSubtypeCode", data, errors, t);
                    errors = validateRequired("leaseTypeCode", data, errors, t);
                } else {
                    errors = { ...errors, "annualRent": "", "currentBaseRent": "", "unitSubtypeCode": "", "leaseTypeCode": ""};
                }
                break;
            case "floorLevel":
                errors = validateRequired(field, data, errors, t);
                break;
            case "startDate":
            case "endDate":
            case "negotiatedDate":
                errors = validateDates(data, errors, t);
                break;
            case "propertyTaxRecovery":
            case "operatingRecovery":
            case "freeRentMonth":
            case "tenantImprovement":
            case "stepUpRate1":
            case "stepUpRate2":
            case "stepUpRate3":
            case "stepUpRate4":
                errors = validateNegative(field, data, errors, t);
                break;
            default:
                break;
        }
    }
    return errors;
}

export function validate(data: Record<string, unknown>, errors: Record<string, string>, t: TFunction) {
    const fields = [
        "tenantName",
        "tenantUnitNumber",
        "unitSubtypeCode",
        "leaseStatusCode",
        "leaseTypeCode",
        "netLeasableArea",
        "floorLevel",
        "annualRent",
        "currentBaseRent",
        "startDate",
        "propertyTaxRecovery",
        "operatingRecovery",
        "freeRentMonth",
        "tenantImprovement",
        "stepUpRate1",
        "stepUpRate2",
        "stepUpRate3",
        "stepUpRate4",
    ];
    fields.forEach((field) => {
        errors = validateField(field, data, errors, t);
    });
    validateToastVacant(data, t);
    validateToastNet(data, t);
    return errors;
}


