import React, { useEffect, useState } from 'react'
import './FontSizeSwapper.css'
import AnchorAsButton from '../AnchorAsButton/AnchorAsButton';

function FontSizeSwapper() {

  const storageFontSize = window.localStorage.getItem("pierFontSize");
  const [fontSize, setFontSize] = useState<"s" | "m" | "l">(
      storageFontSize && ["s", "m", "l"].includes(storageFontSize) ? 
          (storageFontSize as "s" | "m" | "l") : "s");

  const changeTextSize = (size: "s" | "m" | "l") => {
    setFontSize(size);
    switch (size) {
        case "l":
            document.documentElement.classList.remove("medium-text");
            document.documentElement.classList.add("large-text");
            break;
        case "m":
            document.documentElement.classList.remove("large-text");
            document.documentElement.classList.add("medium-text");
            break;
        default:
            document.documentElement.className = "";
    }
    size === "s" ? window.localStorage.removeItem("pierFontSize") : window.localStorage.setItem("pierFontSize", size);
  }
    
  useEffect(() => {
    changeTextSize(fontSize);
  }, [fontSize])



  return (
    <div className="aoda-text-size">
      <AnchorAsButton className='aoda-text-btn' onClick={() => changeTextSize("s")}>
        <img className="aoda-small" src={"/images/zoom_0_" + (fontSize === "s" ? "curr" : "off") + ".png"} alt="Small Font Size"/>
      </AnchorAsButton>
      <AnchorAsButton className='aoda-text-btn' onClick={() => changeTextSize("m")}>
        <img className="aoda-medium" src={"/images/zoom_1_" + (fontSize === "m" ? "curr" : "off") + ".png"} alt="Medium Font Size"/>
      </AnchorAsButton>
      <AnchorAsButton className='aoda-text-btn' onClick={() => changeTextSize("l")}>
        <img className="aoda-large" src={"/images/zoom_2_" + (fontSize === "l" ? "curr" : "off") + ".png"} alt="Large Font Size"/>
      </AnchorAsButton>
    </div>
  )
}

export default FontSizeSwapper