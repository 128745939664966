import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/root';
import { useTranslation } from 'react-i18next';
import OccupancyStatisticsData from '../../../types/occupancyStatistics/OccupancyStatisticsData';
import DataTable from '../../../components/common/DataTable/DataTable';
import TableRow, { TableCellData } from '../../../components/common/TableRow/TableRow';
import { resetSaving } from '../../../redux/actions/saveData/saveData';
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer';
import { getProfileAttributes, putProfileAttributes, resetGetProfileAttributes } from '../../../redux/actions/profile/profile';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer';
import { setNextActiveMenuItem } from '../../../redux/actions/menu/menu';
import { initialMenuItem } from '../../../redux/reducers/getMenu/getMenuReducer';
import { ProfileAttributesState } from '../../../redux/reducers/getProfile/profileAttributes/getAttributesReducer';
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer';

function OccupancyStatistics() {
  const dispatch = useDispatch();
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
  const profileAttributes: ProfileAttributesState = useSelector((state: RootState) => state.profileReducer.getProfileAttributes as ProfileAttributesState)
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
  const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult)
  const currentSnapshotId = Number(localStorage.getItem('currentSnapshotId') as string)
  const initialOccupancyStatisticsData: OccupancyStatisticsData = useMemo(() => ({
    potentialResidentDay: null,
    actualResidentDay: null,
    reportedVacancy: null,
    secondOccupantResidentDay: null,
    respiteDay: null,
  }), [])

  const [occupancyData, setOccupancyData] = useState<OccupancyStatisticsData>(initialOccupancyStatisticsData)
  const { t } = useTranslation('occupancy_statistics')

  const occupancyStatisticsHeadings = [
    <th scope="col" data-tip="The total number of suites available to actual or potential residents of the home multiplied by 365" id='potential-resident-days' className='pier-datatable-clear-th' key="occupancy-column-1-header">{t("Potential Resident Days")}</th>,
    <th scope="col" data-tip="The actual number of days (in a year) the suites were occupied" id='actual-resident-days' className='pier-datatable-clear-th' key="occupancy-column-2-header">{t("Actual Resident Days")}</th>,
    <th scope="col" data-tip="The % ratio of vacancy and collection loss (at market rental rates) to potential gross income" id='reported-vacancy-percent' className='pier-datatable-clear-th' key="occupancy-column-3-header">{t("Reported Vacancy Percent (%)")}</th>,
    <th scope="col" data-tip="The number of days that suites were occupied with a 2nd occupant" id='second-occupant-resident-days' className='pier-datatable-clear-th' key="occupancy-column-4-header">{t("Second Occupant Resident Days")}</th>,
    <th scope="col" data-tip="The numbers of days the suites were occupied for respite care" id='respite-days' className='pier-datatable-clear-th' key="occupancy-column-5-header">{t("Respite Days")}</th>,
  ];

  useEffect(() => {
    dispatch(resetGetProfileAttributes())
    dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (profileAttributes.success) {
      const newOccupancyData = {
        potentialResidentDay: profileAttributes.data.potentialResidentDay,
        actualResidentDay: profileAttributes.data.actualResidentDay,
        reportedVacancy: profileAttributes.data.reportedVacancy,
        secondOccupantResidentDay: profileAttributes.data.secondOccupantResidentDay,
        respiteDay: profileAttributes.data.respiteDay,
      }
      setOccupancyData(newOccupancyData)
    } else {
      setOccupancyData(initialOccupancyStatisticsData)
    }
  }, [profileAttributes, initialOccupancyStatisticsData])

  const occupancyStatisticsRow: TableCellData[] = [
    { name: "potentialResidentDay", type: "integer", header: "potential-resident-days", forced: true, positiveOnly: true },
    { name: "actualResidentDay", type: "integer", header: "actual-resident-days", forced: true, positiveOnly: true },
    { name: "reportedVacancy", type: "float", symbol: '%', header: "reported-vacancy-percent", forced: true, positiveOnly: true },
    { name: "secondOccupantResidentDay", type: "integer", header: "second-occupant-resident-days", forced: true, positiveOnly: true },
    { name: "respiteDay", type: "integer", header: "respite-days", forced: true, positiveOnly: true },
  ];

  const handleOccupancyStatisticsChange = (name: keyof OccupancyStatisticsData, value: string | boolean | number | null) => {
    setOccupancyData(oldOccupancyData => ({
      ...oldOccupancyData,
      [name]: value
    }))
  }

  const saveOccupancyData = useCallback(() => {
    if (!profileAttributes.loading && profileAttributes.data) {
      const fullOccupancyData = { ...profileAttributes.data, ...occupancyData }
      dispatch(putProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId, fullOccupancyData))
    } else {
      toast.warn(t("toastMessage.validationFailed") as string)
      dispatch(resetSaving())
      dispatch(setNextActiveMenuItem(initialMenuItem))
    }
  }, [currentSnapshotId, dispatch, occupancyData, profileAttributes.data, profileAttributes.loading, profileOverview.profile.profile_type_code, t])

  useEffect(() => {
    if (saveData.saving) {
      saveOccupancyData()
    }
  }, [saveData.saving, saveOccupancyData])

  useEffect(() => {
    if (saveResultState.status === 200) {
      dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId))
    }
  }, [currentSnapshotId, dispatch, profileOverview.profile.profile_type_code, saveResultState.status])

  return (
    <div>
      <ReactTooltip />
        <div>
          <h3>{t("Occupancy Statistics")}</h3>
          <DataTable headings={occupancyStatisticsHeadings}>
            <TableRow cellData={occupancyStatisticsRow} dataType={occupancyData} onChange={handleOccupancyStatisticsChange} />
          </DataTable>
        </div>
    </div>
  );
}

export default OccupancyStatistics
