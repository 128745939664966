import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../common/Checkbox/Checkbox';
import "./CommercialUnit.css";

interface OperatingExpenseProps {
    formData: Record<string, unknown>,
}

export default function OperatingExpense(props: OperatingExpenseProps): JSX.Element {
    const { t } = useTranslation('unit');

    return (
        <tbody>
            <tr>
                <td>
                    <div className='flex flex-row align-items-center'
                        title={t('tooltips.insurance-tooltip')} >
                        <Checkbox name="includeInsuranceFlag"
                            defaultChecked={props.formData.includeInsuranceFlag as boolean | undefined} />
                        <div className='padding-left-10'>
                            {t('headings.insurance')}
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div className='flex flex-row align-items-center'
                        title={t('tooltips.management-tooltip')} >
                        <Checkbox name="includeManagementFlag"
                            defaultChecked={props.formData.includeManagementFlag as boolean} />
                        <div className='padding-left-10'>
                            {t('headings.management')}
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div className='flex flex-row align-items-center'
                        title={t('tooltips.maintenance-tooltip')}>
                        <Checkbox name="includeMaintenanceFlag"
                            defaultChecked={props.formData.includeMaintenanceFlag as boolean} />
                        <div className='padding-left-10'>
                            {t('headings.maintenance')}
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className=''>
                    <div className='flex flex-row align-items-center'
                        title={t('tooltips.hydro-tooltip')} >
                        <Checkbox name="includeHydroFlag"
                            defaultChecked={props.formData.includeHydroFlag as boolean} />
                        <div className='padding-left-10'>
                            {t('headings.hydro')}
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className=''>
                    <div className='flex flex-row align-items-center'
                        title={t('tooltips.water-tooltip')} >
                        <Checkbox name="includeWaterFlag"
                            defaultChecked={props.formData.includeWaterFlag as boolean} />
                        <div className='padding-left-10'>
                            {t('headings.water')}
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className=''>
                    <div className='flex flex-row align-items-center'
                        title={t('tooltips.hvac-tooltip')} >
                        <Checkbox name="includeHvacFlag"
                            defaultChecked={props.formData.includeHvacFlag as boolean} />
                        <div className='padding-left-10'>
                            {t('headings.hvac')}
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    );
}
