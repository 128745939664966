import { GET_APARTMENT_TENANT_SUMMARY_BY_SNAPSHOT_ID } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState } from "../../types";


// Define an initial state value for the app
const initialState: BaseAsyncReducerState = {
    loading: false,
    error: false,
    success: false,
    data: []
}
  
function getApartmentTenantSummaryReducer(state = initialState, action: AppAction) {
    return defaultAsyncReducer(state, action, GET_APARTMENT_TENANT_SUMMARY_BY_SNAPSHOT_ID);
}

export default getApartmentTenantSummaryReducer;