import { SET_READ_ONLY } from "../../actionTypes/actionsTypes";
import { LocalAction } from "../../types";


export interface ReadOnlyState {
    isReadOnly: boolean;
}

const initialState: ReadOnlyState = {
    isReadOnly: false,
}

function getReadOnlyReducer(state = initialState, action: LocalAction) {
    switch (action.type) {
      case SET_READ_ONLY:
        return {
          ...state,
          isReadOnly: action.data
        }
      default:
        return state;
    }
  }
  
  export default getReadOnlyReducer;