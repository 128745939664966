/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 */
import { GET_ASSESSMENT_INFO, RESET_GET_ASSESSMENT_INFO } from "../../actionTypes/actionsTypes"
import defaultAsyncReducer from "../../defaultReducer"
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types"

type AssessmentInfoDataType = {
  snapshotId: number | string,
  assessmentInfo: Record<string, Record<string, unknown>>,
}

export interface AssessmentInfoState extends BaseAsyncReducerState {
  success: boolean,
  message: string,
  data: AssessmentInfoDataType
}

const initialState: AssessmentInfoState = {
  loading: false,
  success: false,
  error: false,
  message: "",
  data: {} as AssessmentInfoDataType,
}

function getAssessmentInfoReducer(state = initialState, action: AppAction | LocalAction) {
  if (action.type === RESET_GET_ASSESSMENT_INFO) {
    return initialState
  } else {
    return defaultAsyncReducer(state, action as AppAction, GET_ASSESSMENT_INFO)
  }
}

export default getAssessmentInfoReducer