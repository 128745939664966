import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PropsWithChildren, ReactElement } from "react";
import "./Modal.css";
import Button from "../Button/Button";

interface ModalProps extends PropsWithChildren {
    title?: string,
    onCloseModal: React.MouseEventHandler
}
function Modal(props: ModalProps): ReactElement {
    return (
        <div className="modal-bg">
            <div className="modal-container">
                <div className="modal-header">
                    <span className="modal-title">{props?.title}</span>
                    <div className="close-btn">
                        <Button forceEnabled={true} type="cancel" ariaLabel="cancel" onClick={(e) => props.onCloseModal(e)}>
                            <FontAwesomeIcon icon={faClose} />
                        </Button>
                    </div>
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default Modal;