import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import RentInclusions from '../../../components/common/RentInclusions/RentInclusions'
import SuiteBreakdownTable from '../../../components/common/SuiteBreakdownTable/SuiteBreakdownTable'
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/root'
import { deleteIncomeUnitById, getTenantSummaryBySnapshotId, postIncomeUnit, putIncomeUnit } from '../../../redux/actions/incomeUnit/incomeUnit'
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer'
import { getProfileAttributes, putProfileAttributes, resetGetProfileAttributes } from '../../../redux/actions/profile/profile'
import { GetMemoryCareState } from '../../../redux/reducers/getIncomeUnit/getMemoryCareReducer'
import { toast } from 'react-toastify'
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer'
import { ProfileAttributesState } from '../../../redux/reducers/getProfile/profileAttributes/getAttributesReducer'
import updateProperties from '../../../utils/DataModifier/DataModifier'

interface AttributeDataType {
    "memoryCareHeatFlag": boolean | null,
    "memoryCareHydroFlag": boolean | null,
    "memoryCareWaterFlag": boolean | null,
    "memoryCareCableFlag": boolean | null,
    "memoryCareInternetFlag": boolean | null,
    "memoryCareFoodFlag": boolean | null,
    "memoryCareHousekeepingFlag": boolean | null,
    "memoryCareActivityFlag": boolean | null,
    "memoryCareCareServiceFlag": boolean | null,
}
const initialAttributeData: AttributeDataType = {
    "memoryCareHeatFlag": null,
    "memoryCareHydroFlag": null,
    "memoryCareWaterFlag": null,
    "memoryCareCableFlag": null,
    "memoryCareInternetFlag": null,
    "memoryCareFoodFlag": null,
    "memoryCareHousekeepingFlag": null,
    "memoryCareActivityFlag": null,
    "memoryCareCareServiceFlag": null,
}

function MemoryCare() {
    const { t } = useTranslation(['assisted_living', 'navigation'])
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
    const profileAttributes: ProfileAttributesState = useSelector((state: RootState) => state.profileReducer.getProfileAttributes as ProfileAttributesState)
    const tenantSummaryState: GetMemoryCareState = useSelector((state: RootState) => state.incomeUnitReducer.getMemoryCare)
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult)
    const dispatch = useDispatch()
    const [attributeData, setAttributeData] = useState<AttributeDataType>(initialAttributeData)
    const [data, setData] = useState<Record<string, string | number | boolean>[]>([])
    const [isRequestSent, setIsRequestSent] = useState(false)
    const [noDataToast, setNoDataToast] = useState(false)
    const currentSnapshotId = Number(localStorage.getItem('currentSnapshotId') as string)

    useEffect(() => {
        if (!isRequestSent) {
            dispatch(getTenantSummaryBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string), "6"))
            setIsRequestSent(true)
        }
    }, [dispatch, isRequestSent])

    useEffect(() => {
        dispatch(resetGetProfileAttributes())
        dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!profileAttributes.loading && profileAttributes.data) {
            const initData = { ...initialAttributeData }
            updateProperties(initData, profileAttributes.data)
            setAttributeData(initData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileAttributes.data, profileAttributes.loading])

    useEffect(() => {
        if (isRequestSent && !tenantSummaryState.loading) {
            if (tenantSummaryState.success) {
                setData(tenantSummaryState.tenantSummary as Record<string, string | number | boolean>[])
            }
            else if (!noDataToast) {
                toast.warn(t("noDataFound", { ns: 'assisted_living' }) as string)
                setNoDataToast(true)
            }
        }
    }, [t, tenantSummaryState, isRequestSent, noDataToast])

    const updateValue = (e: React.ChangeEvent, index: number, key: string, isNumber?: boolean) => {
        const dataSet = [...data]
        if (isNumber && (e.target as HTMLSelectElement | HTMLInputElement).value !== "") {
            if (!isNaN(Number((e.target as HTMLSelectElement | HTMLInputElement).value))) {
                dataSet[index][key] = Number((e.target as HTMLSelectElement | HTMLInputElement).value)
            } else {
                (e.target as HTMLInputElement).value = dataSet[index][key].toString()
            }
        } else {
            dataSet[index][key] = (e.target as HTMLSelectElement | HTMLInputElement).value
        }
        setData(dataSet)
    }

    const isNewUnit = (unit: Record<string, unknown>) => {
        return 'new' in unit
    }

    const including = (fields: unknown[], noValue: unknown[]) => noValue.some(n => fields.includes(n))

    const requiredFields = (unit: Record<string, unknown>) => {
        let result = true
        if (including([unit['unitSubtypeCode'], unit['tenantUnitNumber'], unit['unitCount'], unit['netLeasableArea'], unit['monthlyActualRent'], unit['monthlyMarketRent']], [null, ''])) {
            result = false
        }
        return result
    }

    const positiveNumbers = (unit: Record<string, unknown>) => {
        let result = true
        if (Math.min(unit['unitCount'] as number,
            unit['netLeasableArea'] as number,
            unit['monthlyActualRent'] as number,
            unit['monthlyMarketRent'] as number,
            unit['typicalCareRate'] as number,
            unit['secondOccupantFee'] as number) < 0) {
            result = false
        }
        return result
    }

    const validUnit = (unit: Record<string, unknown>) => {
        if (localStorage.getItem("isInternalUser")) {
            //
        } else {
            if (!requiredFields(unit)) {
                toast.error(t("validation-error", { ns: 'assisted_living' }) as string)
                return false
            }
        }

        if (!positiveNumbers(unit)) {
            toast.error(t("validation-error-negative", { ns: 'assisted_living' }) as string)
            return false
        }

        return true
    }

    const saveRow = (index: number) => {
        const unit = data[index]
        if (validUnit(unit)) {
            if (isNewUnit(unit)) {
                dispatch(postIncomeUnit(Number(localStorage.getItem('currentSnapshotId') as string), unit))
            } else {
                dispatch(putIncomeUnit(Number(localStorage.getItem('currentSnapshotId') as string), unit))
            }
            return true
        }
        return false
    }

    const deleteRow = (index: number) => {
        const unit = data[index]
        dispatch(deleteIncomeUnitById(Number(localStorage.getItem('currentSnapshotId') as string), unit.tenantId.toString()))
    }

    const handleAttributeChange = (name: string, value: string | number | boolean | null) => {
        setAttributeData({
            ...attributeData,
            [name]: value
        })
    }

    useEffect(() => {
        if (saveData.saving) {
            const data = { ...profileAttributes.data, ...attributeData } as Record<string, unknown>
            dispatch(putProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId, data))
        }
    }, [attributeData, currentSnapshotId, dispatch, profileAttributes.data, profileOverview.profile.profile_type_code, saveData.saving])


    useEffect(() => {
        if (saveResultState.status === 200) {
            dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId))
        }
    }, [currentSnapshotId, dispatch, profileOverview.profile.profile_type_code, saveResultState.status])

    return (
        <>
            <h3>{t("memoryCare", { ns: 'navigation' })}</h3>
            <div className='assisted-suite-breakdown-container'>
                    <RentInclusions data={{ ...attributeData } as Record<string, boolean | null>}
                        onChange={handleAttributeChange}
                        title="memoryCareHeader" />

                    <SuiteBreakdownTable
                        title='memoryCareHeader'
                        data={data}
                        setData={setData}
                        onSave={saveRow}
                        onDelete={deleteRow}
                        onChange={updateValue}
                        isSuiteTypeMandatory={true}
                        isNumUnitsMandatory={true}
                        isNumBedsMandatory={true}
                        isAreaSFMandatory={true}
                        isActualRentMandatory={true}
                        isMarketRentMandatory={true}
                        unitTypeCode='6'
                    />
            </div>
        </>
    )
}

export default MemoryCare