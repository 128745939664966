import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ControlledAccordion, AccordionItem, useAccordionProvider } from '@szhsin/react-accordion';
import { setNextActiveMenuItem } from '../../redux/actions/menu/menu';
import { getAssessmentInfo, putAssessmentInfo, resetGetAssessmentInfo, resetPutAssessmentInfo } from '../../redux/actions/assessmentInfo/assessmentInfo';
import { resetSaving } from '../../redux/actions/saveData/saveData';
import { RootState } from '../../redux/reducers/root';
import { AssessmentInfoState } from '../../redux/reducers/getAssessmentInfo/getAssessmentInfoReducer';
import { initialMenuItem } from '../../redux/reducers/getMenu/getMenuReducer';
import { SaveDataState } from '../../redux/reducers/getSaveData/saveDataReducer';
import { SaveResultState } from '../../redux/reducers/getSaveData/saveResultReducer';
import Overview from '../../components/gev/Overview';
import CommercialGrainElevatorFacility from '../../components/gev/CommercialGrainElevatorFacility';
import FeedMillingProcessingFacility from '../../components/gev/FeedMillingProcessingFacility';
import OtherAgriBusiness from '../../components/gev/OtherAgriBusiness';
import FarmingOperationCommercialGrain from '../../components/gev/FarmingOperationCommercialGrain';
import NoFormSelected from '../../components/gev/NoFormSelected';
import "./BusinessOperations.css";

export interface OperationProps {
    operation: string,
    data: Record<string, unknown>,
    setData: (selectionKey: string, data: Record<string, unknown>) => void,
    handleFormChange: (e: React.FormEvent<HTMLFormElement>,
        data: Record<string, unknown>,
        setData: React.Dispatch<React.SetStateAction<Record<string, unknown>>>) => void,
    validating: boolean,
    validate: (operationKey: string) => void,
    errors: Record<string, string | Record<string, string>>,
    setErrors: (operationKey: string, operationErrors: Record<string, string | Record<string, string>>) => void,
}
export interface OperationFieldProps {
    data: Record<string, unknown>,
    operation: string,
    name: string,
    errors: Record<string, string | Record<string, string>>,
    label?: string,
    title?: string,
    type?: string,
    options?: string[],
}

export const allOtherOptions = [
    'commercialGrainElevatorFacility',
    'feedMillingProcessingFacility',
    'otherAgriBusiness'
]
export const farmingOption = 'farmingOperationCommercialGrain'
export const noFormSelectedOption = 'noFormSelected'
export const initialGrainLicenseType: Record<string, boolean | null> = {
    "elevatorOperator": null,
    "grainDealer": null,
    "both": null,
    "other": null,
}
export const initialPerCrop = {
    "corn": null,
    "soybeans": null,
    "wheat": null,
}


export default function BusinessOperations(): JSX.Element {
    const { t } = useTranslation('gev')
    const dispatch = useDispatch()
    const providerValue = useAccordionProvider({
        allowMultiple: true,
        transition: true,
        transitionTimeout: 250
    })
    // Destructuring `toggle` and `toggleAll` from `providerValue`
    const { toggle } = providerValue
    const assessmentInfoState: AssessmentInfoState = useSelector((state: RootState) => state.assessmentInfoReducer.getAssessmentInfo)
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
    const [assessmentInformation, setAssessmentInformation] = useState<Record<string, Record<string, unknown>>>({})
    const [errors, setErrors] = useState<Record<string, Record<string, string | Record<string, string>>>>({})
    const [validating, setValidating] = useState(false)
    const [validation, setValidation] = useState<string[]>([])
    const [saveInformation, setSaveInformation] = useState<Record<string, Record<string, unknown>>>({})
    const [requireGetData, setRequireGetData] = useState(true)
    const [gettingData, setGettingData] = useState(false)
    const [puttingData, setPuttingData] = useState(false)
    const currentSnapshotId = localStorage.getItem("currentSnapshotId") as string
    const activeCampaignYear = Number(localStorage.getItem("activeCampaignYear"))
    const initialAssessmentInformation: Record<string, Record<string, unknown>> = {
        "commercialGrainElevatorFacility": {
            "selected": false,
            "licenseExpiryDate": null,
            "grainLicenseType": {
                "elevatorOperator": null,
                "grainDealer": null,
                "both": null,
                "other": null,
            },
            "licenseRegisteredTo": null,
            "isLicenseInUse": null,
            "totalBuschelCapacity": null,
            "isTruckScaleOnSite": null,
            "truckScaleCapacity": null,
            "areAnyBuildingsAddedOrRemoved": null,
            "comments": null,
        },
        "feedMillingProcessingFacility": {
            "selected": false,
            "licenseExpiryDate": null,
            "grainLicenseType": {
                "elevatorOperator": null,
                "grainDealer": null,
                "both": null,
                "other": null,
            },
            "licenseRegisteredTo": null,
            "isLicenseInUse": null,
            "isCommercialGrainElevatorOperated": null,
            "isTruckScaleOnSite": null,
            "truckScaleCapacity": null,
            "areAnyBuildingsAddedOrRemoved": null,
            "comments": null,
        },
        "otherAgriBusiness": {
            "selected": false,
            "licenseExpiryDate": null,
            "grainLicenseType": {
                "elevatorOperator": null,
                "grainDealer": null,
                "both": null,
                "other": null,
            },
            "licenseRegisteredTo": null,
            "isLicenseInUse": null,
            "totalBuschelCapacity": null,
            "isTruckScaleOnSite": null,
            "truckScaleCapacity": null,
            "isCommercialGrainElevatorOperated": null,
            "primaryBusinessOperations": null,
            "areAnyBuildingsAddedOrRemoved": null,
            "comments": null,
        },
        "farmingOperationCommercialGrain": {
            "selected": false,
            "licenseExpiryDate": null,
            "grainLicenseType": {
                "elevatorOperator": null,
                "grainDealer": null,
                "both": null,
                "other": null,
            },
            "licenseRegisteredTo": null,
            "isLicenseInUse": null,
            "totalBuschelCapacity": null,
            "totalAcresCropped": null,
            "acresPerCrop": {
                "corn": null,
                "soybeans": null,
                "wheat": null,
                "other": null,
            },
            "yieldPerCrop": {
                "corn": null,
                "soybeans": null,
                "wheat": null,
                "other": null,
            },
            "isTruckScaleOnSite": null,
            "truckScaleCapacity": null,
            "percentageOfThroughput": {
                "campaignYearOne": {
                    "yearValue": activeCampaignYear - 3,
                    "owner": null,
                    "nonOwner": null,
                    "totalThroughput": null
                },
                "campaignYearTwo": {
                    "yearValue": activeCampaignYear - 2,
                    "owner": null,
                    "nonOwner": null,
                    "totalThroughput": null
                },
                "campaignYearThree": {
                    "yearValue": activeCampaignYear - 1,
                    "owner": null,
                    "nonOwner": null,
                    "totalThroughput": null
                }
            },
            "areAnyBuildingsAddedOrRemoved": null,
            "comments": null,
        },
        "noFormSelected": {
            "selected": false,
            "changesToProperty": null,
            "dateOfChange": null,
        },
    }

    useEffect(() => {
        if (requireGetData && !gettingData && !puttingData) {
            dispatch(resetGetAssessmentInfo())
            dispatch(getAssessmentInfo(currentSnapshotId))
            setGettingData(true)
        }
    }, [currentSnapshotId, dispatch, gettingData, puttingData, requireGetData])

    useEffect(() => {
        if (gettingData && assessmentInfoState.status) {
            setGettingData(false)
            if (assessmentInfoState.status === 200) {
                const assessmentInfo = assessmentInfoState.data.assessmentInfo
                const assessmentInfoKeys = Object.keys(assessmentInfo)
                let info: Record<string, Record<string, unknown>> = {}
                if (assessmentInfoKeys.length) {
                    Object.keys(initialAssessmentInformation).forEach((key) => {
                        if (assessmentInfoKeys.includes(key)) {
                            info[key] = assessmentInfo[key]
                            // if (key === farmingOption) {
                            //     const pot = info[farmingOption]["percentageOfThroughput"] as Record<string, Record<string, number | null>>
                            //     const initialPot = initialAssessmentInformation[farmingOption]["percentageOfThroughput"] as Record<string, Record<string, number | null>>
                            //     if (pot) {
                            //         const potKeys = Object.keys(pot)
                            //         potKeys.forEach((potKey) => {
                            //             const potYear = pot[potKey]
                            //             potYear["yearValue"] = initialPot[potKey]["yearValue"]
                            //             if (potYear["owner"] === null) {
                            //                 potYear["totalThroughput"] = null
                            //                 potYear["nonOwner"] = null
                            //             } else {
                            //                 potYear["totalThroughput"] = 100
                            //                 potYear["nonOwner"] = 100 - potYear["owner"]
                            //             }
                            //         })
                            //     }
                            // }
                        } else {
                            info[key] = initialAssessmentInformation[key]
                        }
                    })
                } else {
                    info = structuredClone(initialAssessmentInformation)
                }
                setAssessmentInformation(info)
                setRequireGetData(false)
            } else {
                setRequireGetData(true)
                if (assessmentInfoState.message) {
                    toast.error(assessmentInfoState.message)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gettingData, assessmentInfoState.data.assessmentInfo, assessmentInfoState.message, assessmentInfoState.status])

    const changeSelection = (operationKey: string, data: Record<string, unknown>) => {
        setErrors({})
        if (data["selected"]) {
            setAssessmentInformation({ ...assessmentInformation, [operationKey]: data })
        } else {
            setAssessmentInformation({ ...assessmentInformation, [operationKey]: initialAssessmentInformation[operationKey] })
        }
    }

    const validate = (operationKey: string) => {
        if (!validation.includes(operationKey)) {
            setValidation([...validation, operationKey])
        }
    }

    const saveOperationData = (operationKey: string, data: Record<string, unknown>) => {
        if (!Object.keys(saveInformation).includes(operationKey)) {
            setSaveInformation({ ...saveInformation, [operationKey]: data })
        }
    }

    const setOperationErrors = (operationKey: string, operationErrors: Record<string, string | Record<string, string>>) => {
        if (!Object.keys(errors).includes(operationKey)) {
            setErrors({ ...errors, [operationKey]: operationErrors })
        }
    }

    const handleFormChange = (e: React.FormEvent<HTMLFormElement>,
        data: Record<string, unknown>,
        setData: React.Dispatch<React.SetStateAction<Record<string, unknown>>>) => {
        const target = e.target as HTMLInputElement
        const targetName = target.name
        const value = target.value
        if (targetName.indexOf(".") !== -1) {
            const index = targetName.indexOf(".")
            // const operation = targetName.substring(0, index)
            const name = targetName.substring(index + 1)

            if (name === "grainLicenseType") {
                const grainLicenseType = { ...initialGrainLicenseType }
                const option = value
                if (target.checked) {
                    grainLicenseType[option] = target.checked
                }
                setData({ ...data, [name]: grainLicenseType })
            } else if ([
                "isLicenseInUse",
                "isTruckScaleOnSite",
                "areAnyBuildingsAddedOrRemoved",
                "isCommercialGrainElevatorOperated",
            ].includes(name)) {
                if (target.checked) {
                    setData({ ...data, [name]: value.toLowerCase() === "true" })
                } else {
                    setData({ ...data, [name]: null })
                }
            } else if (["totalBuschelCapacity", "totalAcresCropped"].includes(name)) {
                let number = null
                if (value.length) {
                    number = Number(value)
                    number = number < 0 ? -number : number
                }
                if (number !== data[name]) {
                    setData({ ...data, [name]: number })
                }
            } else if (name.indexOf(".") !== -1) {
                const index = name.indexOf(".")
                const dataName = name.substring(0, index)
                const option = name.substring(index + 1)
                if (option === "other") {
                    setData({ ...data, [dataName]: { ...data[dataName] as Record<string, unknown>, [option]: value.trim() === "" ? null : value } })
                } else {
                    let number = null
                    if (value.length) {
                        number = Number(value)
                        number = number < 0 ? -number : number
                    }
                    setData({ ...data, [dataName]: { ...data[dataName] as Record<string, unknown>, [option]: number } })
                }
            } else {
                setData({ ...data, [name]: value.trim() === "" ? null : value })
            }
        }
    }

    const displayOperation = (operationKey: string) => {
        const props: OperationProps = {
            operation: operationKey,
            data: assessmentInformation[operationKey],
            handleFormChange: handleFormChange,
            setData: saveOperationData,
            validating: validating,
            validate: validate,
            errors: errors[operationKey],
            setErrors: setOperationErrors,
        }
        switch (operationKey) {
            case "commercialGrainElevatorFacility":
                return <CommercialGrainElevatorFacility {...props} />
            case "feedMillingProcessingFacility":
                return <FeedMillingProcessingFacility {...props} />
            case "otherAgriBusiness":
                return <OtherAgriBusiness {...props} />
            case "farmingOperationCommercialGrain":
                return <FarmingOperationCommercialGrain {...props} />
            case "noFormSelected":
                return <NoFormSelected {...props} />
            default:
                return null
        }
    }

    useEffect(() => {
        if (saveData.saving) {
            setSaveInformation({})
            setErrors({})
            setValidation([])
            setValidating(true)
        }
    }, [saveData.saving])

    useEffect(() => {
        if (validating) {
            const operationsNeedValidation = ["overview"]
            Object.keys(assessmentInformation).forEach((key) => {
                if (assessmentInformation[key]["selected"]) {
                    operationsNeedValidation.push(key)
                }
            })
            const doneValidation = operationsNeedValidation.every(item => validation.includes(item))
            if (doneValidation) {
                setValidating(false)
                const errorKeys = Object.keys(errors)
                let valid = errorKeys.length === 0
                if (localStorage.getItem("isInternalUser")) {
                    valid = true
                    setErrors({})
                }

                if (valid) {
                    Object.keys(assessmentInformation).forEach((key) => {
                        if (assessmentInformation[key]["selected"]) {
                            assessmentInformation[key] = saveInformation[key]
                        }
                    })

                    dispatch(resetPutAssessmentInfo())
                    dispatch(putAssessmentInfo(currentSnapshotId,
                        { snapshotId: Number(currentSnapshotId), assessmentInfo: assessmentInformation }))
                    setPuttingData(true)
                } else {
                    Object.keys(assessmentInformation).forEach((key) => {
                        if (assessmentInformation[key]["selected"]) {
                            toggle(key, errorKeys.includes(key))
                        }
                    })
                    document.getElementById("bottom-of-overview")?.scrollIntoView({ behavior: 'smooth' })
                    dispatch(resetSaving());
                    dispatch(setNextActiveMenuItem(initialMenuItem));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, errors, validating, validation])

    useEffect(() => {
        if (saveResultState.status === 200) {
            setRequireGetData(true)
        }
    }, [saveResultState.status])

    return (
        <>
            <Overview assessmentInformation={assessmentInformation}
                changeSelection={changeSelection}
                validating={validating}
                validate={validate}
                errors={errors["overview"] as Record<string, string>}
                setErrors={setOperationErrors}
            />
            <div id="bottom-of-overview"></div>
            <div className='accordion'>
                <ControlledAccordion providerValue={providerValue}>
                    {
                        Object.keys(assessmentInformation).length > 0
                        && allOtherOptions.concat(farmingOption).concat(noFormSelectedOption).map((key) => {
                            return (
                                (assessmentInformation[key] && assessmentInformation[key]["selected"]) ?
                                    <AccordionItem header={t('overview.' + key)}
                                        className="item"
                                        buttonProps={{ className: Object.keys(errors).includes(key) ? "itemBtnError" : "itemBtn" }}
                                        panelProps={{ className: "itemPanel" }}
                                        key={key}
                                        itemKey={key}
                                        initialEntered
                                    >
                                        {displayOperation(key)}
                                    </AccordionItem>
                                    :
                                    null
                            )
                        })
                    }
                </ControlledAccordion>
            </div>
        </>
    )
}
