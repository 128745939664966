import { API_REQUEST, GET_ELS_LOOKUPS, GET_LOOKUPS } from "../../actionTypes/actionsTypes";
import { AppAction } from "../../types";

// Manage unit income action
export const getElsLookups = (): AppAction => ({
    type: API_REQUEST,
    payload: {
        url: "/income/get-lookups/unit-dropdowns",
        next: GET_ELS_LOOKUPS,
        method: 'GET',
        authorization: true
    }
});

export const getLookups = (lookupType: string): AppAction => ({
    type: API_REQUEST,
    payload: Object.assign({
        url: `/income/get-lookups/${lookupType}`,
        next: GET_LOOKUPS,
        method: 'GET',
        authorization: true
    })

});
