import PropertyObject from "../../../types/property/PropertyObject";
import { GET_PROPERTIES, RESET_PROPERTIES } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types";

export interface PropertiesState extends BaseAsyncReducerState {
    found: boolean;
    firstName: string,
    lastName: string,
    rolls: PropertyObject[]
}

const initialState: PropertiesState = {
    found: false,
    firstName: "",
    lastName: "",
    rolls: [],
    loading: false,
    error: false
}

function getPropertiesReducer(state = initialState, action: AppAction | LocalAction) {
    if (action.type === RESET_PROPERTIES) {
        return { ...initialState };
    } else {
        return defaultAsyncReducer(state, action as AppAction, GET_PROPERTIES);
    }
}

export default getPropertiesReducer;