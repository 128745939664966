import React from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import RadioButton from '../common/RadioGroup/RadioButton';

interface LeaseTypeWizardProps {
    updateWizardFunction : (e: React.ChangeEvent<Element>, i: number, f: string, n?: boolean) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    leaseTypeOptions: Record<string, unknown>[];
    leaseTypeForAll: string;
    setLeaseTypeForAll: (value: string) => void;
    isSameType: boolean | undefined;
    setIsSameType: (value: boolean) => void;
    unitSubtypeLookup: Record<string, unknown>;
    // for pagination
    totalPages: number | undefined;
    currentPage: number;
    changePage: (e: number) => void;
}

export default function LeaseTypeWizard(props: LeaseTypeWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");

    const headings = [<th key="basic-info-wizard-heading-1">{t("unit-heading.floor-level")}</th>,
        <th key="basic-info-wizard-heading-2">{t("unit-heading.tenant-name")}</th>,
        <th key="basic-info-wizard-heading-3">{t("unit-heading.unit-number")}</th>,
        <th key="basic-info-wizard-heading-4">{t("unit-heading.unit-use")}</th>,
        <th key="basic-info-wizard-heading-5">{t("unit-heading.lease-type") + " " + t("unit-heading.select-one")}</th>]

        const buildLeaseTypeOptions = (index?: number): JSX.Element => {
            const leaseTypeOptions: JSX.Element[] = [];
            for (let i = 0; i < props.leaseTypeOptions.length; i++) {
                if (props.leaseTypeOptions[i]["value"] as string !== "") {
                    leaseTypeOptions.push(<RadioButton key={`lease-type-option-${(props.leaseTypeOptions[i]["text"] as string).toLocaleLowerCase()}` + (index === undefined ? "" : `-${index}`)}
                        name={"lease-type-option" + (index === undefined ? "" : `-${index}`)} id={`lease-type-option-${(props.leaseTypeOptions[i]["text"] as string).toLocaleLowerCase()}` + (index === undefined ? "" : `-${index}`)}
                        text={props.leaseTypeOptions[i]["text"] as string} textPosition='right' value={props.leaseTypeOptions[i]["value"] as string} 
                        currentValue={(index !== undefined ? ((props.tenantList[index]["leaseTypeCode"] as string) ?? "") : props.leaseTypeForAll)} 
                        onChange={(e) => (index !== undefined ? props.updateWizardFunction(e, index, "leaseTypeCode") : props.setLeaseTypeForAll(props.leaseTypeOptions[i]["value"] as string))} />);
                }
            }
            return (<div className='wizard-radio-cell'>{leaseTypeOptions}</div>);
        }

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            for (let index = 10 * (props.currentPage - 1); index < Math.min(props.tenantList.length, 10 * props.currentPage); index++) {
                tableBody.push (<tr key={`basic-info-wizard-row-${index}`}>
                    <td className='no-input-cell'><label>{props.floorDescriptionLookup[props.tenantList[index]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell tenant-name-cell'><p>{props.tenantList[index]["tenantName"] as string}</p></td>
                    <td className='no-input-cell tenant-unit-cell'><p>{props.tenantList[index]["tenantUnitNumber"] as string}</p></td>
                    <td className='no-input-cell'><p>{props.unitSubtypeLookup[props.tenantList[index]["unitSubtypeCode"] as string] as string}</p></td>
                    <td className="wizard-grey-out-cell">{(props.tenantList[index]["leaseStatusCode"] as string) !== "1" && buildLeaseTypeOptions(index)}</td>
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <div className='wizard-radio-cell wizard-question'>
            <fieldset>
                <legend>{t("lease-type-wizard.lease-type-question")}</legend>
                <RadioButton name="lease-type-question" id="lease-type-question-yes" text={t("yes")} textPosition='right' value="true" currentValue={props.isSameType ?? ""} onChange={() => props.setIsSameType(true)} />
                <RadioButton name="lease-type-question" id="lease-type-question-no" text={t("no")} textPosition='right' value="false" currentValue={props.isSameType ?? ""} onChange={() => props.setIsSameType(false)} />
            </fieldset>
        </div>
        {props.isSameType === true && <>
            <fieldset>
                <legend>{t("lease-type-wizard.all-lease-type")}</legend>
                {buildLeaseTypeOptions()}
            </fieldset>
        </>}
        {props.isSameType === false && <DataTable headings={headings} className='wizard-table' totalPages={props.totalPages} currentPage={props.currentPage} changePage={props.changePage}>
            {buildTableContent()}
        </DataTable>}
    </div>)
}