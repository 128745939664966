import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenuItem, setMenu, setNextActiveMenuItem } from "../../redux/actions/menu/menu";
import { RESET_SAVEDATA } from "../../redux/actionTypes/actionsTypes";
import { RootState } from "../../redux/reducers/root";
import { GlobalSettingState } from "../../redux/reducers/getGlobalSetting/getGlobalSettingReducer";
import { initialMenuItem } from "../../redux/reducers/getMenu/getMenuReducer";
import { ProfileOverviewState } from "../../redux/reducers/getProfile/getProfileOverviewReducer";
import CIXNavList from "../../assets/data/navigationList/CIXNavList";
import GLFNavList from "../../assets/data/navigationList/GLFNavList";
import HTLNavList from "../../assets/data/navigationList/HTLNavList";
import MTLNavList from "../../assets/data/navigationList/MTLNavList";
import LLENavList from "../../assets/data/navigationList/LLENavList";
import LTCNavList from "../../assets/data/navigationList/LTCNavList";
import RHONavList from "../../assets/data/navigationList/RHONavList";
import GEVNavList from "../../assets/data/navigationList/GEVNavList";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import MenuItem from "../../types/menu/MenuItem";
import MenuState from "../../types/menu/MenuState";
import './PortalMenu.css';
import { useReadOnly } from "../../utils/ReadOnlyContext";
import { resetSaveData, resetSaveResult, setSaving } from "../../redux/actions/saveData/saveData";
import { SaveResultState } from "../../redux/reducers/getSaveData/saveResultReducer";
import { toast } from "react-toastify";

function PortalMenu(): JSX.Element {
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
    const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
    const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
    const [collapsed, setCollapsed] = useState(false)
    const [menuList, setMenuList] = useState<MenuItem[]>([]);
    const dispatch = useDispatch();
    const { isReadOnly } = useReadOnly()

    useEffect(() => {
        setDynamicMenu(profileOverview.profile.profile_type_code);
    }, [profileOverview.profile.profile_type_code]);

    useEffect(() => {
        const filterMenu = (menuList: MenuItem[]): MenuItem[] => {
            let result = menuList
            if(isReadOnly) {
                result = menuList.filter(item => item.name !== "submit" && item.name !== "summaryReview")
            }
            return result
        }

        if (menuList.length > 0) {
            dispatch(setMenu(filterMenu(menuList)));
            dispatch(setActiveMenuItem(filterMenu(menuList)[0]));
            dispatch({ type: RESET_SAVEDATA });
        }
    }, [dispatch, menuList, isReadOnly]);

    const navigateToNextActiveMenu = () => {
        if (menuState.nextActiveItem.id) {
            dispatch(setActiveMenuItem(menuState.nextActiveItem));
        }
    }

    useEffect(() => {
        // When the nextActiveItem is different from the activeItem, the user is attempting to navigate to another page,
        // if there is no changesPending or is in isReadOnly mode, proceed to another page, otherwise trigger setSaving.
        // Once the data is successfully saved, the page component should resetSaveData, making changesPending false
        if (menuState.nextActiveItem.id !== 0 && (menuState.activeItem.id !== menuState.nextActiveItem.id 
            || ((menuState.activeItem.selectedTabIndex ?? -1) !== (menuState.nextActiveItem.selectedTabIndex ?? -1)))) {
            if (isReadOnly) {
                navigateToNextActiveMenu();
            } else if (menuState.activeItem.id) {
                dispatch(setSaving());
            } else {
                navigateToNextActiveMenu();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isReadOnly, menuState.activeItem.id, menuState.activeItem.selectedTabIndex, menuState.nextActiveItem.id, menuState.nextActiveItem.selectedTabIndex]);

    // If saveResult status is 200, reset everything, otherwise show the error message, set saving to false, and clear setNextActiveMenuItem
    useEffect(() => {
        if (saveResultState.status) {
            if (saveResultState.status === 200) {
                navigateToNextActiveMenu();
            } else {
                dispatch(setNextActiveMenuItem(initialMenuItem));
                if (saveResultState.message) {
                    toast.error(saveResultState.message);
                }
                if (saveResultState.errors) {
                    toast.error(JSON.stringify(saveResultState.errors));
                }
            }
            dispatch(resetSaveData());
            dispatch(resetSaveResult());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveResultState, dispatch]);

    const handleCollapsedChange = () => {
        setCollapsed(!collapsed);
    }

    const setDynamicMenu = (profileType: string): void => {
        let menuList: MenuItem[] = [];
        switch (profileType) {
            case 'BIP':
                menuList = CIXNavList;
                break;
            case 'GLF':
                menuList = GLFNavList;
                break;
            case 'HTL':
                menuList = HTLNavList;
                break;
            case 'MTL':
                menuList = MTLNavList;
                break;
            case 'LLE':
                menuList = LLENavList;
                break;
            case 'LTC':
                menuList = LTCNavList;
                break;
            case 'RHO':
                menuList = RHONavList;
                break;
            case 'GEV':
                menuList = GEVNavList;
                break;
            default:
                menuList = [];
        }
        // Hide submit menu item for internal users
        if (localStorage.getItem("isInternalUser")) {
            menuList = menuList.filter((menu) => menu.name !== "submit");
        }
        setMenuList(menuList);
    }

    return (<Sidebar
        collapsed={collapsed}
        handleCollapse={handleCollapsedChange}
        year={globalSettingState.success ? (globalSettingState.setting["campaign_year"] as string) : ""}
        items={menuState.menu}
    />
    )
}

export default PortalMenu;