import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers/root";
import "../PIE.css";
import { ProfileOverviewState } from "../../../redux/reducers/getProfile/getProfileOverviewReducer";
import { SaveDataState } from "../../../redux/reducers/getSaveData/saveDataReducer";
import { getProfileAttributes, putProfileAttributes, resetGetProfileAttributes } from "../../../redux/actions/profile/profile";
import NumberOfSitesData from "../../../types/numberOfSites/numberOfSitesData";
import updateProperties from "../../../utils/DataModifier/DataModifier";
import { useTranslation } from "react-i18next";
import Input from "../../../components/common/Input/Input";
import ToolTip from "../../../components/common/ToolTip/ToolTip";
import { SaveResultState } from "../../../redux/reducers/getSaveData/saveResultReducer";
import { ProfileAttributesState } from "../../../redux/reducers/getProfile/profileAttributes/getAttributesReducer";

function NumberOfSites(): ReactElement {
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult)
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
    const profileAttributes: ProfileAttributesState = useSelector((state: RootState) => state.profileReducer.getProfileAttributes as ProfileAttributesState)
    const dispatch = useDispatch()
    const { t } = useTranslation('number_of_sites')
    const numberOfSiteKeys = ["occupiedLot", "vacantLot", "totalSiteCount", "transientLot", "campgroundSite"]
    const [totalSites, setTotalSites] = useState<number | undefined>(undefined)

    const initialNumberOfSitesData: NumberOfSitesData = {
        "occupiedLot": null,
        "vacantLot": null,
        "totalSiteCount": null,
        "transientLot": null,
        "campgroundSite": null,
    }
    const [numberSitesData, setNumberSitesData] = useState<NumberOfSitesData>(initialNumberOfSitesData as NumberOfSitesData)

    useEffect(() => {
        dispatch(resetGetProfileAttributes())
        dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, Number(localStorage.getItem('currentSnapshotId') as string)))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!profileAttributes.loading && profileAttributes.data) {
            const initData = { ...initialNumberOfSitesData }
            updateProperties(initData, profileAttributes.data)
            setNumberSitesData(initData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileAttributes.data, profileAttributes.loading])

    const handleNumberOfSitesChange = (name: string, e: React.ChangeEvent<HTMLInputElement>) => {
        const currentTarget = e.currentTarget
        const value = currentTarget.value
        let numberValue: number | null = null
        if (typeof value === 'string' && value.trim() !== '') {
            numberValue = parseInt(value)
            currentTarget.value = String(numberValue)
        }

        setNumberSitesData({
            ...numberSitesData,
            [name as keyof typeof numberSitesData]: numberValue,
        })
    }

    useEffect(() => {
        const initTotalSites = () => {
            let total = 0
            const vacant = numberSitesData.vacantLot ?? 0
            const occupied = numberSitesData.occupiedLot ?? 0
            total = vacant + occupied
            setTotalSites(total)
        }

        initTotalSites()
    }, [numberSitesData])

    useEffect(() => {
        if (saveData.saving) {
            const dataOriginal = profileAttributes
            const data = { ...dataOriginal.data, ...numberSitesData } as Record<string, unknown>
            dispatch(putProfileAttributes(profileOverview.profile.profile_type_code, Number(localStorage.getItem('currentSnapshotId') as string), data))
        }
    }, [dispatch, numberSitesData, profileAttributes, profileOverview.profile.profile_type_code, saveData.saving])

    useEffect(() => {
        if (saveResultState.status === 200) {
            dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, Number(localStorage.getItem('currentSnapshotId') as string)))
        }
    }, [dispatch, profileOverview.profile.profile_type_code, saveResultState.status])

    return (<>
        <div>
                <table className="table-with-border pie-table capital-expenditures-table" width="70%">
                    <tbody>
                        <tr>
                            <th colSpan={2}>{t("numberOfSitesTableHeader")}</th>
                        </tr>
                        {numberOfSiteKeys.map((key) => {
                            if (key === 'totalSiteCount') {
                                return (
                                    <tr key={key}>
                                        <th><ToolTip contents={t(key)}>{t("tooltip." + key)}</ToolTip></th>
                                        <td> <p className={"padding-left-10 flex"}> {totalSites} </p></td>
                                    </tr>)
                            } else {
                                return (
                                    <tr key={key}>
                                        <th><ToolTip contents={t(key)}>{t("tooltip." + key)}</ToolTip></th>
                                        <td>
                                            <Input className={"padding-left-10 flex"}
                                                value={numberSitesData[key as keyof NumberOfSitesData] !== null ? String(numberSitesData[key as keyof NumberOfSitesData]) : ""}
                                                name={key} id={key} type={"text"}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => (handleNumberOfSitesChange(key, e))} />
                                        </td>
                                    </tr>)
                            }
                        })}
                    </tbody>
                </table>
        </div>
    </>)
}

export default NumberOfSites