// documentation for creating this component: https://floating-ui.com/docs/react
import {
    FloatingPortal, Placement, FloatingArrow, autoUpdate, flip, arrow, offset, shift, useDismiss,
    useFloating, useFocus, useHover, useInteractions, useRole
} from '@floating-ui/react';
import React from 'react';
import { useState, useRef } from 'react';
import './ToolTip.css';
 
export type TooltipProps = {
    contents: React.ReactNode | string;
    placement?: Placement;
    children: React.ReactNode;
}
 
export default function ToolTip(props: TooltipProps) {
    const [open, setOpen] = useState(false);
    const arrowRef = useRef(null);
    const { x, y, refs, strategy, context } = useFloating({
        open,
        onOpenChange(open) {
            setOpen(open);
        },
        placement: props.placement ? props.placement : "top",
        middleware: [offset(9), 
                    flip({fallbackAxisSideDirection: "start", crossAxis: false}), 
                    shift(),
                    arrow({
                        element: arrowRef,
                      })],
        whileElementsMounted: autoUpdate
    });
 
    const { getReferenceProps, getFloatingProps } = useInteractions([
        useHover(context, { move: false }),
        useFocus(context),
        useRole(context, { role: 'tooltip' }),
        useDismiss(context),
    ]);
 
    return (<>
      <span ref={refs.setReference} {...getReferenceProps()}>
        {props.contents}
      </span>
        <FloatingPortal>
            {open && (<div
            className="ToolTip"
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
            >
                {props.children}
            <FloatingArrow ref={arrowRef} context={context} />
            </div>)}

        </FloatingPortal>
    </>);
}