import { API_REQUEST, UPDATE_CAMPAIGN_STATUS } from "../../actionTypes/actionsTypes";
import { AppAction } from "../../types";

export const updateCampaignStatus = (new_status: string, profileId: string, campaignId: string, isCopySnapshot = "false"): AppAction => ({
  type: API_REQUEST,
  payload: {
    url: `/income/update-campaign-status`,
    next: UPDATE_CAMPAIGN_STATUS,
    method: 'POST',
    data: {
      "profile_id": profileId,
      "campaign_id": campaignId,
      "new_status": new_status,
      "is_copy_snapshot": isCopySnapshot
    },
    authorization: true
  }
});