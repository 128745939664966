import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initialPerCrop, OperationProps } from '../../pages/BusinessOperations/BusinessOperations';
import GEVInput from './GEVInput';
import GEVYesNo from './GEVYesNo';
import GEVTexttarea from './GEVTexttarea';
import GEVGrainLicenseType from './GEVGrainLicenseType';
import GEVPerCrop from './GEVPerCrop';
import GEVPercentageOfThroughput from './GEVPercentageOfThroughput';

export default function FarmingOperationCommercialGrain(props: OperationProps): JSX.Element {
    const { t } = useTranslation('gev')
    const [data, setData] = useState(props.data)

    const potChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target
        const targetName = target.name
        const potData = data["percentageOfThroughput"] as Record<string, Record<string, number | null>>
        const rowData = potData[targetName]
        const targetValue = target.value.trim()
        if (targetValue === "") {
            setData({
                ...data,
                "percentageOfThroughput": {
                    ...potData,
                    [targetName]: {
                        ...rowData,
                        "owner": null,
                        "nonOwner": null,
                        "totalThroughput": null
                    }
                }
            })
        } else {
            let value = Number(targetValue)
            if (value < 0) {
                value = 0
            } else if (value > 100) {
                value = 100
            }
            setData({
                ...data,
                "percentageOfThroughput": {
                    ...potData,
                    [targetName]: {
                        ...rowData,
                        "owner": value,
                        "nonOwner": 100 - value,
                        "totalThroughput": 100
                    }
                }
            })
        }
        e.stopPropagation()
    }


    useEffect(() => {
        if (props.validating) {
            const err: Record<string, string | Record<string, string>> = {}
            const grainLicenseType = data["grainLicenseType"] as Record<string, boolean | null>
            if (grainLicenseType &&
                !Object.keys(grainLicenseType).some((option) => grainLicenseType[option] === true)) {
                err["grainLicenseType"] = t("missingRequiredField")
            }
            if (!data["licenseRegisteredTo"]) {
                err["licenseRegisteredTo"] = t("missingRequiredField")
            }
            if (data["licenseRegisteredTo"] && data["licenseRegisteredTo"].toString().length > 100) {
                err["licenseRegisteredTo"] = t("max-100-chars")
            }
            if (data["isLicenseInUse"] === null) {
                err["isLicenseInUse"] = t("missingRequiredField")
            }
            if (!data["totalBuschelCapacity"]) {
                err["totalBuschelCapacity"] = t("missingRequiredField")
            }
            if (data["totalBuschelCapacity"] && data["totalBuschelCapacity"].toString().length > 15) {
                err["totalBuschelCapacity"] = t("max-15-digits")
            }
            if (!data["totalAcresCropped"]) {
                err["totalAcresCropped"] = t("missingRequiredField")
            }
            if (data["totalAcresCropped"] && data["totalAcresCropped"].toString().length > 15) {
                err["totalAcresCropped"] = t("max-15-digits")
            }
            const acresPerCrop = data["acresPerCrop"] as Record<string, number | string | null>
            if (!Object.keys(initialPerCrop).concat("other").some((key) => acresPerCrop[key] !== null)) {
                err["acresPerCrop"] = { "options": t("missingRequiredField") }
            } else {
                const acresPerCropErr: Record<string, string> = {}
                Object.keys(initialPerCrop).forEach((key) => {
                    if (acresPerCrop[key] !== null && acresPerCrop[key].toString().length > 15) {
                        acresPerCropErr[key] = t("max-15-digits")
                    }
                })
                if (acresPerCrop["other"] !== null && acresPerCrop["other"].toString().length > 100) {
                    acresPerCropErr["other"] = t("max-100-chars")
                }
                if (Object.keys(acresPerCropErr).length) {
                    err["acresPerCrop"] = acresPerCropErr;
                }
            }
            const yieldPerCrop = data["yieldPerCrop"] as Record<string, number | string | null>
            if (!Object.keys(initialPerCrop).concat("other").some((key) => yieldPerCrop[key] !== null)) {
                err["yieldPerCrop"] = { "options": t("missingRequiredField") }
            } else {
                const yieldPerCropErr: Record<string, string> = {}
                Object.keys(initialPerCrop).forEach((key) => {
                    if (yieldPerCrop[key] !== null && yieldPerCrop[key].toString().length > 15) {
                        yieldPerCropErr[key] = t("max-15-digits")
                    }
                })
                if (yieldPerCrop["other"] !== null && yieldPerCrop["other"].toString().length > 100) {
                    yieldPerCropErr["other"] = t("max-100-chars")
                }
                if (Object.keys(yieldPerCropErr).length) {
                    err["yieldPerCrop"] = yieldPerCropErr;
                }
            }
            if (data["isTruckScaleOnSite"] === null) {
                err["isTruckScaleOnSite"] = t("missingRequiredField")
            }
            if (data["isTruckScaleOnSite"] && !data["truckScaleCapacity"]) {
                err["truckScaleCapacity"] = t("missingRequiredField")
            }
            if (data["truckScaleCapacity"] && data["truckScaleCapacity"].toString().length > 100) {
                err["truckScaleCapacity"] = t("max-100-chars")
            }
            if (data["areAnyBuildingsAddedOrRemoved"] === null) {
                err["areAnyBuildingsAddedOrRemoved"] = t("missingRequiredField")
            }
            if (data["areAnyBuildingsAddedOrRemoved"] && !data["comments"]) {
                err["comments"] = t("missingRequiredField")
            }
            if (data["comments"] && data["comments"].toString().length > 200) {
                err["comments"] = t("max-200-chars")
            }
            props.validate(props.operation)
            props.setData(props.operation, data)
            if (Object.keys(err).length) {
                props.setErrors(props.operation, err)
            }
        }
    }, [data, props, t])

    return (
        <>
            <div>
                <form onChange={(e) => props.handleFormChange(e, data, setData)}>
                    <table width={"100%"} role="none">
                        <tbody>
                            <GEVGrainLicenseType data={data}
                                operation={props.operation}
                                name={'grainLicenseType'}
                                errors={props.errors}
                            />
                            <GEVInput data={data}
                                operation={props.operation}
                                name={'licenseRegisteredTo'}
                                errors={props.errors}
                            />
                            <GEVYesNo
                                data={data}
                                operation={props.operation}
                                name={'isLicenseInUse'}
                                errors={props.errors}
                            />
                            <GEVInput data={data}
                                operation={props.operation}
                                name={'totalBuschelCapacity'}
                                type="number"
                                errors={props.errors}
                            />
                            <GEVInput data={data}
                                operation={props.operation}
                                name={'totalAcresCropped'}
                                type="number"
                                errors={props.errors}
                            />
                            <GEVPerCrop data={data}
                                operation={props.operation}
                                name={'acresPerCrop'}
                                errors={props.errors}
                            />
                            <GEVPerCrop data={data}
                                operation={props.operation}
                                name={'yieldPerCrop'}
                                errors={props.errors}
                            />
                            <GEVYesNo
                                data={data}
                                operation={props.operation}
                                name={'isTruckScaleOnSite'}
                                errors={props.errors}
                            />
                            <GEVInput data={data}
                                operation={props.operation}
                                name={'truckScaleCapacity'}
                                errors={props.errors}
                            />
                            <GEVPercentageOfThroughput
                                data={data}
                                operation={props.operation}
                                name={'percentageOfThroughput'}
                                errors={props.errors}
                                onChange={potChange}
                            />
                            <GEVYesNo
                                data={data}
                                operation={props.operation}
                                name={'areAnyBuildingsAddedOrRemoved'}
                                errors={props.errors}
                            />
                            <GEVTexttarea data={data}
                                operation={props.operation}
                                name={'comments'}
                                errors={props.errors}
                            />
                        </tbody>
                    </table>
                </form>
            </div>
        </>
    );
}
