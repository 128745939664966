import React, { useEffect, useState } from 'react'
import './TranslationSwapper.css'
import {useTranslation} from "react-i18next";
import AnchorAsButton from '../AnchorAsButton/AnchorAsButton';

function TranslationSwapper() {
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(localStorage.getItem('prefLang') || 'en');

    useEffect(() => {
        if(!localStorage.getItem('prefLang')) {
            localStorage.setItem('prefLang', 'en');
        }
        i18n.changeLanguage(lang);
    }, [lang, i18n])

    const toggleLang = () => {
        const updated = lang === 'en' ? 'fr' : 'en';
        setLang(updated);
        localStorage.setItem('prefLang', updated);
    }

    return (
        <div className="translation-text">
            <AnchorAsButton onClick={toggleLang}>{lang === 'en' ? "Français" : "English"}</AnchorAsButton>
        </div>
    )
}

export default TranslationSwapper