import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadOnly } from '../../utils/ReadOnlyContext';
import { OperationFieldProps } from '../../pages/BusinessOperations/BusinessOperations';
import "./GEV.css";

export default function GEVInput(props: OperationFieldProps): JSX.Element {
    const { t } = useTranslation('gev')
    const { isReadOnly } = useReadOnly()
    const [data, setData] = useState(props.data[props.name] as string | number | null)

    useEffect(() => {
        setData(props.data[props.name] as string | number | null)
    }, [props.data, props.name])

    return (
        <>
            <tr className='gev-input-tr'>
                <td>
                    <div className='gev-input-label'>{t(props.operation + "." + props.name)}</div>
                    <input type={props.type === "number" ? "number" : "text"}
                        id={props.operation + "." + props.name}
                        name={props.operation + "." + props.name}
                        value={data === null ? "" : data}
                        className='gev-input-100'
                        title={t(props.operation + "." + props.name)}
                        aria-label={t(props.operation + "." + props.name)}
                        autoComplete="off"
                        disabled={isReadOnly}
                        onChange={() => {
                            //
                        }}
                        onKeyDown={(e) => {
                            if (props.type === "number") {
                                ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                            }
                        }}
                        onWheel={(e) => {
                            if (props.type === "number") {
                                e.currentTarget.blur()
                            }
                        }}
                    />
                    <div className="input-error-message">
                        {props.errors && props.errors[props.name] as string}
                    </div>
                </td>
            </tr>
        </>
    )
}
