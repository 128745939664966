import {
    MANAGE_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID,
    RESET_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID,
} from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types";

export interface IncomeAttributeState extends BaseAsyncReducerState {
    snapshotId: number,
}

// Define an initial state value
const initialState: IncomeAttributeState = {
    success: false,
    message: "",
    loading: false,
    error: false,
    data: [],
    snapshotId: 0,
}

function getincomeAttributesReducer(state = initialState, action: AppAction | LocalAction) {
    if (action.type === RESET_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID) {
        return { ...initialState };
    } else {
        return defaultAsyncReducer(state, action as AppAction, MANAGE_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID);
    }
}

export default getincomeAttributesReducer;