import {
    GET_COMMENT,
    RESET_COMMENT,
} from "../../actionTypes/actionsTypes";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types";

export type CommentDataClass = {
    commentId: number | null;
    commentDate: string | null;
    commentTypeCode: string | null;
    userName: string | null;
    profileId: number | null;
    campaignId: number | null;
    commentText: string | null;
    deletedFlag: boolean | null;
}

export interface CommentState extends BaseAsyncReducerState {
    success: boolean,
    message: string,
    data: CommentDataClass[]
}

// Define an initial state value
const initialState: CommentState = {
    success: false,
    message: "",
    loading: false,
    error: false,
    data: []
}

function getCommentReducer(state = initialState, action: AppAction | LocalAction) {
    switch (action.type) {
        case GET_COMMENT.PENDING:
            return {
                ...state,
                loading: true,
                error: false,
                success: false,
            }
        case GET_COMMENT.SUCCESS:
            return {
                ...state,
                success: (action as AppAction).payload.success,
                data: (action as AppAction).payload.data,
                loading: false,
                error: false,
            }
        case GET_COMMENT.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
            }

        case RESET_COMMENT:
            return {
                ...initialState
            }
        default:
            return state

    }
}

export default getCommentReducer;