import ValidateInternalTokenState from "../../../types/auth/ValidateInternalTokenState";
import { VALIDATE_INTERNAL_TOKEN } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction } from "../../types";

const initialState: ValidateInternalTokenState = {
  success: false,
  loading: false,
  error: false
}

function getValidInternalTokenReducer(state = initialState, action: AppAction) {
  return defaultAsyncReducer(state, action, VALIDATE_INTERNAL_TOKEN);
}

export default getValidInternalTokenReducer;