import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";

import landing_en from "./translations/en/landing.json";
import landing_fr from "./translations/fr/landing.json";

import navigation_en from "./translations/en/navigation.json";
import navigation_fr from "./translations/fr/navigation.json";

import contact_info_en from "./translations/en/contact_info.json";
import contact_info_fr from "./translations/fr/contact_info.json";

import table_en from "./translations/en/table.json";
import table_fr from "./translations/fr/table.json";

import transition_en from "./translations/en/transition.json";
import transition_fr from "./translations/fr/transition.json";

import suite_breakdown_table_en from "./translations/en/suite_breakdown_table.json";
import suite_breakdown_table_fr from "./translations/fr/suite_breakdown_table.json";

import pie_en from "./translations/en/pie.json";
import pie_fr from "./translations/fr/pie.json";

import lease_table_en from "./translations/en/lease_table.json";
import lease_table_fr from "./translations/fr/lease_table.json";

import parking_property_en from "./translations/en/parking_property.json"
import parking_property_fr from "./translations/fr/parking_property.json"

import residential_parking_en from "./translations/en/residential_parking.json"
import residential_parking_fr from "./translations/fr/residential_parking.json"

import assisted_living_en from "./translations/en/assisted_living.json"
import assisted_living_fr from "./translations/fr/assisted_living.json"

import unit_en from "./translations/en/unit.json";
import unit_fr from "./translations/fr/unit.json";

import disclosure_en from "./translations/en/disclosure.json";
import disclosure_fr from "./translations/fr/disclosure.json";

import help_en from "./translations/en/help.json";
import help_fr from "./translations/fr/help.json";

import room_statistics_en from "./translations/en/room_statistics.json"
import room_statistics_fr from "./translations/fr/room_statistics.json"

import ltc_profile_attribute_en from "./translations/en/ltc_profile_attribute.json"
import ltc_profile_attribute_fr from "./translations/fr/ltc_profile_attribute.json"

import rounds_rate_en from "./translations/en/rounds_rate.json"
import rounds_rate_fr from "./translations/fr/rounds_rate.json"

import rent_inclusions_en from "./translations/en/rent_inclusions.json"
import rent_inclusions_fr from "./translations/fr/rent_inclusions.json"

import number_of_sites_en from "./translations/en/number_of_sites.json"
import number_of_sites_fr from "./translations/fr/number_of_sites.json"

import gev_en from "./translations/en/gev.json"
import gev_fr from "./translations/fr/gev.json"
import guided_wizard_en from "./translations/en/guided_wizard.json"
import guided_wizard_fr from "./translations/fr/guided_wizard.json"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        common: common_en,
        landing: landing_en,
        navigation: navigation_en,
        table: table_en,
        contact_info: contact_info_en,
        transition: transition_en,
        suite_breakdown_table: suite_breakdown_table_en,
        pie: pie_en,
        unit: unit_en,
        lease_table: lease_table_en,
        parking_property: parking_property_en,
        disclosure: disclosure_en,
        residential_parking: residential_parking_en,
        assisted_living: assisted_living_en,
        room_statistics: room_statistics_en,
        rent_inclusions: rent_inclusions_en,
        rounds_rate: rounds_rate_en,
        help: help_en,
        ltc_profile_attribute: ltc_profile_attribute_en,
        number_of_sites: number_of_sites_en,
        gev: gev_en,
        guided_wizard: guided_wizard_en
      },
      fr: {
        common: common_fr,
        landing: landing_fr,
        navigation: navigation_fr,
        table: table_fr,
        contact_info: contact_info_fr,
        transition: transition_fr,
        suite_breakdown_table: suite_breakdown_table_fr,
        pie: pie_fr,
        unit: unit_fr,
        lease_table: lease_table_fr,
        parking_property: parking_property_fr,
        disclosure: disclosure_fr,
        residential_parking: residential_parking_fr,
        assisted_living: assisted_living_fr,
        room_statistics: room_statistics_fr,
        rent_inclusions: rent_inclusions_fr,
        rounds_rate: rounds_rate_fr,
        help: help_fr,
        ltc_profile_attribute: ltc_profile_attribute_fr,
        number_of_sites: number_of_sites_fr,
        gev: gev_fr,
        guided_wizard_fr
      }
    },
    lng: localStorage.getItem("prefLang") || "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
        escapeValue: false, // react already safes from xss
    }
});

export default i18n;