import { combineReducers } from "redux";
import getProfileOverviewReducer from "./getProfileOverviewReducer";
import getProfileDetailsReducer from "./getProfileDetailsReducer";
import putProfileAttributesReducer from "./putProfileAttributesReducer";
import putProfileDetailsReducer from "./putProfileDetailsReducer";
import getProfileByRollReducer from "./getProfileByRollReducer";
import getProfileAttributesReducer from "./profileAttributes/getAttributesReducer";

const profileReducer = combineReducers({
  getProfileOverview: getProfileOverviewReducer,
  getProfileDetails: getProfileDetailsReducer,
  putProfileDetails: putProfileDetailsReducer,
  getProfileAttributes: getProfileAttributesReducer,
  putProfileAttributes: putProfileAttributesReducer,
  getProfileByRoll: getProfileByRollReducer,
});

export default profileReducer;