import ProfileDataType from "../../../types/profile/ProfileData";
import { API_REQUEST, GET_PROFILE_DETAILS, GET_PROFILE_OVERVIEW, RESET_PUT_PROFILE_DETAILS, RESET_GET_PROFILE_DETAILS, SAVE_RESULT, GET_PROFILE_ATTRIBUTES, RESET_GET_PROFILE_ATTRIBUTES } from "../../actionTypes/actionsTypes";
import { AppAction, LocalAction } from "../../types";

export const getProfileOverview = (profileID: number | string): AppAction => ({
  type: API_REQUEST,
  payload: {
    url: `/income/profile-overview/${profileID}`,
    next: GET_PROFILE_OVERVIEW,
    method: 'GET',
    authorization: true
  }
});

export const getProfileDetails = (snapshotId: number | string): AppAction => ({
  type: API_REQUEST,
  payload: {
    url: `/income/profile-details/${snapshotId}`,
    next: GET_PROFILE_DETAILS,
    method: 'GET',
    authorization: true
  }
})

export const putProfileDetails = (profileType: string, snapshotId: string | number, profileData: ProfileDataType): AppAction => ({
  type: API_REQUEST,
  payload: {
    url: `/income/profile-details/${snapshotId}/${profileType}`,
    data: profileData,
    next: SAVE_RESULT,
    method: 'PUT',
    authorization: true
  }
})

export const resetPutProfileDetails = (): LocalAction => ({
  type: RESET_PUT_PROFILE_DETAILS
})

export const resetGetProfileDetails = (): LocalAction => ({
  type: RESET_GET_PROFILE_DETAILS
})

export const getProfileAttributes = (profileType: string, snapshotId: number | string): AppAction => {
  return {
    type: API_REQUEST,
    payload: {
      url: `/income/profile/${profileType}/${snapshotId}/get-profile-attributes`,
      next: GET_PROFILE_ATTRIBUTES,
      method: 'GET',
      authorization: true
    }
  }
}

export const resetGetProfileAttributes = (): LocalAction => ({
  type: RESET_GET_PROFILE_ATTRIBUTES
})

export const putProfileAttributes = (profileType: string, snapshotId: number | string, profileAttributes: Record<string, unknown>): AppAction => ({
  type: API_REQUEST,
  payload: {
    url: `/income/profile/${profileType}/${snapshotId}/update-profile-attributes`,
    data: { "attributes": profileAttributes },
    next: SAVE_RESULT,
    method: 'PUT',
    authorization: true
  }
})
