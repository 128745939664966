/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 */
import React, { useEffect, useState } from "react";
import Switch from "../../components/common/Switch/Switch";
import Avatar from "../../components/common/Avatar/Avatar";
import { ProfileOverviewState } from "../../redux/reducers/getProfile/getProfileOverviewReducer";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/root";
import './Header.css';
import { useReadOnly } from "../../utils/ReadOnlyContext";

interface HeaderProps {
  showAutoSave: boolean,
  showRollNumber?: boolean,
  isInternalUser: boolean,
}

function Header(props: HeaderProps): JSX.Element {
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
  const [lastSaved, setLastSave] = useState<Date>(new Date());
  const [autoSave, setAutoSave] = useState(true);
  const { isReadOnly } = useReadOnly()

  const formattedDate = lastSaved.toLocaleDateString('en-CA', {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  })
  const formattedTime = lastSaved.toLocaleTimeString('en-CA', {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "short"
  })

  useEffect(() => {
    if (autoSave) {
      const interval = setInterval(() => {
        setLastSave(new Date());
      }, 2 * 60 * 1000)
      return () => clearInterval(interval);
    } else {
      return undefined;
    }
  }, [autoSave]);

  return (
    <header>
      {props.isInternalUser && <div>
        <p className="internal-user-tag">Internal User View</p>
      </div>}
      <div className="autosave-container">
        {props.showAutoSave && !isReadOnly && (<>
          <div className="switch-block">
            <div className="autosave-block">Auto-save</div>
            <Switch id="autosave" onChange={(e) => setAutoSave(e.target.checked)} checked={autoSave} />
          </div>
          <div className="last-saved-text">
            {autoSave ? <i>Last saved on {formattedDate} at {formattedTime}</i> : <> </>}
          </div>
        </>)}
      </div>
      <div className="profile-container">
        {!props.isInternalUser ? <>
          <div className="roll-info-container">
            {(props.showRollNumber !== false) && (
              <p className="strong">{localStorage.getItem('rollNumber')}</p>
            )}
            <p>{profileOverview.profile.profile_address}</p>
          </div>
          <div className="avatar-area">
            <Avatar />
          </div></> :
          <div className="roll-info-container">
            <p className="strong">User: {localStorage.getItem('username')}</p>
          </div>}
      </div>
    </header>
  );
}

export default Header;