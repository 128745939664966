import { faAddressCard, faListCheck, faFilePdf, faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import MenuItem from "../../../types/menu/MenuItem";

const GEVNavList: MenuItem[] = [
  {
    id: 1,
    name: 'contactInfo',
    componentName: 'ContactInfo',
    complete: false,
    icon: faAddressCard,
  },
  {
    id: 2,
    name: 'businessOperations',
    componentName: 'BusinessOperations',
    complete: false,
    icon: faListCheck,
  },
  {
    id: 3,
    name: 'summaryReview',
    componentName: 'Summary',
    complete: false,
    icon: faFilePdf,
  },
  {
    id: 4,
    name: 'submit',
    componentName: 'Submit',
    complete: false,
    icon: faFileInvoiceDollar,
  }
];

export default GEVNavList;