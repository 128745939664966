import { faBuilding, faCartShopping, faFileInvoiceDollar, faFilePdf, faMoneyBillTrendUp } from "@fortawesome/free-solid-svg-icons";
import MenuItem from "../../../types/menu/MenuItem";

const MTLNavList: MenuItem[] = [
  {
    id: 1,
    name: 'incomeExpense',
    complete: false,
    icon: faMoneyBillTrendUp,
    subItems: [
      {
        id: 11,
        name: 'contactInfo',
        componentName: 'ContactInfo',
        complete: false
      },
      {
        id: 12,
        name: 'roomStatistics',
        componentName: 'RoomStatistics',
        complete: false
      },
      {
        id: 13,
        name: 'motelRevenue',
        componentName: 'MotelRevenue',
        complete: false
      },
      {
        id: 14,
        name: 'directProductCost',
        componentName: 'DirectProductCost',
        complete: false
      },
      {
        id: 15,
        name: 'undistributedExpenses',
        componentName: 'UndistributedExpenses',
        complete: false
      },
      {
        id: 16,
        name: 'fixedCharges',
        componentName: 'FixedCharges',
        complete: false
      },
      {
        id: 17,
        name: 'reserveForReplacementAllowance',
        componentName: 'ReserveForReplacementAllowance',
        complete: false
      },
      {
        id: 18,
        name: 'capitalCostsSummary',
        componentName: 'CapitalCostsSummary',
        complete: false
      },
      {
        id: 19,
        name: 'comments',
        componentName: 'Comment',
        complete: false
      }
    ],
  },
  {
    id: 2,
    name: 'apartment',
    complete: false,
    icon: faBuilding,
    subItems: [
      {
        id: 21,
        name: 'suiteBreakdownTable',
        componentName: 'SuiteBreakdown',
        complete: false
      }
    ]
  },
  {
    id: 3,
    name: 'commercial',
    complete: false,
    icon: faCartShopping,
    subItems: [
      // {
      //   id: 31,
      //   name: 'LeaseImprovementSummary',
      //   forInternal: false,
      //   complete: false
      // },
      {
        id: 31,
        name: 'leaseInformationTable',
        componentName: 'LeaseInfo',
        complete: false
      },
      {
        id: 32,
        name: 'parkingPropertySummary',
        complete: false,
        componentName: 'ParkingPropertySummary',
      }
    ]
  },
  {
    id: 4,
    name: 'summaryReview',
    componentName: 'Summary',
    complete: false,
    icon: faFilePdf
  },
  {
    id: 5,
    name: 'submit',
    componentName: 'Submit',
    complete: false,
    icon: faFileInvoiceDollar
  }
];

export default MTLNavList;