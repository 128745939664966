import { SET_SAVING, RESET_SAVING, CHANGES_MADE, RESET_SAVEDATA } from "../../actionTypes/actionsTypes";
import { LocalAction } from "../../types";

export interface SaveDataState {
  changesPending: boolean;
  saving: boolean;
}

const initialState: SaveDataState = {
  changesPending: false,
  saving: false
}

function getSaveDataReducer(state = initialState, action: LocalAction) {
  switch (action.type) {
    case CHANGES_MADE:
      return {
        ...state,
        changesPending: true
      }
    case SET_SAVING:
      return {
        ...state,
        saving: true
      }
    case RESET_SAVING:
      return {
        ...state,
        saving: false
      }
    case RESET_SAVEDATA:
      return initialState
    default:
      return state;
  }
}

export default getSaveDataReducer;