import { DELETE_INCOME_UNIT_BY_ID, RESET_DELETE_INCOME_BY_ID } from "../../actionTypes/actionsTypes";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types";

// Define an initial state value for the app
const initialState: BaseAsyncReducerState = {
    loading: false,
    error: false
}
  
function deleteIncomeUnitReducer(state = initialState, action: AppAction | LocalAction) {

    switch (action.type) {
        case DELETE_INCOME_UNIT_BY_ID.PENDING:
            return {
                loading: true,
                error: false,
            }

        case DELETE_INCOME_UNIT_BY_ID.SUCCESS:
            return {
                ...state,
                success: (action as AppAction).payload.success,
                loading: false,
                error: false,

            }

        case DELETE_INCOME_UNIT_BY_ID.ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: true
            }

        case RESET_DELETE_INCOME_BY_ID:
            return {
                ...initialState
            }

        default:
            // If the reducer doesn't care about this action type,
            // return the existing state unchanged
            return state
    }
}

export default deleteIncomeUnitReducer;