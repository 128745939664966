import { API_REQUEST, GET_GLOBAL_SETTING } from "../../actionTypes/actionsTypes";
import { AppAction } from "../../types";

export const getGlobalSetting = (): AppAction => ({
    type: API_REQUEST,
    payload: {
        url: `/income/global_setting`,
        next: GET_GLOBAL_SETTING,
        method: 'GET',
        authorization: true
    }
});
