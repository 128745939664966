import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getIncomeFile } from '../../redux/actions/file/file'
import { setSaveResultSuccess } from '../../redux/actions/saveData/saveData'
import { RootState } from '../../redux/reducers/root'
import { ProfileOverviewState } from '../../redux/reducers/getProfile/getProfileOverviewReducer'
import { FileState } from '../../redux/reducers/getFile/getFileReducer'
import { SaveDataState } from '../../redux/reducers/getSaveData/saveDataReducer'
import Button from '../../components/common/Button/Button'
import { GeneratePDFPayload } from '../../types/pdf/GeneratePDFPayload'
import './Summary.css'

export default function Summary(): JSX.Element {
    const { t } = useTranslation('common')
    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
    const fileState: FileState = useSelector((state: RootState) => state.fileReducer.file)
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
    const [downloading, setDownloading] = useState(false)
    const rollNumber = localStorage.getItem('rollNumber')

    const downloadPreviewFile = () => {
        const snapshotRequestType = localStorage.getItem("isInternalUser") ? "3" : "2"
        const payload: GeneratePDFPayload = {
            language: i18n.language,
            rollNumber: rollNumber as string,
            profileId: profileOverview.profile.profile_id,
            campaignId: Number(localStorage.getItem('campaignId') as string),
            campaignYear: profileOverview.profile.current_campaign.year,
            snapshotRequestType: snapshotRequestType
        }
        dispatch(getIncomeFile(payload))
        setDownloading(true)
    }

    useEffect(() => {
        if (downloading && fileState.loading === false) {
            setDownloading(false)
            if (fileState.status === 200) {
                const base64string = "data:application/pdf;base64," + fileState.pdf
                const downloadLink = document.createElement("a")
                downloadLink.href = base64string
                downloadLink.download = rollNumber + "_" + profileOverview.profile.current_campaign.year + "_PIER.pdf"
                downloadLink.click()
            } else {
                toast.error(fileState.message)
            }
        }
    }, [downloading, fileState, profileOverview.profile.current_campaign.year, rollNumber])

    useEffect(() => {
        if (saveData.saving) {
            dispatch(setSaveResultSuccess())
        }
    }, [dispatch, saveData.saving])

    return (
        <div className='summary-page-container'>
            <FontAwesomeIcon icon={faPrint} className='print-icon' />
            <p className='print-wip-text'>{t('print-wip')}</p>
            <Button type='submit' text={t('print')} onClick={() => downloadPreviewFile()} />
        </div>
    )
}
