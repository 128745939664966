import React from "react";
import './Button.css'

interface ButtonProps {
    onClick?: React.MouseEventHandler;
    type?: "cancel" | "submit" | "disabled" | "secondary" | "danger";
    text?: string | JSX.Element;
    children?: React.ReactNode;
    disabled?: boolean;
    className?: string;
    id?: string;
    ariaLabel?: string;
    testId?: string;
    title?: string;
    forceEnabled?: boolean;
}

const buttonClasses = {
    cancel: "button-cancel",
    submit: "button-submit",
    disabled: "button-disabled",
    secondary: "button-secondary",
    danger: "button-danger"
}

export default function Button(props: ButtonProps): JSX.Element {
    let disabled = props.disabled;
    if (props.forceEnabled) {
        disabled = false;
    }
    return (
        <button id={props.id} disabled={disabled}
            className={disabled ? "button-disabled" : (props.type ? buttonClasses[props.type] : "default-button") + (props.className ? " " + props.className : "")}
            onClick={(e) => { props.onClick && props.onClick(e) }}
            aria-label={props.ariaLabel}
            data-testid={props.testId}
            title={props.title}>
            {props.text ?? ""}{props.children ?? ""}
        </button>
    )
}
