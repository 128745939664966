import { GET_FILE, RESET_FILE } from "../../actionTypes/actionsTypes";
import { AppAction, BaseAsyncReducerState } from "../../types";

export interface FileState extends BaseAsyncReducerState {
    s3url: string,
    pdf: string,
    message: string,
    link: string
}

const initialState: FileState = {
    loading: false,
    error: false,
    success: false,
    s3url: "",
    pdf: "",
    message: "",
    link: ""
}

function getFileReducer(state = initialState, action: AppAction) {
    const { payload } = action;
    switch (action.type) {
        case GET_FILE.PENDING:
            {
                return {
                    ...state,
                    loading: true,
                    error: false,
                    success: false,
                    s3url: "",
                    pdf: "",
                    message: "",
                }
            }
        case GET_FILE.SUCCESS:
            {
                return {
                    ...state,
                    ...payload,
                    loading: false,
                    error: false,
                    success: true,
                }
            }
        case GET_FILE.ERROR:
            return {
                ...state,
                ...payload,
                loading: false,
                error: true,
                success: false,
            }
        case RESET_FILE:
            return initialState
        default:
            return state
    }
}

export default getFileReducer;