import {
    API_REQUEST,
    MANAGE_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID,
    RESET_PUT_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID,
    SAVE_RESULT
} from "../../actionTypes/actionsTypes";
import { AppAction, LocalAction } from "../../types";

// Manage unit income action
export const getIncomeAttributesBySnapshotId = (snapshotId: number | string): AppAction => ({
    type: API_REQUEST,
    payload: {
        url: `/income/snapshots/${snapshotId}/income-attributes`,
        next: MANAGE_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID,
        method: 'GET',
        authorization: true
    }
});

export const putIncomeAttributesBySnapshotId = (snapshotId: number | string, attributes: Record<string, unknown>[]): AppAction => ({
    type: API_REQUEST,
    payload: {
        url: `/income/snapshots/${snapshotId}/income-attributes`,
        data: { "attributes": attributes },
        next: SAVE_RESULT,
        method: 'PUT',
        authorization: true
    }
});

export const resetPutIncomeAttributesBySnapshotId = (): LocalAction => ({
    type: RESET_PUT_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID
});