/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 * Update: 18/04/2024 - Mariam Bawa - Cast ELS Income attribute response to work with updated LookupsState
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/root';
import 'react-tabs/style/react-tabs.css';
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer';
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer';
import "../PIE.css";
import { getProfileAttributes, putProfileAttributes, resetGetProfileAttributes } from '../../../redux/actions/profile/profile';
import ProfileAttributeTableFragmentGenerator from '../../../utils/TableGenerator/ProfileAttributeTableFragmentGenerator';
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer';
import { ProfileAttributesState } from '../../../redux/reducers/getProfile/profileAttributes/getAttributesReducer';
import SubsidyData from '../../../types/subsidy/SubsidyData';
import updateProperties from '../../../utils/DataModifier/DataModifier';

const subsidyKeys = [
  'nursingPersonalCareTotal',
  'programSupportServiceTotal',
  'rawFoodTotal',
  'cchsaTotal',
  'equalizationAdjustmentTotal',
  'aggregateSubsidyTotal',
  'structuralComplianceFundingTotal',
  'municipalTaxAllowanceTotal',
  'additionalConstructionFundingTotal',
  'subsidyOtherTotal'
]
const initialSubsidyData: SubsidyData = {
  'nursingPersonalCareTotal': null,
  'programSupportServiceTotal': null,
  'rawFoodTotal': null,
  'cchsaTotal': null,
  'equalizationAdjustmentTotal': null,
  'aggregateSubsidyTotal': null,
  'structuralComplianceFundingTotal': null,
  'municipalTaxAllowanceTotal': null,
  'additionalConstructionFundingTotal': null,
  'subsidyOtherTotal': null,
}

export default function Subsidy(): JSX.Element {
  const { i18n } = useTranslation()
  const { t } = useTranslation("ltc_profile_attribute")
  const dispatch = useDispatch()

  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
  const profileAttributes: ProfileAttributesState = useSelector((state: RootState) => state.profileReducer.getProfileAttributes as ProfileAttributesState)
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
  const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult)
  const currentSnapshotId = Number(localStorage.getItem('currentSnapshotId') as string)
  const [subsidyData, setSubsidyData] = useState<SubsidyData>(initialSubsidyData)
  const [totalIncome, setTotalIncome] = useState<number | undefined>(undefined)

  useEffect(() => {
    dispatch(resetGetProfileAttributes())
    dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!profileAttributes.loading && profileAttributes.data) {
      const initData = { ...initialSubsidyData }
      updateProperties(initData, profileAttributes.data)
      setSubsidyData(initData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileAttributes.data, profileAttributes.loading])

  useEffect(() => {
    if (saveData.saving) {
      const fullData = { ...profileAttributes.data, ...subsidyData }
      dispatch(putProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId, fullData));
    }
  }, [currentSnapshotId, dispatch, profileAttributes.data, profileOverview.profile.profile_type_code, saveData.saving, subsidyData])

  useEffect(() => {
    if (saveResultState.status === 200) {
      dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId));
    }
  }, [saveResultState, dispatch, profileOverview.profile.profile_type_code, currentSnapshotId]);

  const handleChange = (newAmt: number | undefined, name: string, _oldAmt: number | undefined) => {
    const na = newAmt === undefined ? null : newAmt
    setSubsidyData({ ...subsidyData, [name]: na })
  }

  useEffect(() => {
    const initTotalIncome = () => {
      let total = 0
      subsidyKeys.forEach((key) => {
        total = total + (subsidyData[key as keyof SubsidyData] ?? 0)
      })
      setTotalIncome(total)
    }

    initTotalIncome()
  }, [subsidyData])

  const formatCurrency = (value: number | undefined) => {
    if (typeof value === "number") {
      const farmattedValue = value.toLocaleString(i18n.language, {
        style: 'currency',
        currency: "CAD",
        currencyDisplay: "code",
      }).replaceAll("CAD", '').trim()
      return farmattedValue
    }
    return value
  }

  return (
    <div className='tab-page-container'>
         <>
          <table className="table-with-border pie-table">
            <thead>
              <tr>
                <th id="attribute-label" scope="col"></th>
                <th id="attribute-value" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {subsidyKeys.map((key) => {
                return <ProfileAttributeTableFragmentGenerator key={key} name={key}
                  data={subsidyData[key as keyof SubsidyData]}
                  translationFile='ltc_profile_attribute'
                  valueChangeHandler={handleChange} isRequiredField={false} />
              })}
            </tbody>
          </table>
          <div className='tab-container-footer'>
            <div className="total-income">
              <p>{t('headers.total')} </p>
              <p>$ {formatCurrency(totalIncome)}</p>
            </div>
          </div>
        </>
    </div>
  )
}