import React from 'react'
import { useTranslation } from 'react-i18next';
import SidebarItemMedium from '../SidebarItemMedium/SidebarItemMedium';
import MenuItem from '../../../types/menu/MenuItem';
import './SidebarItemLarge.css'
import { SidebarItemProps } from '../../../layouts/Sidebar/Sidebar';

function SidebarItemLarge(props: SidebarItemProps) {
    const isChildActiveRecursive = (item: MenuItem, activeItemId: number) => {
        if (!item.subItems)
            return false;

        const isSubItemActive = item.subItems.some(subItem => {
            if (subItem.id === activeItemId) {
                return true;
            } else {
                return isChildActiveRecursive(subItem, activeItemId);
            }

        })
        return isSubItemActive;
    }

    const { t } = useTranslation('navigation');
    const isActive = props.item.id === props.activeItemId;
    const isChildActive = isChildActiveRecursive(props.item, props.activeItemId);
    const isAnyActive = isActive || isChildActive

    return (
        <>
            <div className='sidebar-item-large-container'>
                <div onClick={() => { props.menuOnClick(props.item) }}
                    className={`sidebar-large-item sidebar-large-item-${isAnyActive ? 'active' : 'inactive'}`}>
                    <p className='sidebar-large-item-text'>
                        {t(props.item.name)}
                    </p>
                </div>
                {isAnyActive && props.item.subItems && props.item.subItems.map(item => (
                    <SidebarItemMedium key={item.id} item={item} activeItemId={props.activeItemId}
                        isChildActive={isChildActiveRecursive}
                        menuOnClick={props.menuOnClick}
                    />
                ))}
            </div>
        </>
    )
}

export default SidebarItemLarge