import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../../redux/reducers/root';
import MenuState from '../../../types/menu/MenuState';
import './SidebarItemSmall.css'
import { SidebarItemProps } from '../../../layouts/Sidebar/Sidebar';

function SidebarItemSmall(props: SidebarItemProps) {

    const { t } = useTranslation('navigation');
    const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);

    const isActive = menuState.activeItem.hasTabs ?
        (props.index === menuState.activeItem.selectedTabIndex)
        :
        (props.item.id === props.activeItemId);

    return (
        <div onClick={() => { props.menuOnClick({ ...menuState.activeItem, selectedTabIndex: props.index }) }}
            className={`sidebar-small-item ${isActive ? 'sidebar-small-item-active' : 'sidebar-small-item-inactive'}`}
        >
            <span className='sidebar-status-container'>
                {!isActive && props.item.complete && <FontAwesomeIcon icon={faCircleCheck} />}
            </span>
            <p className='sidebar-small-item-text'>
                {t(props.item.name)}
            </p>
        </div>
    )
}

export default SidebarItemSmall