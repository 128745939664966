import { AppAction, AsyncActionType, BaseAsyncReducerState } from "./types";


export default function defaultAsyncReducer(state: BaseAsyncReducerState, action: AppAction, actionType: AsyncActionType) {
    const { payload } = action;

    switch (action.type) {
        case actionType.PENDING:
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            }
        case actionType.SUCCESS:
            return {
                ...state,
                ...payload,
                loading: false,
                success: true,
                error: false,
            }
        case actionType.ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: payload
            };
        default:
            // If the reducer doesn't care about this action type,
            // return the existing state unchanged
            return state
    }
}