import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../redux/reducers/root";
import MenuItem from "../../../types/menu/MenuItem";
import MenuState from "../../../types/menu/MenuState";
import './Breadcrumb.css';

function Breadcrumb(): JSX.Element {
  const { t } = useTranslation('navigation');
  const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
  const [breadcrumbs, setBreadcrumbs] = useState<Array<MenuItem>>([])

  useEffect(() => {
    if (menuState.menu && menuState.menu.length > 0) {
      const bc = [] as Array<MenuItem>;
      let found = false;
      const search = (root: Array<MenuItem> | null | undefined) => {
        if (root && root.length > 0) {
          for (let i = 0; i < root.length; i++) {
            if (root[i].id === menuState.activeItem.id) {
              found = true;
              bc.push(root[i]);
              return;
            } else {
              search(root[i].subItems);
              if (found) {
                bc.push(root[i])
                return;
              }
            }
          }
        }
        return;
      };
      search(menuState.menu);
      setBreadcrumbs(bc.toReversed());
    }
  }, [menuState.menu, menuState.activeItem]);

  return (
    <div className="breadcrumbs-container">
      {
        breadcrumbs.map((breadcrumb, index) => {
          return (
            <div key={index} className="flex flex-row">
              {index === 0 ?
                ""
                :
                <div className={"padding-left-5 padding-right-5"}>
                  &gt;
                </div>
              }
              <div className={breadcrumb.id === menuState.activeItem.id ? "current" : ""}>
                {t(breadcrumb.name)}
              </div>
            </div>
          )
        }
        )
      }
    </div>
  );
}

export default Breadcrumb;