import React from 'react';
import Asterisk from '../common/Asterisk/Asterisk';
import Input from '../common/Input/Input';
import "./CommercialUnit.css";

interface CommercialUnitInputFieldProps {
    formData: Record<string, unknown>,
    name: string,
    type?: string,
    errors: Record<string, string>,
    heading: string,
    title?: string,
}

export default function CommercialUnitInputField(props: CommercialUnitInputFieldProps): JSX.Element {

    return (
        <>
            <tr>
                <td className='label-cell'>
                    {props.heading} <Asterisk />
                </td>
            </tr>
            <tr>
                <td>
                    <Input type={props.type === "number" ? "number" : "text"} id={props.name} name={props.name}
                        value={(typeof props.formData[props.name]) === "number" ? (props.formData[props.name] as number).toString() : props.formData[props.name] as string | undefined}
                        className='border unit-input'
                        title={props.title}
                        errorMessage={props.errors[props.name]}
                        hideErrorCross={true}
                        noTimer={true}
                        ariaLabel={props.heading}
                        positiveOnly={props.type === "number" ? true : false}
                    />
                </td>
            </tr>
        </>
    );
}
