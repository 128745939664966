import { GET_MEMORY_CARE_UNITS_BY_SNAPSHOT_ID } from "../../actionTypes/actionsTypes";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types";

export interface GetMemoryCareState extends BaseAsyncReducerState {
    tenantSummary: Record<string, unknown>[];
}

// Define an initial state value for the app
const initialState: GetMemoryCareState = {
    loading: false,
    error: false,
    success: false,
    tenantSummary: []
}
  
function getMemoryCareReducer(state = initialState, action: AppAction | LocalAction) {

    switch (action.type) {
        case GET_MEMORY_CARE_UNITS_BY_SNAPSHOT_ID.PENDING:
            return {
                loading: true,
                error: false,
                success: false,
            }

        case GET_MEMORY_CARE_UNITS_BY_SNAPSHOT_ID.SUCCESS:
            return {
                ...state,
                success: (action as AppAction).payload.success,
                tenantSummary: (action as AppAction).payload.data,
                status: (action as AppAction).payload.status,
                loading: false,
                error: false,
            }

        case GET_MEMORY_CARE_UNITS_BY_SNAPSHOT_ID.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
            }

        default:
            // If the reducer doesn't care about this action type,
            // return the existing state unchanged
            return state
    }
}

export default getMemoryCareReducer;