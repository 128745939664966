import { API_REQUEST, SEARCH_PROFILE_BY_ROLL } from "../../actionTypes/actionsTypes";
import { AppAction } from "../../types";

export const searchProfileByRoll = (roll: string): AppAction => ({
  type: API_REQUEST,
  payload: {
    url: `/income/profile/roll-search/${roll}`,
    next: SEARCH_PROFILE_BY_ROLL,
    method: 'GET',
    authorization: true
  }
});