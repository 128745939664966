import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IncomeAttributeState } from "../../../redux/reducers/getIncomeAttributes/getIncomeAttributesReducer";
import { RootState } from "../../../redux/reducers/root";
import { LookupsState } from "../../../redux/reducers/getLookups/lookupsReducer";
import { SaveDataState } from "../../../redux/reducers/getSaveData/saveDataReducer";
import { ProfileOverviewState } from "../../../redux/reducers/getProfile/getProfileOverviewReducer";
import { getLookups } from "../../../redux/actions/lookups/lookups";
import IncomeAttributeTableFragmentGenerator from "../../../utils/TableGenerator/IncomeAttributeTableFragmentGenerator";
import { getIncomeAttributesBySnapshotId, putIncomeAttributesBySnapshotId } from "../../../redux/actions/incomeAttributes/incomeAttributes";
import { SaveResultState } from "../../../redux/reducers/getSaveData/saveResultReducer";

export default function MotelRevenue() {
  const { t } = useTranslation(['common', 'pie']);
  const dispatch = useDispatch();
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
  const incomeAttributesState: IncomeAttributeState = useSelector((state: RootState) => state.incomeAttributesReducer.incomeAttributes);
  const lookupState = useSelector((state: RootState) => state.lookupsReducer.getLookups as LookupsState);
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
  const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);

  const [totalMotelRevenue, setTotalMotelRevenue] = useState(0);
  
  // calculated variables for keeping code clean
  const lookupType = profileOverview.profile.profile_type_code.toLowerCase() + "-income-attributes";
  const currentSnapshotId = Number(localStorage.getItem('currentSnapshotId') as string);

// This hook sends a request to fetch income attribute data if the following conditions are met:
// 1. Return type is import
// 2. A request to fetch wasn't alreadt sent
// 3. If system has a failed response and/or result of a different snapshot stored
useEffect(() => {
  if ((!incomeAttributesState.loading && !incomeAttributesState.success)
    || incomeAttributesState.snapshotId !== currentSnapshotId) {
    dispatch(getIncomeAttributesBySnapshotId(currentSnapshotId));
  }
  // eslint-disable-next-line
}, []);

// This hook fetches income attribute lookups if they are not already loaded in system
useEffect(() => {
  if (!lookupState.success) {
    dispatch(getLookups(lookupType));
  }
}, [dispatch, lookupState.success, lookupType]);

// If table is valid, this hook sends a save request and handles response
useEffect(() => {
  if (saveData.saving) {
    const incomeAttributes = (incomeAttributesState.data as Record<string, unknown>[]).filter((data) => data.value || data.description);
    dispatch(putIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string), incomeAttributes));
  }
}, [dispatch, incomeAttributesState.data, saveData.saving]);

useEffect(() => {
  if (saveResultState.status === 200) {
    dispatch(getIncomeAttributesBySnapshotId(currentSnapshotId));
  }
}, [dispatch, currentSnapshotId, saveResultState]);

const calculateTotal = useCallback(() => {
  let total = 0;
  let filteredAttributes: Record<string, unknown>[] = [];

  if (incomeAttributesState.data) {
    filteredAttributes = (incomeAttributesState.data as Record<string, unknown>[]).filter(row => {
      return lookupState.lookups[1]["motelRevenue"].some(f => {
        return f.incomeAttributeCode === row['code']
      })
    });
    
    filteredAttributes.forEach(record => {
      if(record['value']) {
        total += record['value'] as number
      }  
    });
  }
  return total;
}, [incomeAttributesState.data, lookupState]);

// This hook calculates Total Motel Revenue by tracking changes to income attribute data
useEffect(() => {
  if (!incomeAttributesState.loading && incomeAttributesState.data && lookupState.success) {
    const total = calculateTotal();
    setTotalMotelRevenue(total);
  }
}, [incomeAttributesState, calculateTotal, lookupState.success]);

// This functions handles changes made to income attribute data columns
const handleIncomeAttrChange = (newAmt: number | undefined | null, name: string, _oldAmt: number | undefined | null) => {
  const idx = name.lastIndexOf("-");
  if (idx !== -1) {
    const code = name.substring(idx + 1);
    const row = (incomeAttributesState.data as Record<string, unknown>[]).find(row => row.code === code);
    const na = newAmt ? newAmt : null;
    if (row) {
      row['value'] = newAmt;
    } else {
      (incomeAttributesState.data as Record<string, unknown>[]).push({
        snapshotId: currentSnapshotId,
        code: code,
        description: null,
        value: na,
        type: "1",
        percent: null,
        year: null,
      });
    }

    // Update totals
    const newTotal = calculateTotal();
    setTotalMotelRevenue(newTotal);
  }
}

  return (<>
    <p className="trash">{ t('alteast-one-mandatory-field') }</p>
      <table className="table-with-border pie-table">
        <thead></thead>
        <tbody>
          {
            lookupState.lookups[1]["motelRevenue"] && lookupState.lookups[1]["motelRevenue"].map((lookup, key) => {
              return <IncomeAttributeTableFragmentGenerator key={key} attribute={lookup}
                data={(incomeAttributesState.data as Record<string, unknown>[]).find(row => lookup.incomeAttributeCode === String(row.code))}
                valueChangeHandler={handleIncomeAttrChange}
                handleCommentChange={() => null}
                showSubheading={false}
                hasInputLabel={false}
                isOtherLabel={false}
                isRequiredField={false}
                isReplacementReserve={false}
              />
            })
          }
        </tbody>
      </table>
    <div className='tab-container-footer'>
      <div className="total-income">
        <p>{t('revenues.totalMotelRevenue', { ns: 'pie' })}</p>
        <p>$ {totalMotelRevenue}</p>
      </div>
    </div>
  </>);
}
