import { PUT_PROFILE_ATTRIBUTES, RESET_PUT_PROFILE_ATTRIBUTES } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types";

export interface PutProfileAttributesState extends BaseAsyncReducerState {
    success: boolean,
    message: string
}
  
const initialState: PutProfileAttributesState = {
    success: false,
    message: "",
    loading: false,
    error: false
}

function putProfileAttributesReducer(state = initialState, action: AppAction | LocalAction) {
    if (action.type === RESET_PUT_PROFILE_ATTRIBUTES) {
        return initialState;
      } else {
        return defaultAsyncReducer(state, action as AppAction, PUT_PROFILE_ATTRIBUTES)
      }
    
}

export default putProfileAttributesReducer;