import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import "./Input.css";
import { useReadOnly } from "../../../utils/ReadOnlyContext";

interface InputProps {
    name: string,
    id: string,
    type: "text" | "password" | "number" | "hidden" | "checkbox"
    className?: string,
    title?: string,
    placeholder?: string,
    ariaLabel?: string,
    label?: string,
    disabled?: boolean,
    onInput?: (e: string) => void;
    onChange?: React.ChangeEventHandler;
    onBlur?: React.ChangeEventHandler;
    onPaste?: React.ClipboardEventHandler;
    noTimer?: boolean;
    loading?: boolean;
    value?: string;
    isError?: boolean;
    isValid?: boolean;
    autoComplete?: string;
    errorMessage?: string;
    hideErrorCross?: boolean;
    maxLength?: number;
    testid?: string;
    readOnly?: boolean;
    autoCompleteAPIEnabled?: boolean;
    autoCompleteAPIItems?: Array<string>;
    table?: boolean;
    forcedType?: string | undefined;
    positiveOnly?: boolean;
}

export default function Input(props: InputProps): JSX.Element {

    const [val, setVal] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const { isReadOnly } = useReadOnly()

    useEffect(() => {
        if (props.value !== undefined) {
            setVal(props.value);
        }
    }, [props.value]);

    useEffect(() => {

        const handleInput = (e: string) => {
            if (typeof props.onInput === "function") {
                props.onInput(e);
            }
            return;
        }

        if (props.noTimer) {
            handleInput(val);
            return;
        } else {
            const timeOutId = setTimeout(() => handleInput(val), 1000);
            return () => clearTimeout(timeOutId)
        }

    }, [val, props]);

    // const autoCompleteItem = (value: AutoCompleteItem) => {
    //     return (
    //         <div onClick={() => {
    //             setVal(value.address);
    //             dispatch({ type: AUTOCOMPLETE.RESET });
    //         }}><strong>{`${value.address} - ${value.city}`}</strong></div>
    //     )
    // };

    const updateValueByModifiers = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        if(props.positiveOnly) {
            if(value.includes("-")) {
                return value.replace("-", "")
            }
        }

        if(!props.forcedType) {
            return value;
        }

        if(value === '') {
            return value;
        }

        if(props.forcedType === 'integer') {
            const intValue = parseInt(value, 10)
            if(isNaN(intValue)) {
                return ''
            }
            return String(intValue)
        } else if(props.forcedType === 'float') {
            return String(/^\d*\.?\d*$/.test(value) ? parseFloat(value) : value)
        }

        return value;
    }

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(props.onChange){
            props.onChange(e);
            setVal(updateValueByModifiers(e))
        } else {
            setVal(updateValueByModifiers(e))
        }
    }

    const focusOut = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(props.onBlur){
            props.onBlur(e);
            setVal((e.target as HTMLInputElement).value);
        }
    }

    return (
        <>
            <input
                title={props.title ?? props.label}
                readOnly={props.readOnly}
                value={val ? val : ""}
                className={`${props.table ? "table-input" : "table"}${props.type === "password" ? " password-input" : ""}${props.className ? " " + props.className : ""}${props.isError ? " error" : ""}`}
                onChange={(e) => {changeHandler(e)}                }
                type={props.type === "password" ? (showPassword ? "text" : "password") : props.type}
                id={props.id}
                name={props.name} placeholder={props.placeholder}
                disabled={props.disabled || isReadOnly}
                autoComplete={props.autoComplete}
                aria-label={props.ariaLabel}
                data-testid={props.testid ?? "input-test"}
                onPaste={props.onPaste}
                maxLength={props.maxLength}
                onBlur={(e) => focusOut(e)}>
                
            </input>

            {props.type === "password" && <button type="button" className="show-password-button" onClick={() => setShowPassword(!showPassword)} aria-label="show-password-button"><FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} /></button>}
            {/* <ValidateResultIcon isValid={props.isValid} /> */}
            {/* {props.loading ? <AmpInlineLoadingSpinner /> : ""} */}
            {props.errorMessage ? <div className="input-error-message">{!props.hideErrorCross && <FontAwesomeIcon className="red-cross" icon={faCircleXmark} />}{props.errorMessage}</div> : ""}
        </>
    )
}