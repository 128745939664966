import React, { createContext, ReactNode, useContext, useState } from "react";

const ReadOnlyContext = createContext<ReadOnlyContextValue | undefined>(undefined);

interface ReadOnlyContextValue {
    isReadOnly: boolean;
    setIsReadOnly: (value: boolean) => void;
}

interface ReadOnlyProviderProps {
    children: ReactNode;
    isInitiallyReadOnly: boolean;
}

export const ReadOnlyProvider = (props: ReadOnlyProviderProps) => {
    const [isReadOnly, setIsReadOnly] = useState(props.isInitiallyReadOnly)

    return (
        <ReadOnlyContext.Provider value={{ isReadOnly, setIsReadOnly }}>
            {props.children}
        </ReadOnlyContext.Provider>
    )
}

export const useReadOnly = (): ReadOnlyContextValue => {
    const context = useContext(ReadOnlyContext)
    if(!context) {
        // If context is accessed somewhere in the tree that does not have a context, default to not readonly. 
        return {
            isReadOnly: false,
            setIsReadOnly: () => undefined
        }
    }
    return context
}