import { UPDATE_ACKNOWLEDGEMENT_FLAG } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState } from "../../types";

export interface UpdateAcknowledgementState extends BaseAsyncReducerState {
  acknowledgementFlag?: boolean | null,
  campaign?: Record<string, unknown> | null
}

const initialState: UpdateAcknowledgementState = {
  success: false,
  loading: false,
  error: false
}

function updateAcknowledgementFlagReducer(state = initialState, action: AppAction) {
  return defaultAsyncReducer(state, action as AppAction, UPDATE_ACKNOWLEDGEMENT_FLAG);
}

export default updateAcknowledgementFlagReducer;