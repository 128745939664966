import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useReadOnly } from "../../../utils/ReadOnlyContext";

interface RadioButtonProps {
    name: string,
    id: string,
    text: string,
    textPosition: "left" | "right" | "top" | "below",
    value: string | number,
    onChange?: React.ChangeEventHandler;
    currentValue: string | number | boolean, // Set this value to make a radio button checked
    className?: string,
    ariaLabel?: string,
    label?: string,
    disabled?: boolean,
    isError?: boolean;
    isValid?: boolean;
    errorMessage?: string;
    hideErrorCross?: boolean;
    testid?: string;
    readOnly?: boolean;
}

export default function RadioButton(props: RadioButtonProps): JSX.Element {
    const { isReadOnly } = useReadOnly()

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(props.onChange){
            props.onChange(e);
        } 
    }

    return (
        <span>
            <span className={`component-radio ${props.textPosition}${props.className ? " " + props.className : ""}`}>
                <input
                    type="radio" 
                    id={props.id}
                    name={props.name}
                    title={props.label}
                    readOnly={props.readOnly}
                    onChange={(e) => {changeHandler(e)}}                
                    disabled={props.disabled || isReadOnly}
                    aria-label={props.ariaLabel}
                    data-testid={props.testid ?? "radio-test"}
                    value={props.value} 
                    checked={(typeof props.currentValue === "boolean") ? ((props.currentValue === true) ? "true" === props.value : ((props.currentValue === false) ? "false" === props.value : false)) : props.currentValue === props.value}
                /> 
                <label htmlFor={props.id}>{props.text}</label>
            </span>
            {props.errorMessage ? <div className="input-error-message">{!props.hideErrorCross && <FontAwesomeIcon className="red-cross" icon={faCircleXmark}/>}{props.errorMessage}</div> : ""}
        </span>
    )
}