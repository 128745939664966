import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OperationProps } from '../../pages/BusinessOperations/BusinessOperations';
import DatePicker from '../common/DatePicker/DatePicker';
import GEVTexttarea from './GEVTexttarea';

export default function NoFormSelected(props: OperationProps): JSX.Element {
    const { t } = useTranslation('gev')
    const [data, setData] = useState(props.data)

    useEffect(() => {
        if (props.validating) {
            const err: Record<string, string> = {}
            if (!data["changesToProperty"]) {
                err["changesToProperty"] = t("missingRequiredField")
            }
            if (data["changesToProperty"] && data["changesToProperty"].toString().length > 200) {
                err["changesToProperty"] = t("max-200-chars")
            }
            if (!data["dateOfChange"]) {
                err["dateOfChange"] = t("missingRequiredField")
            }
            props.validate(props.operation)
            props.setData(props.operation, data)
            if (Object.keys(err).length) {
                props.setErrors(props.operation, err)
            }
        }
    }, [data, props, t])

    return (
        <>
            <div>
                <form onChange={(e) => props.handleFormChange(e, data, setData)}>
                    <table width={'100%'} role="none">
                        <tbody>
                            <GEVTexttarea data={data}
                                operation={props.operation}
                                name="changesToProperty"
                                errors={props.errors}
                            />
                            <tr className='gev-input-tr'>
                                <td>
                                    {t(props.operation + ".dateOfChange")}
                                    <DatePicker id="dateOfChange" name="dateOfChange"
                                        date={data["dateOfChange"] === null ? "" : data["dateOfChange"] as string}
                                        position="right"
                                        title={t(props.operation + ".dateOfChange")}
                                        errorMessage={props.errors && props.errors["dateOfChange"] as string}
                                        onChange={(e) => setData({ ...data, "dateOfChange": e ? e.toISOString().substring(0, 10) : null })} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </>
    )
}
