/**
 * Update: 18/04/2024 - Mariam Bawa - Refactor code to use IncomeAttributeTableFragmentGenerator to generate table content and cast data to IncomeAttributeLookupObject
*  Update: 18/04/2024 - Mariam Bawa - Added thead element in markup to meet AODA standards. 
Element is then hidden from UI using CSS
*/
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IncomeAttributeState } from "../../../redux/reducers/getIncomeAttributes/getIncomeAttributesReducer";
import { RootState } from "../../../redux/reducers/root";
import IncomeAttributeLookupObject from "../../../types/lookups/IncomeAttributeLookupObject";
import IncomeAttributeTableFragmentGenerator from "../../../utils/TableGenerator/IncomeAttributeTableFragmentGenerator";
import { GlobalSettingState } from "../../../redux/reducers/getGlobalSetting/getGlobalSettingReducer";

export interface CapitalCostProps {
  lookups: IncomeAttributeLookupObject[],
  name: string,
  incomeAttributes?: Record<string, unknown>[]
}

export default function CapitalCostTab(props: CapitalCostProps): JSX.Element {
  const { i18n } = useTranslation();
  const { t } = useTranslation(['pie', 'navigation']);
  const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);
  const incomeAttributesState: IncomeAttributeState = useSelector((state: RootState) => state.incomeAttributesReducer.incomeAttributes);
  let total = 0;

  const findAttributeData = (attributeCode: string) => {
    let incomeAttributesData: Record<string, unknown> | undefined = undefined;
    if (incomeAttributesState.data) {
      incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attributeCode);
    }
    return incomeAttributesData;
  }

  const handleCurrencyChange = (newAmt: number | undefined | null, name: string, _oldAmt: number | undefined | null) => {
    const idx = name.lastIndexOf("-");
    if (idx !== -1) {
      const code = name.substring(idx + 1);
      const incomeAttributesData = findAttributeData(code);
      const na = newAmt ? newAmt : null;
      if (incomeAttributesData) {
        incomeAttributesData["value"] = na;
      } else {
        (incomeAttributesState.data as Record<string, unknown>[]).push({
          snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
          code: code,
          description: null,
          value: na,
          type: "3",
          percent: null,
          year: null,
        });
      }
    }
  }
  const formatCurrency = (value: number | undefined) => {
    if (typeof value === "number") {
      const farmattedValue = value.toLocaleString(i18n.language, {
        style: 'currency',
        currency: "CAD",
        currencyDisplay: "code",
      }).replaceAll("CAD", '').trim();
      return farmattedValue;
    }
    return value;
  }

  return (
    <>
      {
        props.lookups &&
        <table className="table-with-border pie-table">
          <thead>
            <tr>
              <th id="attribute-label" scope="col"></th>
              <th id="attribute-value" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {
              props.lookups.map((attr, key) => {
                let incomeAttributesData: Record<string, unknown> | undefined = undefined;
                if (props.incomeAttributes) {
                  incomeAttributesData = props.incomeAttributes.find(({ code }) => code === attr.incomeAttributeCode);
                  if (incomeAttributesData && incomeAttributesData.value) {
                    total = total + (incomeAttributesData["value"] as number);
                  }
                }
                return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                  data={incomeAttributesData}
                  valueChangeHandler={handleCurrencyChange}
                  handleCommentChange={() => null}
                  showSubheading={false}
                  hasInputLabel={false}
                  isOtherLabel={false}
                  isRequiredField={false}
                  isReplacementReserve={false}
                />
              }
              )
            }
          </tbody>
        </table>
      }

      <div className='tab-container-footer'>
        <div className="total-income">
          <p>{t(props.name + '.total')}</p>
          <p>{(globalSettingState.setting["currency"] as Record<string, unknown>)["symbol"] as string} {formatCurrency(total)}</p>
        </div>
      </div>
    </>
  );
}
