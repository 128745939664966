import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initialGrainLicenseType, OperationFieldProps } from '../../pages/BusinessOperations/BusinessOperations';
import Checkbox from '../common/Checkbox/Checkbox';
import "./GEV.css";

export default function GEVGrainLicenseType(props: OperationFieldProps): JSX.Element {
    const { t } = useTranslation('gev')
    const [data, setData] = useState(props.data[props.name] as Record<string, boolean | null>)

    useEffect(() => {
        setData(props.data[props.name] as Record<string, boolean | null>)
    }, [props.data, props.name])

    return (
        <>
            <tr className='gev-input-tr'>
                <td>
                    <div className='gev-input-label'>{t(props.operation + "." + props.name)}</div>
                    <fieldset className='noborder-fieldset'>
                        <legend className='invisible-legend'><span>{t(props.operation + "." + props.name)}</span></legend>
                        <div className='gev-space-between'>
                            {
                                Object.keys(initialGrainLicenseType).map((option) => {
                                    return (
                                        <div key={option} className='flex flex-row align-items-center'
                                            title={t(props.operation + "." + props.name + "Options." + option)}>
                                            {t(props.operation + "." + props.name + "Options." + option)}&nbsp;&nbsp;
                                            <Checkbox name={props.operation + "." + props.name}
                                                value={option}
                                                checked={data[option] === true}
                                                onChange={() => {
                                                    //
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                )
                            }
                        </div>
                    </fieldset>
                    <div className="input-error-message">
                        {props.errors && props.errors[props.name] as string}
                    </div>
                </td>
            </tr>
        </>
    )
}
