import { combineReducers } from "redux";
import deleteIncomeUnitReducer from "./deleteIncomeUnitReducer";
import getincomeUnitReducer from "./getIncomeUnitReducer";
import getTenantSummaryReducer from "./getTenantSummaryReducer";
import getApartmentTenantSummaryReducer from "./getApartmentTenantSummaryReducer";
import updateUnitReducer from "./updateUnitReducer";
import createUnitReducer from "./createUnitReducer";
import getIndependentLivingReducer from "./getIndependentLivingReducer";
import getMemoryCareReducer from "./getMemoryCareReducer";
import getAssistedLivingReducer from "./getAssistedLivingReducer";

const incomeUnitReducer = combineReducers({
    getIncomeUnit: getincomeUnitReducer,
    deleteIncomeUnit: deleteIncomeUnitReducer,
    getTenentSummary: getTenantSummaryReducer,
    getIndependentLiving: getIndependentLivingReducer,
    getMemoryCare: getMemoryCareReducer,
    getAssistedLiving: getAssistedLivingReducer,
    getApartmentTenantSummary: getApartmentTenantSummaryReducer,
    putIncomeUnit: updateUnitReducer,
    postIncomeUnit: createUnitReducer,
});

export default incomeUnitReducer;