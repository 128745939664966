import ValidateTokenState from "../../../types/auth/ValidateTokenState";
import { VALIDATE_TOKEN } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction } from "../../types";

const initialState: ValidateTokenState = {
  valid: false,
  loading: false,
  error: false
}

function getValidTokenReducer(state = initialState, action: AppAction) {
  return defaultAsyncReducer(state, action, VALIDATE_TOKEN);
}

export default getValidTokenReducer;