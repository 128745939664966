import { API_REQUEST, UPDATE_ACKNOWLEDGEMENT_FLAG } from "../../actionTypes/actionsTypes";
import { AppAction } from "../../types";

export const updateAcknowledgementFlag = (new_flag: boolean, profileId: string | number, campaignId: string | number): AppAction => ({
  type: API_REQUEST,
  payload: {
    url: `/income/update-acknowledgement/${profileId}/${campaignId}`,
    next: UPDATE_ACKNOWLEDGEMENT_FLAG,
    method: 'POST',
    data: {
      "acknowledgementFlag": new_flag
    },
    authorization: true
  }
});