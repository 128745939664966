import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useReadOnly } from "../../../utils/ReadOnlyContext";

export interface Option {
    value: string | number | readonly string[] | undefined, 
    text: string,
    displayName?: string 
    disabled?: boolean, 
    subOptions?: Array<Option>
}

export interface DropdownProps {
    onChange?: React.ChangeEventHandler;
    className?: string;
    options: Array<Option | null>;
    value?: string | number;
    label?: string;
    ariaLabel?: string;
    title?: string;
    id?: string;
    name?: string;
    errorMessage?: string;
    hideErrorCross?: boolean;
    testid?: string;
    readOnly?: boolean;
    multiple?: boolean;
    forceEnabled?: boolean;
}

export default function Dropdown(props: DropdownProps): JSX.Element {

    const { onChange, className, options, value, label, ariaLabel, id, name, errorMessage, testid, title, readOnly, forceEnabled } = props
    const { isReadOnly } = useReadOnly()


    return (
        <>
            {label && <h5 id={id} className="dropdown-title">{label}</h5>}
            <select disabled={forceEnabled ? false : readOnly || isReadOnly} name={name} id={id} className={className} value={value} title={title} 
                onChange={(e) => onChange && onChange(e)} aria-label={ariaLabel} 
                aria-labelledby={id} data-testid={testid ?? "dropdown-test"} multiple={props.multiple}>
                {options.map((option, index) => {
                    if(option){
                        if (option.subOptions?.length) {
                            return <optgroup key={index} label={option.text}>
                                {option.subOptions.map((subOption, subIndex) => {
                                    return <option key={`${index}${subIndex}`} value={subOption.value}>{subOption.displayName}</option>
                                })}
                            </optgroup>
                        } else {
                            return <option key={index} value={option.value} disabled={option.disabled}>{option.text}</option>
                        }
                    } else {
                        return <></>
                    }
                })}
            </select>
            {props.errorMessage ? <div className="input-error-message">{!props.hideErrorCross && <FontAwesomeIcon className="red-cross" icon={faCircleXmark}/>}{errorMessage}</div> : ""}
        </>
    )
}