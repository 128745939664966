import React from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import RadioButton from '../common/RadioGroup/RadioButton';
import Checkbox from '../common/Checkbox/Checkbox';

interface StepUpWizardProps {
    updateWizardFunction : (e: undefined, i: number, f: string, n?: boolean, c?: boolean) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    hasStepUp: boolean | undefined;
    setHasStepUp: (value: boolean) => void;
    stepUpArr: number[];
    setStepUpArr: (value: number[]) => void;
    // for pagination
    totalPages: number | undefined;
    currentPage: number;
    changePage: (e: number) => void;
}

export default function StepUpWizard(props: StepUpWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");

    const checkboxEventHandler = (e: React.ChangeEvent<Element>, index: number) => {
        const newStepUpArr = [...props.stepUpArr];
        const indexOf = newStepUpArr.indexOf(index);
        if ((e.target as HTMLInputElement).checked) {
            if (indexOf < 0) {
                newStepUpArr.push(index);
            }
        } else {
            if (indexOf >= 0) {
                props.updateWizardFunction(undefined, index, "stepUpDate1");
                props.updateWizardFunction(undefined, index, "stepUpRate1");
                props.updateWizardFunction(undefined, index, "stepUpDate2");
                props.updateWizardFunction(undefined, index, "stepUpRate2");
                props.updateWizardFunction(undefined, index, "stepUpDate3");
                props.updateWizardFunction(undefined, index, "stepUpRate3");
                props.updateWizardFunction(undefined, index, "stepUpDate4");
                props.updateWizardFunction(undefined, index, "stepUpRate4");
                newStepUpArr.splice(indexOf, 1);
            }
        }
        props.setStepUpArr(newStepUpArr.sort());
    }

    const headings = [<th key="step-up-wizard-heading-1">{t("unit-heading.floor-level")}</th>,
        <th key="step-up-wizard-heading-2">{t("unit-heading.tenant-name")}</th>,
        <th key="step-up-wizard-heading-3">{t("unit-heading.unit-number")}</th>,
        <th key="step-up-wizard-heading-4">{t("unit-heading.step-up")}</th>]

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            for (let index = 10 * (props.currentPage - 1); index < Math.min(props.tenantList.length, 10 * props.currentPage); index++) {
                tableBody.push (<tr key={`step-up-wizard-row-${index}`}>
                    <td className='no-input-cell'><label>{props.floorDescriptionLookup[props.tenantList[index]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell tenant-name-cell'><p>{props.tenantList[index]["tenantName"] as string}</p></td>
                    <td className='no-input-cell tenant-unit-cell'><p>{props.tenantList[index]["tenantUnitNumber"] as string}</p></td>
                    <td className='center-cell wizard-grey-out-cell'>
                        {(props.tenantList[index]["leaseStatusCode"] as string) !== "1" && <Checkbox key={`step-up-wizard-${index}`} name={`step-up-wizard-${index}`} id={`step-up-wizard-${index}`}
                            defaultChecked={props.stepUpArr.indexOf(index) >= 0} onChange={(e) => checkboxEventHandler(e, index)}/>}
                    </td>
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <div className='wizard-radio-cell wizard-question'>
            <fieldset>
                <legend>{t("step-up-wizard.step-up-question")}</legend>
                <RadioButton name="step-up-question" id="step-up-question-yes" text={t("yes")} textPosition='right' value="true" currentValue={props.hasStepUp ?? ""} onChange={() => props.setHasStepUp(true)} />
                <RadioButton name="step-up-question" id="step-up-question-no" text={t("no")} textPosition='right' value="false" currentValue={props.hasStepUp ?? ""} onChange={() => props.setHasStepUp(false)} />
            </fieldset>
        </div>
        {props.hasStepUp === true && <DataTable headings={headings} className='wizard-table' totalPages={props.totalPages} currentPage={props.currentPage} changePage={props.changePage}>
            {buildTableContent()}
        </DataTable>}
    </div>)
}