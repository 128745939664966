import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OperationFieldProps } from '../../pages/BusinessOperations/BusinessOperations';
import { useReadOnly } from '../../utils/ReadOnlyContext';
import "./GEV.css";

export default function GEVTexttarea(props: OperationFieldProps): JSX.Element {
    const { t } = useTranslation('gev')
    const { isReadOnly } = useReadOnly()
    const [data, setData] = useState<string | null>(props.data[props.name] as string | null)

    useEffect(() => {
        setData(props.data[props.name] as string | null)
    }, [props.data, props.name])

    return (
        <>
            <tr className='gev-input-tr'>
                <td>
                    <div className='gev-input-label'>{t(props.operation + "." + props.name)}</div>
                    <div>
                        <textarea id={props.operation + "." + props.name}
                            name={props.operation + "." + props.name}
                            value={data === null ? "" : data}
                            title={t(props.operation + "." + props.name)}
                            rows={3}
                            readOnly={isReadOnly}
                            onChange={() => {
                                //
                            }}
                        />
                    </div>
                    <div className="input-error-message">
                        {props.errors && props.errors[props.name] as string}
                    </div>
                </td>
            </tr>
        </>
    )
}
