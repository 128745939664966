import React from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import RadioButton from '../common/RadioGroup/RadioButton';

interface UnitUseWizardProps {
    updateWizardFunction : (e: React.ChangeEvent<Element>, i: number, f: string, n?: boolean) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    unitUseOptions: Record<string, unknown>[];
    unitUseForAll: string;
    setUnitUseForAll: (value: string) => void;
    isSameType: boolean | undefined;
    setIsSameType: (value: boolean) => void;
    // for pagination
    totalPages: number | undefined;
    currentPage: number;
    changePage: (e: number) => void;
}

export default function UnitUseWizard(props: UnitUseWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");

    const headings = [<th key="basic-info-wizard-heading-1">{t("unit-heading.floor-level")}</th>,
        <th key="basic-info-wizard-heading-2">{t("unit-heading.tenant-name")}</th>,
        <th key="basic-info-wizard-heading-3">{t("unit-heading.unit-number")}</th>,
        <th key="basic-info-wizard-heading-4">{t("unit-heading.unit-use") + " " + t("unit-heading.select-one")}</th>]

        const buildUnitUseOptions = (index?: number): JSX.Element => {
            const unitUseOptions: JSX.Element[] = [];
            for (let i = 0; i < props.unitUseOptions.length; i++) {
                if (props.unitUseOptions[i]["activeFlag"] as string === "Y") {
                    unitUseOptions.push(<RadioButton key={`unit-use-option-${props.unitUseOptions[i]["unitSubtypeCode"] as string}` + (index === undefined ? "" : `-${index}`)} 
                        name={"unit-use-option" + (index === undefined ? "" : `-${index}`)} id={`unit-use-option-${props.unitUseOptions[i]["unitSubtypeCode"] as string}` + (index === undefined ? "" : `-${index}`)}
                        text={props.unitUseOptions[i]["unitSubtypeDescription"] as string} textPosition='right' value={props.unitUseOptions[i]["unitSubtypeCode"] as string} 
                        currentValue={(index !== undefined ? ((props.tenantList[index]["unitSubtypeCode"] as string) ?? "") : props.unitUseForAll)} 
                        onChange={(e) => (index !== undefined ? props.updateWizardFunction(e, index, "unitSubtypeCode") : props.setUnitUseForAll(props.unitUseOptions[i]["unitSubtypeCode"] as string))} />);
                }
            }
            return (<div className='wizard-radio-cell'>{unitUseOptions}</div>);
        }

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            for (let index = 10 * (props.currentPage - 1); index < Math.min(props.tenantList.length, 10 * props.currentPage); index++) {
                tableBody.push (<tr key={`basic-info-wizard-row-${index}`}>
                    <td className='no-input-cell small-cell'><label>{props.floorDescriptionLookup[props.tenantList[index]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell small-cell'><p>{props.tenantList[index]["tenantName"] as string}</p></td>
                    <td className='no-input-cell small-cell'><p>{props.tenantList[index]["tenantUnitNumber"] as string}</p></td>
                    <td className="wizard-grey-out-cell">{(props.tenantList[index]["leaseStatusCode"] as string) !== "1" && buildUnitUseOptions(index)}</td>
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <div className='wizard-radio-cell wizard-question'>
            <fieldset>
                <legend>{t("unit-use-wizard.unit-use-question")}</legend>
                <RadioButton name="unit-use-question" id="unit-use-question-yes" text={t("yes")} textPosition='right' value="true" currentValue={props.isSameType ?? ""} onChange={() => props.setIsSameType(true)} />
                <RadioButton name="unit-use-question" id="unit-use-question-no" text={t("no")} textPosition='right' value="false" currentValue={props.isSameType ?? ""} onChange={() => props.setIsSameType(false)} />
            </fieldset>
        </div>
        {props.isSameType === true && <>
            <fieldset>
                <legend>{t("unit-use-wizard.all-unit-use")}</legend>
                {buildUnitUseOptions()}
            </fieldset>
        </>}
        {props.isSameType === false && <DataTable headings={headings} className='wizard-table' totalPages={props.totalPages} currentPage={props.currentPage} changePage={props.changePage}>
            {buildTableContent()}
        </DataTable>}
    </div>)
}