import React from "react";
import ContactInfo from "../pages/PIE/ContactInfo/ContactInfo";
import NotFound from "../pages/NotFound";
import RevenueContainer from "../pages/PIE/Revenues/RevenueContainer";
import SuiteBreakdown from "../pages/Residential/SuiteBreakdown/SuiteBreakdown";
import Summary from "../pages/Summary/Summary";
import Submit from "../pages/Submit";
import CapitalCostsSummary from "../pages/PIE/CapitalCostsSummary";
import Comment from "../pages/PIE/Comment/Comment";
import LeaseInfo from "../pages/Commercial/LeaseInfo/LeaseInfo";
import ParkingPropertySummary from "../pages/Commercial/ParkingPropertySummary/ParkingPropertySummary";
import ExpenseContainer from "../pages/PIE/Expenses/ExpenseContainer";
import ResidentialParking from "../pages/Residential/ResidentialParking/ResidentialParking";
import AssistedLiving from "../pages/Residential/AssistedLiving/AssistedLiving";
import ReserveForReplacementAllowance from "../pages/PIE/ReserveForReplacementAllowance/ReserveForReplacementAllowance";
import IncomeAttributeTable from "../pages/PIE/IncomeAttribute/IncomeAttributeTable";
import CapitalCostContainer from "../pages/PIE/CapitalCost/CapitalCostContainer";
import RoomStatistics from "../pages/PIE/RoomStatistics/RoomStatistics";
import BedCounts from "../pages/PIE/BedCounts/BedCounts";
import Subsidy from "../pages/PIE/Subsidy/Subsidy";
import IncomeAttributeTableNoTotal from "../pages/PIE/IncomeAttribute/IncomeAttributeTableNoTotal";
import DirectProductCost from "../pages/PIE/MTL/DirectProductCost";
import FixedCharges from "../pages/PIE/MTL/FixedCharges";
import MotelRevenue from "../pages/PIE/MTL/MotelRevenue";
import UndistributedExpenses from "../pages/PIE/MTL/UndistributedExpenses";
import OccupancyStatistics from "../pages/PIE/OccupancyStatistics/OccupancyStatistics";
import RoundsRate from "../pages/PIE/RoundsRate";
import MemoryCare from "../pages/Residential/MemoryCare/MemoryCare";
import IndependentLiving from "../pages/Residential/IndependentLiving/IndependentLiving";
import RHOReserve from "../pages/PIE/RHOReserve";
import NumberOfSites from "../pages/PIE/NumberOfSites/NumberOfSites";
import BusinessOperations from "../pages/BusinessOperations/BusinessOperations";

const CompiledComponents: Record<string, JSX.Element> = {
  'ContactInfo': <ContactInfo />,
  'Comment': <Comment />,
  'CapitalCostsSummary': <CapitalCostsSummary />,
  'NotFound': <NotFound />,
  'RevenueContainer': <RevenueContainer />,
  'ExpenseContainer': <ExpenseContainer />,
  'ReserveForReplacementAllowance': <ReserveForReplacementAllowance />,
  'CapitalCostContainer': <CapitalCostContainer />,
  'IncomeAttributeTable': <IncomeAttributeTable />,
  'IncomeAttributeTableNoTotal': <IncomeAttributeTableNoTotal />,
  'LeaseInfo': <LeaseInfo />,
  'ParkingPropertySummary': <ParkingPropertySummary />,
  'AssistedLiving': <AssistedLiving />,
  'ResidentialParking': <ResidentialParking />,
  'SuiteBreakdown': <SuiteBreakdown />,
  'RoomStatistics': <RoomStatistics />,
  'OccupancyStatistics': <OccupancyStatistics />,
  'DirectProductCost': <DirectProductCost />,
  'FixedCharges': <FixedCharges />,
  'MotelRevenue': <MotelRevenue />,
  'UndistributedExpenses': <UndistributedExpenses />,
  'RoundsRate': <RoundsRate />,
  'MemoryCare': <MemoryCare />,
  'IndependentLiving': <IndependentLiving />,
  'Summary': <Summary />,
  'Submit': <Submit />,
  'BedCounts': <BedCounts />,
  'Subsidy': <Subsidy />,
  'RHOReserve': <RHOReserve />,
  'BusinessOperations': <BusinessOperations />,
  'NumberOfSites': <NumberOfSites />
};

export default CompiledComponents;
