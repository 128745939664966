import { GeneratePDFPayload } from "../../../types/pdf/GeneratePDFPayload";
import { API_REQUEST, GET_FILE } from "../../actionTypes/actionsTypes";
import { AppAction } from "../../types";

// Manage download file action
export const getHelpFile = (language: string, file: string): AppAction => ({
    type: API_REQUEST,
    payload: Object.assign({
        url: `/income/help/download/${language}/${file}`,
        next: GET_FILE,
        method: 'GET',
        authorization: true
    })
});

export const getIncomeFile = (payload: GeneratePDFPayload): AppAction => ({
    type: API_REQUEST,
    payload: Object.assign({
        url: `/income/generate-pdf`,
        next: GET_FILE,
        data: payload,
        method: 'POST',
        authorization: true
    })
});
