import { RESET_UPDATE_CAMPAIGN_STATUS, UPDATE_CAMPAIGN_STATUS } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types";

export interface UpdateCampaignState extends BaseAsyncReducerState {
  updated: boolean,
  message?: string,
  campaign: Record<string, unknown>
}

const initialState: UpdateCampaignState = {
  updated: false,
  campaign: {},
  loading: false,
  error: false
}

function updateCampaignStatusReducer(state = initialState, action: AppAction | LocalAction) {
  if (action.type === RESET_UPDATE_CAMPAIGN_STATUS) {
    return { ...initialState };
  } else {
    return defaultAsyncReducer(state, action as AppAction, UPDATE_CAMPAIGN_STATUS);
  }
}

export default updateCampaignStatusReducer;