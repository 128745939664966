import { PUT_INCOME_UNIT_BY_ID, RESET_PUT_INCOME_UNIT } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types";

export interface updateUnitState extends BaseAsyncReducerState {
    success: boolean,
    message: string
}
  
const initialState: updateUnitState = {
    success: false,
    message: "",
    loading: false,
    error: false
}

function updateUnitReducer(state = initialState, action: AppAction | LocalAction) {
    if (action.type === RESET_PUT_INCOME_UNIT) {
        return initialState;
    } else {
    return defaultAsyncReducer(state, action as AppAction, PUT_INCOME_UNIT_BY_ID)
    }
}

export default updateUnitReducer;