export type ProfileAttributes = {
    // common
    officeOccupiedArea: number | null,
    retailOccupiedArea: number | null,
    warehouseStorageOccupiedArea: number | null,
    manufactureOtherIndustrialUseOccupiedArea: number | null,
    officeVacantArea: number | null,
    retailVacantArea: number | null,
    warehouseStorageVacantArea: number | null,
    manufactureOtherIndustrialUseVacantArea: number | null,
    officeVacantRate: number | null,
    retailVacantRate: number | null,
    warehouseStorageVacantRate: number | null,
    manufactureOtherIndustrialUseVacantRate: number | null,
    dailyIndoorSpace: number | null,
    dailyIndoorSpaceRate: number | null,
    dailyOutdoorSpace: number | null,
    dailyOutdoorSpaceRate: number | null,
    monthlyIndoorSpace: number | null,
    monthlyIndoorSpaceRate: number | null,
    monthlyOutdoorSpace: number | null,
    monthlyOutdoorSpaceRate: number | null,
    reservedIndoorSpace: number | null,
    reservedIndoorSpaceRate: number | null,
    reservedOutdoorSpace: number | null,
    reservedOutdoorSpaceRate: number | null,
    residentialSurfaceParkingNotInRent: number | null,
    residentialSurfaceParkingMonthlyRate: number | null,
    residentialSurfaceParkingInRent: number | null,
    residentialIndoorParkingNotInRent: number | null,
    residentialIndoorParkingMonthlyRate: number | null,
    residentialIndoorParkingInRent: number | null,

    // BIP
    residentialHeatFlag: boolean | null,
    residentialHydroFlag: boolean | null,
    residentialWaterFlag: boolean | null,
    residentialCableFlag: boolean | null,
    residentialInternetFlag: boolean | null,
    residentialLaundryEnsuiteFlag: boolean | null,
    residentialLaundryCommonFlag: boolean | null,
    residentialStorageInternalFlag: boolean | null,
    residentialStorageExternalFlag: boolean | null,
    residentialAccessDoormanConciergeFlag: boolean | null,
    residentialAccessControlledFlag: boolean | null,
    residentialOnsiteSecurityFlag: boolean | null,
    residentialOnsiteStaffFlag: boolean | null,
    residentialPoolSaunaFitnessFlag: boolean | null,
    residentialAirConditioningFlag: boolean | null,
    residentialElevatorFlag: boolean | null,

    // GLF
    totalNumberOfRound: number | null,
    averagePrimeRate: number | null,
    courseName1: string | null,
    numberOfHole1: number | null,
    primeRate1: number | null,
    regularRound1: number | null,
    memberRound1: number | null,
    complimentaryRound1: number | null,
    courseName2: string | null,
    numberOfHole2: number | null,
    primeRate2: number | null,
    regularRound2: number | null,
    memberRound2: number | null,
    complimentaryRound2: number | null,
    courseName3: string | null,
    numberOfHole3: number | null,
    primeRate3: number | null,
    regularRound3: number | null,
    memberRound3: number | null,
    complimentaryRound3: number | null,
    courseName4: string | null,
    numberOfHole4: number | null,
    primeRate4: number | null,
    regularRound4: number | null,
    memberRound4: number | null,
    complimentaryRound4: number | null,
    courseName5: string | null,
    numberOfHole5: number | null,
    primeRate5: number | null,
    regularRound5: number | null,
    memberRound5: number | null,
    complimentaryRound5: number | null,

    // HTL
    numberOfRoom: number | null,
    roomAvailable: number | null,
    occupancyPercent: number | null,
    averageDailyRate: number | null,

    // LLE
    occupiedLot: number | null,
    vacantLot: number | null,
    transientLot: number | null,
    campgroundSite: number | null,
    totalSiteCount: number | null,
    landLeaseLot1: number | null,
    landLeaseRate1: number | null,
    landLeaseTerm1: number | null,
    landLeaseLot2: number | null,
    landLeaseRate2: number | null,
    landLeaseTerm2: number | null,
    landLeaseLot3: number | null,
    landLeaseRate3: number | null,
    landLeaseTerm3: number | null,
    landLeaseLot4: number | null,
    landLeaseRate4: number | null,
    landLeaseTerm4: number | null,
    landLeaseLot5: number | null,
    landLeaseRate5: number | null,
    landLeaseTerm5: number | null,
    landLeaseLot6: number | null,
    landLeaseRate6: number | null,
    landLeaseTerm6: number | null,
    landLeaseLot7: number | null,
    landLeaseRate7: number | null,
    landLeaseTerm7: number | null,
    landLeaseLot8: number | null,
    landLeaseRate8: number | null,
    landLeaseTerm8: number | null,
    landLeaseLot9: number | null,
    landLeaseRate9: number | null,
    landLeaseTerm9: number | null,
    landLeaseLot10: number | null,
    landLeaseRate10: number | null,
    landLeaseTerm10: number | null,

    // LTC
    additionalConstructionFundingBed: number | null,
    additionalConstructionFundingRate: number | null,
    additionalConstructionFundingTotal: number | null,
    aggregateSubsidyRate: number | null,
    aggregateSubsidyTotal: number | null,
    basicBedA: number | null,
    basicBedAa: number | null,
    basicBedAaa: number | null,
    basicBedB: number | null,
    basicBedC: number | null,
    basicBedD: number | null,
    basicBedDailyRate: number | null,
    basicBedTotalCount: number | null,
    basicBedTotalRevenue: number | null,
    cchsaRate: number | null,
    cchsaTotal: number | null,
    equalizationAdjustmentRate: number | null,
    equalizationAdjustmentTotal: number | null,
    municipalTaxAllowanceRate: number | null,
    municipalTaxAllowanceTotal: number | null,
    nursingPersonalCareCmi: number | null,
    nursingPersonalCareRate: number | null,
    nursingPersonalCareTotal: number | null,
    otherBedA: number | null,
    otherBedAa: number | null,
    otherBedAaa: number | null,
    otherBedB: number | null,
    otherBedC: number | null,
    otherBedD: number | null,
    otherBedDailyRate: number | null,
    otherBedTotalCount: number | null,
    otherBedTotalRevenue: number | null,
    otherFundingBed: number | null,
    otherFundingRate: number | null,
    otherIncomeBed: number | null,
    otherIncomeRate: number | null,
    preferredBedRevenue: number | null,
    programSupportServiceTotal: number | null,
    programSupportServicesRate: number | null,
    publicBedA: number | null,
    publicBedAa: number | null,
    publicBedAaa: number | null,
    publicBedB: number | null,
    publicBedC: number | null,
    publicBedD: number | null,
    publicBedDailyRate: number | null,
    publicBedTotalCount: number | null,
    publicBedTotalRevenue: number | null,
    rawFoodRate: number | null,
    rawFoodTotal: number | null,
    semipublicBedA: number | null,
    semipublicBedAa: number | null,
    semipublicBedAaa: number | null,
    semipublicBedB: number | null,
    semipublicBedC: number | null,
    semipublicBedD: number | null,
    semipublicBedDailyRate: number | null,
    semipublicBedTotalCount: number | null,
    semipublicBedTotalRevenue: number | null,
    structuralComplianceFundingBed: number | null,
    structuralComplianceFundingRate: number | null,
    structuralComplianceFundingTotal: number | null,
    subsidyOtherRate: number | null,
    subsidyOtherTotal: number | null,

    // RHO
    rentRollDate: number | null,
    studioSmallRoom: number | null,
    studioSmallBed: number | null,
    studioSmallRate: number | null,
    studioRegularRoom: number | null,
    studioRegularBed: number | null,
    studioRegularRate: number | null,
    studioLargeRoom: number | null,
    studioLargeBed: number | null,
    studioLargeRate: number | null,
    studioSemiRoom: number | null,
    studioSemiBed: number | null,
    studioSemiRate: number | null,
    singleSmallRoom: number | null,
    singleSmallBed: number | null,
    singleSmallRate: number | null,
    singleRegularRoom: number | null,
    singleRegularBed: number | null,
    singleRegularRate: number | null,
    singleLargeRoom: number | null,
    singleLargeBed: number | null,
    singleLargeRate: number | null,
    singleSemiRoom: number | null,
    singleSemiBed: number | null,
    singleSemiRate: number | null,
    doubleSmallRoom: number | null,
    doubleSmallBed: number | null,
    doubleSmallRate: number | null,
    doubleRegularRoom: number | null,
    doubleRegularBed: number | null,
    doubleRegularRate: number | null,
    doubleLargeRoom: number | null,
    doubleLargeBed: number | null,
    doubleLargeRate: number | null,
    doubleSemiRoom: number | null,
    doubleSemiBed: number | null,
    doubleSemiRate: number | null,
    wardRoom: number | null,
    wardBed: number | null,
    wardRate: number | null,
    otherRoom: number | null,
    otherBed: number | null,
    otherRate: number | null,
    totalRoom: number | null,
    totalBed: number | null,
    potentialResidentDay: number | null,
    actualResidentDay: number | null,
    reportedVacancy: number | null,
    secondOccupantResidentDay: number | null,
    respiteDay: number | null,
    independentLivingHeatFlag: boolean | null,
    independentLivingHydroFlag: boolean | null,
    independentLivingWaterFlag: boolean | null,
    independentLivingCableFlag: boolean | null,
    independentLivingInternetFlag: boolean | null,
    independentLivingFoodFlag: boolean | null,
    independentLivingHousekeepingFlag: boolean | null,
    independentLivingActivityFlag: boolean | null,
    independentLivingCareServiceFlag: boolean | null,
    independentLivingDrugFlag: boolean | null,
    independentLivingFeedingFlag: boolean | null,
    independentLivingBathingFlag: boolean | null,
    independentLivingContinenceFlag: boolean | null,
    independentLivingDressingFlag: boolean | null,
    independentLivingHygieneFlag: boolean | null,
    independentLivingAmbulationFlag: boolean | null,
    independentLivingMealFlag: boolean | null,
    independentLivingDementiaFlag: boolean | null,
    independentLivingSkinWoundFlag: boolean | null,
    independentLivingMedicalFlag: boolean | null,
    independentLivingNursingFlag: boolean | null,
    independentLivingPharmacyFlag: boolean | null,
    assistedLivingHeatFlag: boolean | null,
    assistedLivingHydroFlag: boolean | null,
    assistedLivingWaterFlag: boolean | null,
    assistedLivingCableFlag: boolean | null,
    assistedLivingInternetFlag: boolean | null,
    assistedLivingFoodFlag: boolean | null,
    assistedLivingHousekeepingFlag: boolean | null,
    assistedLivingActivityFlag: boolean | null,
    assistedLivingCareServiceFlag: boolean | null,
    assistedLivingDrugFlag: boolean | null,
    assistedLivingFeedingFlag: boolean | null,
    assistedLivingBathingFlag: boolean | null,
    assistedLivingContinenceFlag: boolean | null,
    assistedLivingDressingFlag: boolean | null,
    assistedLivingHygieneFlag: boolean | null,
    assistedLivingAmbulationFlag: boolean | null,
    assistedLivingMealFlag: boolean | null,
    assistedLivingDementiaFlag: boolean | null,
    assistedLivingSkinWoundFlag: boolean | null,
    assistedLivingMedicalFlag: boolean | null,
    assistedLivingNursingFlag: boolean | null,
    assistedLivingPharmacyFlag: boolean | null,
    memoryCareHeatFlag: boolean | null,
    memoryCareHydroFlag: boolean | null,
    memoryCareWaterFlag: boolean | null,
    memoryCareCableFlag: boolean | null,
    memoryCareInternetFlag: boolean | null,
    memoryCareFoodFlag: boolean | null,
    memoryCareHousekeepingFlag: boolean | null,
    memoryCareActivityFlag: boolean | null,
    memoryCareCareServiceFlag: boolean | null,
    memoryCareDrugFlag: boolean | null,
    memoryCareFeedingFlag: boolean | null,
    memoryCareBathingFlag: boolean | null,
    memoryCareContinenceFlag: boolean | null,
    memoryCareDressingFlag: boolean | null,
    memoryCareHygieneFlag: boolean | null,
    memoryCareAmbulationFlag: boolean | null,
    memoryCareMealFlag: boolean | null,
    memoryCareDementiaFlag: boolean | null,
    memoryCareSkinWoundFlag: boolean | null,
    memoryCareMedicalFlag: boolean | null,
    memoryCareNursingFlag: boolean | null,
    memoryCarePharmacyFlag: boolean | null,
}

export const initialProfileAttributes: ProfileAttributes = {
    // common
    officeOccupiedArea: null,
    retailOccupiedArea: null,
    warehouseStorageOccupiedArea: null,
    manufactureOtherIndustrialUseOccupiedArea: null,
    officeVacantArea: null,
    retailVacantArea: null,
    warehouseStorageVacantArea: null,
    manufactureOtherIndustrialUseVacantArea: null,
    officeVacantRate: null,
    retailVacantRate: null,
    warehouseStorageVacantRate: null,
    manufactureOtherIndustrialUseVacantRate: null,
    dailyIndoorSpace: null,
    dailyIndoorSpaceRate: null,
    dailyOutdoorSpace: null,
    dailyOutdoorSpaceRate: null,
    monthlyIndoorSpace: null,
    monthlyIndoorSpaceRate: null,
    monthlyOutdoorSpace: null,
    monthlyOutdoorSpaceRate: null,
    reservedIndoorSpace: null,
    reservedIndoorSpaceRate: null,
    reservedOutdoorSpace: null,
    reservedOutdoorSpaceRate: null,
    residentialSurfaceParkingNotInRent: null,
    residentialSurfaceParkingMonthlyRate: null,
    residentialSurfaceParkingInRent: null,
    residentialIndoorParkingNotInRent: null,
    residentialIndoorParkingMonthlyRate: null,
    residentialIndoorParkingInRent: null,

    // BIP
    residentialHeatFlag: null,
    residentialHydroFlag: null,
    residentialWaterFlag: null,
    residentialCableFlag: null,
    residentialInternetFlag: null,
    residentialLaundryEnsuiteFlag: null,
    residentialLaundryCommonFlag: null,
    residentialStorageInternalFlag: null,
    residentialStorageExternalFlag: null,
    residentialAccessDoormanConciergeFlag: null,
    residentialAccessControlledFlag: null,
    residentialOnsiteSecurityFlag: null,
    residentialOnsiteStaffFlag: null,
    residentialPoolSaunaFitnessFlag: null,
    residentialAirConditioningFlag: null,
    residentialElevatorFlag: null,

    // GLF
    totalNumberOfRound: null,
    averagePrimeRate: null,
    courseName1: null,
    numberOfHole1: null,
    primeRate1: null,
    regularRound1: null,
    memberRound1: null,
    complimentaryRound1: null,
    courseName2: null,
    numberOfHole2: null,
    primeRate2: null,
    regularRound2: null,
    memberRound2: null,
    complimentaryRound2: null,
    courseName3: null,
    numberOfHole3: null,
    primeRate3: null,
    regularRound3: null,
    memberRound3: null,
    complimentaryRound3: null,
    courseName4: null,
    numberOfHole4: null,
    primeRate4: null,
    regularRound4: null,
    memberRound4: null,
    complimentaryRound4: null,
    courseName5: null,
    numberOfHole5: null,
    primeRate5: null,
    regularRound5: null,
    memberRound5: null,
    complimentaryRound5: null,

    // HTL
    numberOfRoom: null,
    roomAvailable: null,
    occupancyPercent: null,
    averageDailyRate: null,

    // LLE
    occupiedLot: null,
    vacantLot: null,
    transientLot: null,
    campgroundSite: null,
    totalSiteCount: null,
    landLeaseLot1: null,
    landLeaseRate1: null,
    landLeaseTerm1: null,
    landLeaseLot2: null,
    landLeaseRate2: null,
    landLeaseTerm2: null,
    landLeaseLot3: null,
    landLeaseRate3: null,
    landLeaseTerm3: null,
    landLeaseLot4: null,
    landLeaseRate4: null,
    landLeaseTerm4: null,
    landLeaseLot5: null,
    landLeaseRate5: null,
    landLeaseTerm5: null,
    landLeaseLot6: null,
    landLeaseRate6: null,
    landLeaseTerm6: null,
    landLeaseLot7: null,
    landLeaseRate7: null,
    landLeaseTerm7: null,
    landLeaseLot8: null,
    landLeaseRate8: null,
    landLeaseTerm8: null,
    landLeaseLot9: null,
    landLeaseRate9: null,
    landLeaseTerm9: null,
    landLeaseLot10: null,
    landLeaseRate10: null,
    landLeaseTerm10: null,

    // LTC
    additionalConstructionFundingBed: null,
    additionalConstructionFundingRate: null,
    additionalConstructionFundingTotal: null,
    aggregateSubsidyRate: null,
    aggregateSubsidyTotal: null,
    basicBedA: null,
    basicBedAa: null,
    basicBedAaa: null,
    basicBedB: null,
    basicBedC: null,
    basicBedD: null,
    basicBedDailyRate: null,
    basicBedTotalCount: null,
    basicBedTotalRevenue: null,
    cchsaRate: null,
    cchsaTotal: null,
    equalizationAdjustmentRate: null,
    equalizationAdjustmentTotal: null,
    municipalTaxAllowanceRate: null,
    municipalTaxAllowanceTotal: null,
    nursingPersonalCareCmi: null,
    nursingPersonalCareRate: null,
    nursingPersonalCareTotal: null,
    otherBedA: null,
    otherBedAa: null,
    otherBedAaa: null,
    otherBedB: null,
    otherBedC: null,
    otherBedD: null,
    otherBedDailyRate: null,
    otherBedTotalCount: null,
    otherBedTotalRevenue: null,
    otherFundingBed: null,
    otherFundingRate: null,
    otherIncomeBed: null,
    otherIncomeRate: null,
    preferredBedRevenue: null,
    programSupportServiceTotal: null,
    programSupportServicesRate: null,
    publicBedA: null,
    publicBedAa: null,
    publicBedAaa: null,
    publicBedB: null,
    publicBedC: null,
    publicBedD: null,
    publicBedDailyRate: null,
    publicBedTotalCount: null,
    publicBedTotalRevenue: null,
    rawFoodRate: null,
    rawFoodTotal: null,
    semipublicBedA: null,
    semipublicBedAa: null,
    semipublicBedAaa: null,
    semipublicBedB: null,
    semipublicBedC: null,
    semipublicBedD: null,
    semipublicBedDailyRate: null,
    semipublicBedTotalCount: null,
    semipublicBedTotalRevenue: null,
    structuralComplianceFundingBed: null,
    structuralComplianceFundingRate: null,
    structuralComplianceFundingTotal: null,
    subsidyOtherRate: null,
    subsidyOtherTotal: null,

    // RHO
    rentRollDate: null,
    studioSmallRoom: null,
    studioSmallBed: null,
    studioSmallRate: null,
    studioRegularRoom: null,
    studioRegularBed: null,
    studioRegularRate: null,
    studioLargeRoom: null,
    studioLargeBed: null,
    studioLargeRate: null,
    studioSemiRoom: null,
    studioSemiBed: null,
    studioSemiRate: null,
    singleSmallRoom: null,
    singleSmallBed: null,
    singleSmallRate: null,
    singleRegularRoom: null,
    singleRegularBed: null,
    singleRegularRate: null,
    singleLargeRoom: null,
    singleLargeBed: null,
    singleLargeRate: null,
    singleSemiRoom: null,
    singleSemiBed: null,
    singleSemiRate: null,
    doubleSmallRoom: null,
    doubleSmallBed: null,
    doubleSmallRate: null,
    doubleRegularRoom: null,
    doubleRegularBed: null,
    doubleRegularRate: null,
    doubleLargeRoom: null,
    doubleLargeBed: null,
    doubleLargeRate: null,
    doubleSemiRoom: null,
    doubleSemiBed: null,
    doubleSemiRate: null,
    wardRoom: null,
    wardBed: null,
    wardRate: null,
    otherRoom: null,
    otherBed: null,
    otherRate: null,
    totalRoom: null,
    totalBed: null,
    potentialResidentDay: null,
    actualResidentDay: null,
    reportedVacancy: null,
    secondOccupantResidentDay: null,
    respiteDay: null,
    independentLivingHeatFlag: null,
    independentLivingHydroFlag: null,
    independentLivingWaterFlag: null,
    independentLivingCableFlag: null,
    independentLivingInternetFlag: null,
    independentLivingFoodFlag: null,
    independentLivingHousekeepingFlag: null,
    independentLivingActivityFlag: null,
    independentLivingCareServiceFlag: null,
    independentLivingDrugFlag: null,
    independentLivingFeedingFlag: null,
    independentLivingBathingFlag: null,
    independentLivingContinenceFlag: null,
    independentLivingDressingFlag: null,
    independentLivingHygieneFlag: null,
    independentLivingAmbulationFlag: null,
    independentLivingMealFlag: null,
    independentLivingDementiaFlag: null,
    independentLivingSkinWoundFlag: null,
    independentLivingMedicalFlag: null,
    independentLivingNursingFlag: null,
    independentLivingPharmacyFlag: null,
    assistedLivingHeatFlag: null,
    assistedLivingHydroFlag: null,
    assistedLivingWaterFlag: null,
    assistedLivingCableFlag: null,
    assistedLivingInternetFlag: null,
    assistedLivingFoodFlag: null,
    assistedLivingHousekeepingFlag: null,
    assistedLivingActivityFlag: null,
    assistedLivingCareServiceFlag: null,
    assistedLivingDrugFlag: null,
    assistedLivingFeedingFlag: null,
    assistedLivingBathingFlag: null,
    assistedLivingContinenceFlag: null,
    assistedLivingDressingFlag: null,
    assistedLivingHygieneFlag: null,
    assistedLivingAmbulationFlag: null,
    assistedLivingMealFlag: null,
    assistedLivingDementiaFlag: null,
    assistedLivingSkinWoundFlag: null,
    assistedLivingMedicalFlag: null,
    assistedLivingNursingFlag: null,
    assistedLivingPharmacyFlag: null,
    memoryCareHeatFlag: null,
    memoryCareHydroFlag: null,
    memoryCareWaterFlag: null,
    memoryCareCableFlag: null,
    memoryCareInternetFlag: null,
    memoryCareFoodFlag: null,
    memoryCareHousekeepingFlag: null,
    memoryCareActivityFlag: null,
    memoryCareCareServiceFlag: null,
    memoryCareDrugFlag: null,
    memoryCareFeedingFlag: null,
    memoryCareBathingFlag: null,
    memoryCareContinenceFlag: null,
    memoryCareDressingFlag: null,
    memoryCareHygieneFlag: null,
    memoryCareAmbulationFlag: null,
    memoryCareMealFlag: null,
    memoryCareDementiaFlag: null,
    memoryCareSkinWoundFlag: null,
    memoryCareMedicalFlag: null,
    memoryCareNursingFlag: null,
    memoryCarePharmacyFlag: null,
}

