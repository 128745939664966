import { combineReducers } from "redux";
import getValidTokenReducer from "./validateTokenReducer";
import getLogoutReducer from "./logoutReducer";
import getValidInternalTokenReducer from "./validateInternalTokenReducer";

const authReducer = combineReducers({
    validateToken: getValidTokenReducer,
    validateInternalToken: getValidInternalTokenReducer,
    logout: getLogoutReducer
});

export default authReducer;