import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OperationFieldProps } from '../../pages/BusinessOperations/BusinessOperations';
import { useReadOnly } from '../../utils/ReadOnlyContext';
import "./GEV.css";

interface GEVPercentageOfThroughputProps extends OperationFieldProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export default function GEVPercentageOfThroughput(props: GEVPercentageOfThroughputProps): JSX.Element {
    const { t } = useTranslation('gev')
    const { isReadOnly } = useReadOnly()
    const rowKeys = ["campaignYearOne", "campaignYearTwo", "campaignYearThree"]
    const colKeys = ["yearValue", "owner", "nonOwner", "totalThroughput"]
    const [potData, setPotData] = useState(props.data["percentageOfThroughput"] as Record<string, Record<string, number | null>>)

    useEffect(() => {
        setPotData(props.data["percentageOfThroughput"] as Record<string, Record<string, number | null>>)
    }, [props.data, props.name])

    return (
        <>
            <tr className='gev-input-tr'>
                <td>
                    <div className='gev-input-label'>{t(props.operation + "." + props.name + ".label")}</div>
                    <table width={"100%"} className='percentageOfThroughput' role="none">
                        <tbody>
                            <tr>
                                {
                                    colKeys.map((colKey, index) => {
                                        if (index === 0) {
                                            return <td key={colKey} width={"10%"}>{t(props.operation + "." + props.name + "." + colKey)}</td>
                                        } else if (index === 1) {
                                            return <td key={colKey} width={"50%"}>{t(props.operation + "." + props.name + "." + colKey)}</td>
                                        } else {
                                            return <td key={colKey} width={"20%"}>{t(props.operation + "." + props.name + "." + colKey)}</td>
                                        }
                                    })
                                }
                            </tr>
                            {
                                rowKeys.map((rowKey) => {
                                    return (
                                        <tr key={rowKey}>
                                            {
                                                colKeys.map((colKey, colIndex) => {
                                                    if (colIndex === 0) {
                                                        return <td key={colKey}>{potData[rowKey][colKey]}</td>
                                                    } else if (colIndex === 1) {
                                                        return <td key={colKey}>
                                                            <input type="number"
                                                                id={rowKey}
                                                                name={rowKey}
                                                                value={potData[rowKey][colKey] === null ? "" : potData[rowKey][colKey].toString()}
                                                                aria-label={t(props.operation + "." + props.name)}
                                                                autoComplete="off"
                                                                disabled={isReadOnly}
                                                                title={t(props.operation + "." + props.name + "." + colKey)}
                                                                onChange={(e) => props.onChange(e)}
                                                                onKeyDown={(e) => {
                                                                    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                                                                }}
                                                                onWheel={(e) => {
                                                                    if (props.type === "number") {
                                                                        e.currentTarget.blur()
                                                                    }
                                                                }}
                                                            /> %
                                                        </td>
                                                    }
                                                    else {
                                                        return <td key={colKey}>{potData[rowKey][colKey]} %</td>
                                                    }
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </td>
            </tr>
        </>
    )
}
