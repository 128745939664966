import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/reducers/root';
import MenuState from '../../types/menu/MenuState';
import MenuItem from '../../types/menu/MenuItem';
import SidebarItemLarge from '../../components/common/SidebarItemLarge/SidebarItemLarge';
import './Sidebar.css'
import { setNextActiveMenuItem } from '../../redux/actions/menu/menu';

interface SidebarProps {
    items: MenuItem[];
    collapsed: boolean;
    handleCollapse: () => void;
    year: string;
}

export interface SidebarItemProps {
    item: MenuItem;
    activeItemId: number;
    isChildActive?: (item: MenuItem, activeItemId: number) => boolean;
    index?: number;
    menuOnClick: (item: MenuItem) => void;
}

function Sidebar(props: SidebarProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
    const isInternalUser = localStorage.getItem("isInternalUser");

    const menuOnClick = (menuItem: MenuItem) => {
        dispatch(setNextActiveMenuItem(menuItem))
    }

    return (
        <div className='sidebar-raw-root'>
            <div className={`sidebar-${props.collapsed ? 'collapsed' : 'open'} sidebar`}>
                <div className='sidebar-main-section'>

                    {props.collapsed || (
                        <h1 className='sidebar-year' onClick={() => isInternalUser ?
                            navigate('/internal-landing') : navigate('/landing')}>
                            {isInternalUser && "Selected year : "} 
                            {localStorage.getItem("activeCampaignYear")}
                        </h1>
                    )}

                    <div className='sidebar-item-container'>
                        {props.items && props.items.map(item => (
                            <SidebarItemLarge
                                key={item.id}
                                item={item}
                                activeItemId={menuState.activeItem.id}
                                menuOnClick={menuOnClick}
                            />
                        ))
                        }
                    </div>
                </div>


                {props.collapsed || (
                    <div className='sidebar-footer-container'>

                        <div className='sidebar-footer-info-container'>
                            <div className='sidebar-logo-image-container'>
                                <img src="/images/MPAC_logo_no_bg.png" alt="MPAC Logo" />
                            </div>
                            <div className='sidebar-mpac-info'>
                                <div className='sidebar-mpac-info-text'>
                                    <div>Copyright 2025 <br /> MPAC. Version 1.0</div>
                                    <a href='https://www.mpac.ca/en/AboutUs/Policies/PrivacyStatement' target='#'>Privacy Policy</a>
                                    <a href='https://www.mpac.ca/en/Contact' target='#'>Contact us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


            </div>
        </div>

    )
}

export default Sidebar