import { faFaceFrownOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function NotFound(): JSX.Element {
  return (
    <div>
      <FontAwesomeIcon icon={faFaceFrownOpen} />
      <p>Uh oh...page does not exist</p>
    </div>
  );
}