import { API_REQUEST, GET_PROPERTIES } from "../../actionTypes/actionsTypes";
import { AppAction } from "../../types";

// export const getProperties = (): LocalAction => ({
//     type: GET_PROPERTIES
// });

export const getTestRolls = (): AppAction => ({
    type: API_REQUEST,
    payload: {
        url: `/income/get-test-rolls`,
        next: GET_PROPERTIES,
        method: 'GET',
        authorization: true
    }
})