/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 */
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updateCampaignStatus } from '../../../redux/actions/campaign/updateStatus'
import { getElsLookups } from '../../../redux/actions/lookups/lookups'
import { getProfileOverview } from '../../../redux/actions/profile/profile'
import { setReadOnly } from '../../../redux/actions/readOnly/readOnly'
import { EXTERNAL_IN_PROGRESS, RESET_PROPERTIES, RESET_UPDATE_CAMPAIGN_STATUS } from '../../../redux/actionTypes/actionsTypes'
import { RootState } from '../../../redux/reducers/root'
import { UpdateCampaignState } from '../../../redux/reducers/getCampaign/updateCampaignStatusReducer'
import { GetElsLookupsState } from '../../../redux/reducers/getLookups/getElsLookupsReducer'
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer'
import Button from '../../../components/common/Button/Button'
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner'
import LandingBanner from '../../../components/LandingBanner/LandingBanner'
import Header from '../../../layouts/Header/Header'
import Footer from '../../../layouts/Footer/Footer'
import CampaignList from '../../../components/CampaignList/CampaignList'
import "./Start.css"

export default function Start(): JSX.Element {
    const { t } = useTranslation('transition')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const profileId = localStorage.getItem('profileId') as string
    const [isReturnTypeSelected, setIsReturnTypeSelected] = useState(false)
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups)
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
    const updateCampaignStatusState: UpdateCampaignState = useSelector((state: RootState) => state.campaignReducer.updateCampaignStatus)
    const getProfileOverviewDispatched = useRef(false)
    const [hasPreviousCampaign, setHasPreviousCampaign] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        if (!getProfileOverviewDispatched.current &&
            !(profileOverview?.profile?.profile_id === Number(profileId))
        ) {
            dispatch(getProfileOverview(profileId))
            getProfileOverviewDispatched.current = true
        }
    }, [dispatch, profileId, profileOverview])

    useEffect(() => {
        const calcHasPreviousCampaign = () => {
            if (profileOverview && profileOverview.profile
                && profileOverview.profile.profile_id === Number(profileId)) {
                if (profileOverview.profile.profile_type_code === "GEV") {
                    return false
                }
                const previous_campaigns = profileOverview.profile.previous_campaigns
                if (previous_campaigns && previous_campaigns.length > 0) {
                    const valid_previous_campaigns = previous_campaigns.filter((c) => c.currentSnapshotId)
                    if (valid_previous_campaigns && valid_previous_campaigns.length > 0) {
                        const sorted = valid_previous_campaigns.sort((a, b) => b.year - a.year)
                        const latest_campaign = sorted[0]
                        if (profileOverview.profile.current_campaign
                            && latest_campaign.year > profileOverview.profile.current_campaign.year - 5) {
                            return true
                        }
                    }
                }
            }
            return false
        }

        if (profileOverview.status && profileOverview.profile.profile_id === Number(profileId)) {
            if (getProfileOverviewDispatched.current) {
                getProfileOverviewDispatched.current = false
            }
            if (profileOverview.status === 200) {
                if (updateCampaignStatusState.updated) {
                    dispatch({ type: RESET_UPDATE_CAMPAIGN_STATUS })
                    navigate('/portal')
                } else {
                    setHasPreviousCampaign(calcHasPreviousCampaign())
                }
            } else {
                if (profileOverview.message) {
                    toast.error(profileOverview.message)
                }
            }
        }
    }, [navigate, profileId, profileOverview, updateCampaignStatusState.updated, dispatch])

    const showImportDataModal = (e: React.MouseEvent) => {
        e.preventDefault()
        localStorage.setItem("incomeReturnType", "import")
        dispatch(setReadOnly(false))
        setIsReturnTypeSelected(true)
        fetchLookups()
        dispatch(updateCampaignStatus(EXTERNAL_IN_PROGRESS,
            profileId,
            String(profileOverview.profile.current_campaign.campaignId),
            "true")) // Set isCopySnapshot to true only when import is selected
    }

    const startNewReturn = (e: React.MouseEvent) => {
        e.preventDefault()
        localStorage.setItem("incomeReturnType", "new")
        dispatch(setReadOnly(false))
        setIsReturnTypeSelected(true)
        fetchLookups()
        dispatch(updateCampaignStatus(EXTERNAL_IN_PROGRESS,
            profileId,
            String(profileOverview.profile.current_campaign.campaignId),
            "false")) // Set isCopySnapshot to false if new is selected
    }

    const fetchLookups = () => {
        // Get lookups if they are not found in localstorage
        if (!elsLookupsState.success) {
            dispatch(getElsLookups())
        }
    }

    useEffect(() => {
        if (isReturnTypeSelected && elsLookupsState.success) {
            if (updateCampaignStatusState.updated) {
                // Save current property selection to localstorage for easy access to ids
                localStorage.setItem('rollNumber', localStorage.getItem('rollNumber') as string)
                localStorage.setItem('profileId', String(updateCampaignStatusState.campaign['profileId']))
                localStorage.setItem('activeCampaignYear', String(updateCampaignStatusState.campaign['campaignYear']))
                localStorage.setItem('campaignId', String(updateCampaignStatusState.campaign['campaignId']))
                localStorage.setItem('currentSnapshotId', String(updateCampaignStatusState.campaign['currentSnapshotId']))
                dispatch({ type: RESET_PROPERTIES })
                dispatch(getProfileOverview(String(updateCampaignStatusState.campaign['profileId'])))
                getProfileOverviewDispatched.current = true
            } else {
                if (updateCampaignStatusState.message) {
                    toast.error(updateCampaignStatusState.message)
                }
            }
        }
    }, [dispatch, elsLookupsState.success, isReturnTypeSelected, updateCampaignStatusState])

    return (<>
        <Header showAutoSave={false} isInternalUser={false} />
        <LandingBanner />
        <LoadingSpinner loading={updateCampaignStatusState.loading}>
            {
                (profileOverview?.profile?.profile_id === Number(profileId) && hasPreviousCampaign !== undefined) &&
                <>
                    <div className='start-body-container'>
                        <div className='start-body'>
                            <div className='start-heading-section'>
                                <h1 className='start-heading'>{t("start.heading")}</h1>
                                <div className='import-block'>
                                    {
                                        profileOverview.profile.profile_type_code !== "GEV" &&
                                        <p>
                                            {hasPreviousCampaign ? t('start.import-question') : t('start.no-import-question')}
                                        </p>
                                    }

                                    <div className="transition-btn-group">
                                        <div className="button-group justify-content-center">
                                            {
                                                hasPreviousCampaign ?
                                                    <>
                                                        <Button onClick={e => showImportDataModal(e)} type="submit" text={t("start.yes")} />
                                                        <Button onClick={e => startNewReturn(e)} type="secondary" text={t("start.no")} />
                                                    </>
                                                    :
                                                    <Button onClick={e => startNewReturn(e)} type="secondary" text={t("start.no-previous-campaigns")} />

                                            }
                                        </div>
                                        <Button onClick={() => navigate("/landing")} type="secondary" text={t("return")}
                                            className='start-page-return-btn' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="campaign-list-wrapper">
                            {hasPreviousCampaign &&
                                <div className="campaign-list">
                                    <CampaignList />
                                </div>
                            }
                        </div>
                    </div>
                </>
            }
        </LoadingSpinner>
        <div className="start-footer">
            <Footer />
        </div>
    </>)
}
