import React, { ReactElement, useEffect, useState } from "react";
import { LookupsState } from "../../../redux/reducers/getLookups/lookupsReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers/root";
import { ProfileOverviewState } from "../../../redux/reducers/getProfile/getProfileOverviewReducer";
import { useTranslation } from "react-i18next";
import { getLookups } from "../../../redux/actions/lookups/lookups";
import { BaseAsyncReducerState } from "../../../redux/types";
import IncomeAttributeTableFragmentGenerator from "../../../utils/TableGenerator/IncomeAttributeTableFragmentGenerator";
import { getIncomeAttributesBySnapshotId, putIncomeAttributesBySnapshotId } from "../../../redux/actions/incomeAttributes/incomeAttributes";
import "../PIE.css";
import CurrencyInput from "../../../components/common/CurrencyInput/CurrencyInput";
import ToolTip from "../../../components/common/ToolTip/ToolTip";
import { SaveDataState } from "../../../redux/reducers/getSaveData/saveDataReducer";
import { SaveResultState } from "../../../redux/reducers/getSaveData/saveResultReducer";

function ReserveForReplacementAllowance(): ReactElement {
    const { i18n } = useTranslation();
    const { t } = useTranslation(['pie']);
    const dispatch = useDispatch();

    const incomeAttributesState: BaseAsyncReducerState = useSelector((state: RootState) => state.incomeAttributesReducer.incomeAttributes);
    const profileOverview: ProfileOverviewState = useSelector((currentState: RootState) => (currentState.profileReducer.getProfileOverview))
    const lookupState: LookupsState = useSelector((currentState: RootState) => (currentState.lookupsReducer.getLookups as LookupsState));
    const lookupType = profileOverview.profile.profile_type_code.toLowerCase() + "-income-attributes";
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
    const [totalExpense, setTotalExpense] = useState<number | undefined>(undefined);
  
    useEffect(() => {
      if (!incomeAttributesState.loading && !incomeAttributesState.success) {
        dispatch(getIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string)));
      }
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (!lookupState.success) {
          dispatch(getLookups(lookupType))
      }
    }, [dispatch, lookupState.success, lookupType]);

    const findAttributeData = (attributeCode: string) => {
      let incomeAttributesData: Record<string, unknown> | undefined = undefined;
      if (incomeAttributesState.data) {
          incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attributeCode);
      }
      return incomeAttributesData;
  };

    useEffect(() => {
        const initTotalExpense = () => {
          let calculated = false;
          let total = 0;
    
          const keys = Object.keys(lookupState.lookups[4]);
          keys.forEach((key) => {
            const lookupAttributes = lookupState.lookups[4][key];
            lookupAttributes.forEach((attribute) => {
              if (attribute.activeFlag === "Y") {
                const code = attribute.incomeAttributeCode;
                const incomeAttributesData = findAttributeData(code);
                if (incomeAttributesData && incomeAttributesData.value && incomeAttributesData.value !== 0) {
                  total = total + (incomeAttributesData.value as number);
                  calculated = true;
                }
              }
            });
          });
          if (calculated) {
            setTotalExpense(total);
          }
        }
    
        if (lookupState.success && incomeAttributesState.success) {
          setTimeout(() => {
            if (totalExpense === undefined) {
              initTotalExpense();
            }
          }, 50);
        }
        // eslint-disable-next-line
      }, [incomeAttributesState.data, incomeAttributesState.success, lookupState.lookups, lookupState.success, lookupType, totalExpense]);
    
    const handleCommentChange = (value: string, name: string) => {
        const idx = name.lastIndexOf("-");
        if (idx !== -1) {
            const code = name.substring(idx + 1);
            const incomeAttributesData = findAttributeData(code);
            const v = value.trim() === "" ? null : value.trim();
            if (incomeAttributesData) {
                incomeAttributesData["description"] = v;
            } else {
                (incomeAttributesState.data as Record<string, unknown>[]).push({
                    snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
                    code: code,
                    description: v,
                    value: null,
                    percent: null,
                    year: null
                });
            }
        }
    }

    const handleTotalReserve = (newAmt: number | undefined | null, name: string, oldAmt: number | undefined | null) => {
        let calculated = false;
        let value = 0;
        const idx = name.lastIndexOf("-");
        if (idx !== -1) {
          const code = name.substring(idx + 1);
          const incomeAttributesData = findAttributeData(code);
          const na = newAmt === undefined ? null : newAmt;
          if (incomeAttributesData) {
            incomeAttributesData["value"] = na;
          } else {
            (incomeAttributesState.data as Record<string, unknown>[]).push({
              snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
              code: code,
              description: null,
              value: na,
              percent: null,
              year: null,
            });
          }
        }
    
        if (typeof totalExpense === "number") {
          value = totalExpense;
        }
        if (typeof newAmt === "number") {
          value = value + newAmt;
          calculated = true;
        }
        if (typeof oldAmt === "number") {
          value = value - oldAmt;
          calculated = true;
        }
        if (calculated) {
          setTotalExpense(value);
        }
      }

    const formatCurrency = (value: number | undefined) => {
        if (typeof value === "number") {
            const formattedValue = value.toLocaleString(i18n.language, {
            style: 'currency',
            currency: "CAD",
            currencyDisplay: "code",
            }).replaceAll("CAD", '').trim();
            return formattedValue;
        }
        return value;
        }
    
    useEffect(() => {  
      if (saveData.saving) {
        const incomeAttributes = (incomeAttributesState.data as Record<string, unknown>[]).filter((data) => data.value || data.descriptiion);
        dispatch(putIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string), incomeAttributes));
      }
    }, [dispatch, incomeAttributesState.data, saveData.saving]);

    useEffect(() => {
      if (saveResultState.status === 200) {
        dispatch(getIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string)));
      }
    }, [saveResultState, dispatch]);

    return (
        <>
        {(!incomeAttributesState.loading && !lookupState.loading) && 
        <>
        <table className="table-with-border pie-table capital-expenditures-table" width="70%">
            <thead>
                <tr><th></th>
                <th>Amount</th></tr>
            </thead>
            <tbody>
              <tr>
                <td className="padding-left-10 padding-right-10">
                  <ToolTip placement={"bottom-start"} contents={"Reserve For Replacement Allowance"}>
                    {t("tooltip.HTL.50")}
                  </ToolTip>
                </td>
                <CurrencyInput name={"reserve-for-replacement-allowance"}
                />
              </tr>
              <th colSpan={2}>
              </th>
              <tr>
                <th>
                  <ToolTip contents={("Replacement spending on short lived items:")}>
                    {t("tooltip.HTL.50")}
                  </ToolTip>
                </th>
                <th>
                  Amount
                </th>
              </tr>
              {lookupState.lookups[4]["reserve"] && (lookupState.lookups[4]["reserve"].map((attr, key) => {
                let incomeAttributesData: Record<string, unknown> | undefined = undefined;
                if (incomeAttributesState.data && (incomeAttributesState.data as Record<string, unknown>[]).length > 0) {
                  incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attr.incomeAttributeCode);
                }
                return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                  data={incomeAttributesData} hasInputLabel={false} valueChangeHandler={handleTotalReserve}
                  handleCommentChange={handleCommentChange} isRequiredField={false} isCapitalCostsOrReplacementReserve={true}
                  showSubheading={false} isOtherLabel={false} />
              }))}
            </tbody>
        </table>
        </>
        }
        <div className='tab-container-footer'>
              <div className="total-income">
                <p>{t('reserve.Total')} </p>
                <p>$ {formatCurrency(totalExpense)}</p>
              </div>
        </div>
    </>)
}

export default ReserveForReplacementAllowance;