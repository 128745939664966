import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OperationFieldProps } from '../../pages/BusinessOperations/BusinessOperations';
import Checkbox from '../common/Checkbox/Checkbox';
import "./GEV.css";

export default function GEVYesNo(props: OperationFieldProps): JSX.Element {
    const { t } = useTranslation('gev')
    const [data, setData] = useState(props.data[props.name] as boolean | null)

    useEffect(() => {
        setData(props.data[props.name] as boolean | null)
    }, [props.data, props.name])

    return (
        <>
            <tr className='gev-input-tr'>
                <td>
                    <div className='gev-input-label'>{t(props.operation + "." + props.name)}</div>
                    <fieldset className='noborder-fieldset'>
                        <legend className='invisible-legend'><span>{t(props.operation + "." + props.name)}</span></legend>
                        <div className='gev-yesno'>
                            {t("yes")}&nbsp;&nbsp;<Checkbox name={props.operation + "." + props.name} value="true"
                                checked={data === true}
                                onChange={() => {
                                    //
                                }}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {t("no")}&nbsp;&nbsp;<Checkbox name={props.operation + "." + props.name} value="false"
                                checked={data === false}
                                onChange={() => {
                                    //
                                }}
                            />
                        </div>
                    </fieldset>
                    <div className="input-error-message">
                        {props.errors && props.errors[props.name] as string}
                    </div>
                </td>
            </tr>
        </>
    )
}
