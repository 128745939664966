import { INCREMENT_LOADING, DECREMENT_LOADING } from "../../actionTypes/actionsTypes";
import { LocalAction } from "../../types";

export interface LoadingState {
  loading: number;
}

const initialState: LoadingState = {
  loading: 0
}

function getLoadingReducer(state = initialState, action: LocalAction) {
  switch (action.type) {
    case INCREMENT_LOADING:
      return {
        loading: state.loading + 1
      }
    case DECREMENT_LOADING:
      return {
        loading: Math.max(0, state.loading - 1)
      }
    default:
      return state;
  }
}

export default getLoadingReducer;