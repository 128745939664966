import React, { ReactElement } from "react";
import { Route } from "react-router-dom";
import Portal from "../layouts/Portal/Portal";
import Landing from "../pages/Landing/Landing";
import Start from "../pages/Transition/Start/Start";
import Continue from "../pages/Transition/Continue/Continue";
import Overview from "../pages/Transition/Overview/Overview";
import HelpCentre from "../pages/HelpCentre/HelpCentre";
import NotFound from "../pages/NotFound";
import InternalLanding from "../pages/InternalLanding/InternalLanding";

interface RouteTemplate {
    path: string;
    component: ReactElement;
    subroutes: Array<RouteTemplate> | null;
}


const authRoutes: Array<RouteTemplate> = [
    {
        path: "/help",
        component: <HelpCentre />,
        subroutes: null
    },
    {
        path: "/landing",
        component: <Landing />,
        subroutes: null
    },
    {
        path: "/internal-landing",
        component: <InternalLanding />,
        subroutes: null
    },
    {
        path: ":year/:roll/:profileID/:profileType/start",
        component: <Start />,
        subroutes: null
    },
    {
        path: ":year/:roll/:profileID/:profileType/continue",
        component: <Continue />,
        subroutes: null
    },
    {
        path: ":year/:roll/:profileID/:profileType/overview",
        component: <Overview />,
        subroutes: null
    },
    {
        path: "/portal",
        component: <Portal />,
        subroutes: null
    },
];

// Routes for a non authenticated User
// eslint-disable-next-line
const nonAuthRoutes: Array<RouteTemplate> = [
    {
        path: "/internal/:token/:roll",
        component: <></>,
        subroutes: []
    },
    {
        path: "/404",
        component: <NotFound />,
        subroutes: [],
    },
];

export function generateRoutes(isAuthed: boolean, sessionExpired: boolean): Array<JSX.Element> {
    const routes = (isAuthed && !sessionExpired) ? authRoutes : nonAuthRoutes;
    return mapRoutes(routes);
}

function mapRoutes(routes: Array<RouteTemplate>) {
    return routes.map((route: RouteTemplate, index: number) => {
        let children;
        if (route.subroutes != null) {
            children = mapRoutes(route.subroutes);
        }
        return (
            <Route key={index} path={route.path} element={route.component}>
                {children}
            </Route>
        );
    });
}
