import { faBuilding, faCartShopping, faFileInvoiceDollar, faFilePdf, faMoneyBillTrendUp } from "@fortawesome/free-solid-svg-icons";
import MenuItem from "../../../types/menu/MenuItem";

const GLFNavList: MenuItem[] = [
  {
    id: 1,
    name: 'incomeExpense',
    complete: false,
    icon: faMoneyBillTrendUp,
    subItems: [
      {
        id: 11,
        name: 'contactInfo',
        componentName: 'ContactInfo',
        complete: false
      },
      {
        id: 12,
        name: 'roundsRate',
        complete: false,
        componentName: 'RoundsRate'
      },
      {
        id: 14,
        name: 'GLF.revenue',
        componentName: 'IncomeAttributeTable',
        complete: false
      },
      {
        id: 15,
        name: 'GLF.sales',
        componentName: 'IncomeAttributeTable',
        complete: false
      },
      {
        id: 16,
        name: 'GLF.cos',
        componentName: 'IncomeAttributeTable',
        complete: false
      },
      {
        id: 17,
        name: 'GLF.expense',
        componentName: 'IncomeAttributeTable',
        complete: false
      },
      {
        id: 18,
        name: 'GLF.charges',
        componentName: 'IncomeAttributeTable',
        complete: false
      },
      {
        id: 19,
        name: 'GLF.reserve',
        componentName: 'IncomeAttributeTable',
        complete: false
      },
      {
        id: 20,
        name: 'capitalCostsSummary',
        componentName: 'CapitalCostContainer',
        complete: false,
        hasTabs: true,
        subItems: [
          {
            id: 201,
            name: 'GLF.course',
            complete: false
          },
          {
            id: 202,
            name: 'GLF.building',
            complete: false
          },
        ]
      },
      {
        id: 2121,
        name: 'comments',
        componentName: 'Comment',
        complete: false
      },
    ],
  },
  {
    id: 2,
    name: 'apartment',
    complete: false,
    icon: faBuilding,
    subItems: [
      {
        id: 21,
        name: 'suiteBreakdownTable',
        componentName: 'SuiteBreakdown',
        complete: false
      }
    ]
  },
  {
    id: 3,
    name: 'commercial',
    complete: false,
    icon: faCartShopping,
    subItems: [
      {
        id: 31,
        name: 'leaseInformationTable',
        componentName: 'LeaseInfo',
        complete: false
      },
      {
        id: 32,
        name: 'parkingPropertySummary',
        complete: false,
        componentName: 'ParkingPropertySummary',
      }
    ]
  },
  {
    id: 4,
    name: 'summaryReview',
    componentName: 'Summary',
    complete: false,
    icon: faFilePdf
  },
  {
    id: 5,
    name: 'submit',
    componentName: 'Submit',
    complete: false,
    icon: faFileInvoiceDollar
  }
];

export default GLFNavList;