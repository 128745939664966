import { GET_GLOBAL_SETTING } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState } from "../../types";

export interface GlobalSettingState extends BaseAsyncReducerState {
    setting: Record<string, unknown>
}

const initialState: GlobalSettingState = {
    setting: {},
    loading: false,
    error: false
}

function getGlobalSettingReducer(state = initialState, action: AppAction) {
    return defaultAsyncReducer(state, action as AppAction, GET_GLOBAL_SETTING);
}

export default getGlobalSettingReducer;