import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { RootState } from '../../redux/reducers/root';
import { GetElsLookupsState } from '../../redux/reducers/getLookups/getElsLookupsReducer';
import CommercialUnitType from '../../types/unit/CommercialUnit';
import Button from '../common/Button/Button';
import CommercialUnitDatePickerField from './CommercialUnitDatePickerField';
import CommercialUnitDropdownField from './CommercialUnitDropdownField';
import CommercialUnitInputField from './CommercialUnitInputField';
import Radio from '../common/Radio/Radio';
import OperatingExpense from './OperatingExpense';
import Rate from './Rate';
import StepDate from './StepDate';
import CommercialUnitInternalTable from './CommercialUnitInternalTable';
import { validate, validateField, validateToastNet, validateToastVacant } from './CommercialUnitValidator';
import "./CommercialUnit.css";
import { GlobalSettingState } from '../../redux/reducers/getGlobalSetting/getGlobalSettingReducer';
import { useReadOnly } from '../../utils/ReadOnlyContext';


interface CommercialUnitProps {
    unit: CommercialUnitType,
    onSave: (unit: Record<string, unknown>) => void,
    onCancel: () => void,
    showDeleteBtn: boolean
}

export default function CommercialUnit(props: CommercialUnitProps): JSX.Element {
    const { i18n } = useTranslation();
    const { t } = useTranslation(['unit', 'common']);
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);
    const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);
    const isInternalUser = localStorage.getItem('isInternalUser') ? true : false;
    const currencySymbol = (globalSettingState.setting["currency"] as Record<string, unknown>)["symbol"];
    const currencyPerSF = currencySymbol + t('headings.perSF');
    const [changeField, setChangeField] = useState<string | undefined>(undefined);
    const [changedFields, setChangedFields] = useState<Array<string>>([]);
    const { isReadOnly } = useReadOnly()

    const initData = () => {
        const data = {} as Record<string, unknown>;
        for (const [key, value] of Object.entries(props.unit)) {
            if (value == null) {
                data[key] = undefined;
            } else {
                data[key] = value;
            }
        }
        data['init'] = true;
        return data;
    }

    const [formData, setFormData] = useState<Record<string, unknown>>(initData());
    const [errors, setErrors] = useState<Record<string, string>>({
        tenantName: "",
        unitSubtypeCode: "",
        leaseStatusCode: "",
        startDate: "",
        leaseTypeCode: "",
        netLeasableArea: "",
        tenantUnitNumber: "",
        floorLevel: "",
        negotiatedDate: "",
        endDate: "",
        annualRent: "",
        currentBaseRent: "",
        propertyTaxRecovery: "",
        operatingRecovery: "",
        freeRentMonth: "",
        tenantImprovement: "",
        stepUpRate1: "",
        stepUpRate2: "",
        stepUpRate3: "",
        stepUpRate4: "",
    });

    const hasError = (errors: Record<string, string>) => {
        let hasError = false;
        for (const errorKey in errors) {
            if (errors[errorKey].length > 0) {
                hasError = true;
                break;
            }
        }
        return hasError;
    };

    const boolFields = ["landLeaseFlag"];
    const numberFields = ["netLeasableArea", "freeRentMonth"];
    const checkFields = [
        "includeInsuranceFlag",
        "includeManagementFlag",
        "includeMaintenanceFlag",
        "includeHydroFlag",
        "includeWaterFlag",
        "includeHvacFlag",
        "linkFlag"
    ];
    const currencyFields = [
        "annualRent",
        "currentBaseRent",
        "propertyTaxRecovery",
        "operatingRecovery",
        "tenantImprovement",
        "stepUpRate1",
        "stepUpRate2",
        "stepUpRate3",
        "stepUpRate4",
    ];

    const saveChangedField = (name: string) => {
        if (!changedFields.includes(name)) {
            setChangedFields([...changedFields, name])
        }
    }

    const handleDateChange = (date: Date | null | undefined, name: string) => {
        if (typeof name !== "string") {
            return;
        }

        let fd = {};
        if (date) {
            const dateStr = format(date, "yyyy-MM-dd")
            fd = { ...formData, [name]: dateStr };
        } else {
            fd = { ...formData, [name]: undefined };
        }
        setFormData(fd);
        setChangeField(name);
        saveChangedField(name);
    };

    const handleCurrencyChange = (value: number | undefined | null, name: string, _oldAmt: number | undefined | null) => {
        setFormData({ ...formData, [name]: value });
        setChangeField(name);
        saveChangedField(name);
    };

    const formChanged = (e: React.FormEvent<HTMLFormElement>) => {
        const target = e.target as HTMLInputElement;
        const targetName = target.name;
        if (!currencyFields.includes(targetName)) {
            const value = target.value.trim();
            if (targetName === "variables") {
                const values = [];
                const selectedOptions = (e.target as HTMLSelectElement).selectedOptions;
                for (const option of selectedOptions) {
                    const variable = (elsLookupsState.dropdowns["unitVariable"] as Record<string, unknown>[]).find((variable) => variable.unitVariableCode === option.value);
                    if (variable) {
                        values.push({ code: variable.unitVariableCode, description: variable.unitVariableDescription });
                    }
                }
            } else if (boolFields.includes(targetName)) {
                setFormData({ ...formData, [targetName]: value.toLowerCase() === "true" });
            } else if (checkFields.includes(targetName)) {
                setFormData({ ...formData, [targetName]: target.checked });
            } else if (numberFields.includes(targetName)) {
                if (value === "") {
                    setFormData({ ...formData, [targetName]: undefined });
                } else {
                    setFormData({ ...formData, [targetName]: Number(value) });
                }
            } else {
                if (value === "") {
                    setFormData({ ...formData, [targetName]: undefined });
                } else {
                    setFormData({ ...formData, [targetName]: value });
                }
            }
            setChangeField(targetName);
            saveChangedField(targetName);
        }
    }

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const validateErrors = validate(formData, errors, t);
        setErrors(validateErrors);
        if (hasError(validateErrors)) {
            toast.error(t("toastMessage.validationFailed") as string);
        } else {
            let result = props.unit;
            result.unitTypeCode = "1"

            if (changedFields.includes("analysisFlag")) {
                const analysisReviewedBy = localStorage.getItem("username") ?? null
                const analysisReviewedDate = (new Date().toISOString()) ?? null
                result.analysisReviewedBy = analysisReviewedBy
                result.analysisReviewedDate = analysisReviewedDate
            }

            changedFields.forEach((field) => {
                const value = formData[field];
                if (value) {
                    result = { ...result, [field]: value };
                } else {
                    result = { ...result, [field]: null };
                }
            });

            props.onSave(result as unknown as Record<string, unknown>);
        }
    }

    useEffect(() => {
        if (changeField) {
            const validatedErrors = validateField(changeField, formData, errors, t);
            setErrors(validatedErrors);
            if (["leaseStatusCode", "startDate"].includes(changeField)) {
                validateToastVacant(formData, t);
            }
            if (["leaseTypeCode", "propertyTaxRecovery", "operatingRecovery"].includes(changeField)) {
                validateToastNet(formData, t);
            }
        } else {
            if (!isReadOnly && formData['init']) {
                formData['init'] = false;
                validateToastVacant(formData, t);
                validateToastNet(formData, t);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeField, formData]);

    return (
        <div>
            <form id="unit-form" onSubmit={(e) => submit(e)} onChange={(e) => formChanged(e)}>
                <table className='unit-table no-border-input' role="none">
                    <tbody className='valign-top'>
                        <tr className='unit-root-level-tr'>
                            <td>
                                {isInternalUser && <CommercialUnitInternalTable formData={formData} />}
                            </td>
                            <td>
                                <table className='unit-table-section' role="none">
                                    <tbody>
                                        <CommercialUnitInputField name='tenantName' formData={formData} errors={errors}
                                            heading={t('headings.tenant-name')} title={t('tooltips.tenant-name-tooltip')} />
                                        <CommercialUnitDropdownField name='unitSubtypeCode' formData={formData} errors={errors}
                                            heading={t('headings.unit-use')} title={t('tooltips.unit-use-tooltip')} />
                                        <CommercialUnitDropdownField name='leaseStatusCode' formData={formData} errors={errors}
                                            heading={t('headings.lease-status')} title={t('tooltips.lease-status-tooltip')} />
                                        <CommercialUnitDatePickerField name={'startDate'} formData={formData} errors={errors}
                                            onChange={handleDateChange} asterisk={true} maxDate={new Date().getFullYear() + "-12-31"}
                                            heading={t('headings.start-date')} title={t('tooltips.start-date-tooltip')} />
                                        <tr>
                                            <td className='label-cell'>{t('headings.land-lease')}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <fieldset className='noborder-fieldset'>
                                                    <legend className='invisible-legend'><span>landLeaseFlag</span></legend>
                                                    <div className='land-lease' title={t('tooltips.land-lease-tooltip')}>
                                                        <div>Yes&nbsp;&nbsp;</div>
                                                        <Radio name="landLeaseFlag" value="true"
                                                            defaultChecked={formData.landLeaseFlag != null && formData.landLeaseFlag === true} />
                                                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                        <div>No&nbsp;&nbsp;</div>
                                                        <Radio name="landLeaseFlag" value="false"
                                                            defaultChecked={formData.landLeaseFlag != null && formData.landLeaseFlag === false} />
                                                    </div>
                                                </fieldset>
                                            </td>
                                        </tr>
                                        <CommercialUnitDropdownField name='leaseTypeCode' formData={formData} errors={errors}
                                            heading={t('headings.lease-type')} title={t('tooltips.lease-type-tooltip')} />
                                        <CommercialUnitInputField name='netLeasableArea' formData={formData} errors={errors} type="number"
                                            heading={t('headings.leased-area')} title={t('tooltips.leased-area-tooltip')} />
                                    </tbody>
                                </table>
                            </td>
                            <td>
                                <table className='unit-table-section' role="none">
                                    <tbody>
                                        <CommercialUnitInputField name='tenantUnitNumber' formData={formData} errors={errors}
                                            heading={t('headings.unit-number')} title={t('tooltips.unit-number-tooltip')} />
                                        <CommercialUnitDropdownField name='floorLevel' formData={formData} errors={errors}
                                            heading={t('headings.floor-level')} title={t('tooltips.floor-level-tooltip')} />
                                        <CommercialUnitDatePickerField name={'negotiatedDate'} formData={formData} errors={errors}
                                            onChange={handleDateChange} asterisk={false}
                                            heading={t('headings.negotiated-date')} title={t('tooltips.negotiated-date-tooltip')} />
                                        <CommercialUnitDatePickerField name={'endDate'} formData={formData} errors={errors}
                                            onChange={handleDateChange} asterisk={false} minDate={"2019-06-01"}
                                            heading={t('headings.end-date')} title={t('tooltips.end-date-tooltip')} />
                                        <tr>
                                            <td className='label-cell' id="select-all-expenses"
                                                title={t('tooltips.enpenses-header-tooltip')}>
                                                {t('headings.enpenses-header')}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table role="none">
                                    <OperatingExpense formData={formData} />
                                </table>
                            </td>
                            <td>
                                <label className='label-title'>{t('headings.rates')}</label>
                                <table className='unit-table half-border' role="none">
                                    <Rate formData={formData} handleCurrencyChange={handleCurrencyChange} errors={errors} />
                                </table>
                                <br />
                                <label className='label-title' title={t('tooltips.step-dates-tooltip')}>{t('headings.step-dates')}</label>
                                <table className='unit-table half-border' role="none">
                                    <StepDate formData={formData} currencySymbol={currencyPerSF}
                                        handleCurrencyChange={handleCurrencyChange} handleDateChange={handleDateChange} errors={errors} />
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='btn-grp'>
                    {!isReadOnly && <Button type='submit'>
                        {t("save", { ns: 'common' }).toLocaleUpperCase(i18n.language)}
                    </Button>}
                    {props.showDeleteBtn && (<Button type='danger' onClick={() => props.onCancel()}>
                        {t("delete", { ns: 'common' }).toLocaleUpperCase(i18n.language)}
                    </Button>)}
                </div>
            </form >
        </div>
    );
}
