import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OperationProps } from '../../pages/BusinessOperations/BusinessOperations';
import GEVInput from './GEVInput';
import GEVYesNo from './GEVYesNo';
import GEVTexttarea from './GEVTexttarea';
import GEVGrainLicenseType from './GEVGrainLicenseType';

export default function OtherAgriBusiness(props: OperationProps): JSX.Element {
    const { t } = useTranslation('gev')
    const [data, setData] = useState(props.data)

    useEffect(() => {
        if (props.validating) {
            const err: Record<string, string> = {}
            const grainLicenseType = data["grainLicenseType"] as Record<string, boolean | null>
            if (grainLicenseType &&
                !Object.keys(grainLicenseType).some((option) => grainLicenseType[option] === true)) {
                err["grainLicenseType"] = t("missingRequiredField")
            }
            if (!data["licenseRegisteredTo"]) {
                err["licenseRegisteredTo"] = t("missingRequiredField")
            }
            if (data["licenseRegisteredTo"] && data["licenseRegisteredTo"].toString().length > 100) {
                err["licenseRegisteredTo"] = t("max-100-chars")
            }
            if (data["isLicenseInUse"] === null) {
                err["isLicenseInUse"] = t("missingRequiredField")
            }
            if (!data["totalBuschelCapacity"]) {
                err["totalBuschelCapacity"] = t("missingRequiredField")
            }
            if (data["totalBuschelCapacity"] && data["totalBuschelCapacity"].toString().length > 15) {
                err["totalBuschelCapacity"] = t("max-15-digits")
            }
            if (data["isTruckScaleOnSite"] === null) {
                err["isTruckScaleOnSite"] = t("missingRequiredField")
            }
            if (data["isTruckScaleOnSite"] && !data["truckScaleCapacity"]) {
                err["truckScaleCapacity"] = t("missingRequiredField")
            }
            if (data["truckScaleCapacity"] && data["truckScaleCapacity"].toString().length > 100) {
                err["truckScaleCapacity"] = t("max-100-chars")
            }
            if (data["isCommercialGrainElevatorOperated"] === null) {
                err["isCommercialGrainElevatorOperated"] = t("missingRequiredField")
            }
            if (!data["primaryBusinessOperations"]) {
                err["primaryBusinessOperations"] = t("missingRequiredField")
            }
            if (data["primaryBusinessOperations"] && data["primaryBusinessOperations"].toString().length > 100) {
                err["primaryBusinessOperations"] = t("max-100-chars")
            }
            if (data["areAnyBuildingsAddedOrRemoved"] === null) {
                err["areAnyBuildingsAddedOrRemoved"] = t("missingRequiredField")
            }
            if (data["areAnyBuildingsAddedOrRemoved"] && !data["comments"]) {
                err["comments"] = t("missingRequiredField")
            }
            if (data["comments"] && data["comments"].toString().length > 200) {
                err["comments"] = t("max-200-chars")
            }
            props.validate(props.operation)
            props.setData(props.operation, data)
            if (Object.keys(err).length) {
                props.setErrors(props.operation, err)
            }
        }
    }, [data, props, t])

    return (
        <>
            <div>
                <form onChange={(e) => props.handleFormChange(e, data, setData)}>
                    <table width={'100%'} role="none">
                        <tbody>
                            <GEVGrainLicenseType data={data}
                                operation={props.operation}
                                name={'grainLicenseType'}
                                errors={props.errors}
                            />
                            <GEVInput data={data}
                                operation={props.operation}
                                name={'licenseRegisteredTo'}
                                errors={props.errors}
                            />
                            <GEVYesNo
                                data={data}
                                operation={props.operation}
                                name={'isLicenseInUse'}
                                errors={props.errors}
                            />
                            <GEVInput data={data}
                                operation={props.operation}
                                name={'totalBuschelCapacity'}
                                type="number"
                                errors={props.errors}
                            />
                            <GEVYesNo
                                data={data}
                                operation={props.operation}
                                name={'isTruckScaleOnSite'}
                                errors={props.errors}
                            />
                            <GEVInput data={data}
                                operation={props.operation}
                                name={'truckScaleCapacity'}
                                errors={props.errors}
                            />
                            <GEVYesNo
                                data={data}
                                operation={props.operation}
                                name={'isCommercialGrainElevatorOperated'}
                                errors={props.errors}
                            />
                            <GEVInput data={data}
                                operation={props.operation}
                                name={'primaryBusinessOperations'}
                                errors={props.errors}
                            />
                            <GEVYesNo
                                data={data}
                                operation={props.operation}
                                name={'areAnyBuildingsAddedOrRemoved'}
                                errors={props.errors}
                            />
                            <GEVTexttarea data={data}
                                operation={props.operation}
                                name={'comments'}
                                errors={props.errors}
                            />
                        </tbody>
                    </table>
                </form>
            </div>
        </>
    )
}
