import { LOG_OUT } from "../../actionTypes/actionsTypes"
import { LocalAction } from "../../types"

export type LogoutState = {
  requested: boolean
}

const initialState: LogoutState = {
  requested: false
}

function getLogoutReducer(state = initialState, action: LocalAction) {
  switch(action.type) {
    case LOG_OUT:
      return {
        ...state,
        requested: true
      }
    default:
      return state;
  }
}

export default getLogoutReducer;