import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getIncomeFile } from '../../redux/actions/file/file';
import { resetGetProfileDetails } from '../../redux/actions/profile/profile';
import { searchProfileByRoll } from '../../redux/actions/profile/searchRoll';
import { setReadOnly } from '../../redux/actions/readOnly/readOnly';
import { EXTERNAL_IN_PROGRESS, NEW_CREATION, RESET_FILE, RESET_SEARCH_PROFILE_BY_ROLL } from '../../redux/actionTypes/actionsTypes';
import { RootState } from '../../redux/reducers/root';
import { ProfileByRollState } from '../../redux/reducers/getProfile/getProfileByRollReducer';
import { ProfileOverviewState } from '../../redux/reducers/getProfile/getProfileOverviewReducer';
import { CampaignListByRoll } from '../../types/profile/CampaignListByRoll';
import { GeneratePDFPayload } from '../../types/pdf/GeneratePDFPayload';
import { FileState } from '../../redux/reducers/getFile/getFileReducer';
import AnchorAsButton from '../common/AnchorAsButton/AnchorAsButton';
import Button from '../common/Button/Button';
import LoadingSpinner from '../common/LoadingSpinner/LoadingSpinner';
import "./CampaignList.css"

export default function CampaignList(): JSX.Element {
    const { t } = useTranslation('transition')
    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const searchRollStore: ProfileByRollState = useSelector((state: RootState) => state.profileReducer.getProfileByRoll)
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
    const fileState: FileState = useSelector((state: RootState) => state.fileReducer.file)
    const [campaignList, setCampaignList] = useState<CampaignListByRoll[] | null>(null)
    const [currentCampaign, setCurrentCampaign] = useState<CampaignListByRoll | undefined>(undefined)
    const [searchingRoll, setSearchingRoll] = useState(false)
    const [downloading, setDownloading] = useState(false)
    const previousYearsNumber = 4
    const currentCampaignYear = profileOverview.profile.current_campaign.year
    const roll = localStorage.getItem('rollNumber') as string
    const isInternalUser = localStorage.getItem("isInternalUser")
    const [choosedSnapshot, setChoosedSnapshot] = useState<Record<string, number>>({
        campaignYear: 0,
        campaignId: 0,
        snapshotId: 0,
    })

    useEffect(() => {
        if (campaignList === null) {
            dispatch({ type: RESET_SEARCH_PROFILE_BY_ROLL })
            dispatch(searchProfileByRoll(roll))
            setSearchingRoll(true)
        }
    }, [campaignList, dispatch, roll])

    useEffect(() => {
        if (searchingRoll) {
            if (searchRollStore.status) {
                if (searchRollStore.status === 200) {
                    const campaigns = searchRollStore.campaigns
                    if (campaigns) {
                        const currentCampaign = campaigns.find((campaign) => (
                            campaign.year === currentCampaignYear
                            && campaign.currentSnapshotId
                        ))
                        setCurrentCampaign(currentCampaign)
                        const previousYears: number[] = []
                        for (let i = 1; i <= previousYearsNumber; i++) {
                            previousYears.push(currentCampaignYear - i)
                        }
                        const campaignListNumber = currentCampaign ? previousYearsNumber : previousYearsNumber + 1
                        const campaignList = campaigns.filter((campaign) => {
                            return campaign.year !== currentCampaignYear
                                && campaign.currentSnapshotId
                                && previousYears.includes(campaign.year)
                            // && [
                            //     EXTERNAL_IN_PROGRESS,
                            //     EXTERNAL_SUBMITTED,
                            //     INTERNAL_IN_PROGRESS
                            // ].includes(campaign["statusCode"] as string)
                        }).slice(0, campaignListNumber)
                        setCampaignList(campaignList)
                    }
                } else {
                    setCurrentCampaign(undefined)
                    setCampaignList([])
                    if (searchRollStore.message) {
                        toast.error(searchRollStore.message)
                    }
                }
                dispatch({ type: RESET_SEARCH_PROFILE_BY_ROLL })
                setSearchingRoll(false)
            }
        }
    }, [currentCampaignYear, dispatch, searchRollStore.campaigns, searchRollStore.message, searchRollStore.status, searchingRoll])

    const isReadOnly = (campaign: CampaignListByRoll, snapshot: Record<string, unknown>) => {
        if (isInternalUser) {
            return false
        }

        if (campaign.year !== currentCampaignYear) {
            return true
        }

        const campaignStatus = campaign.statusCode
        const snapshotType = snapshot["snapshotTypeCode"]
        if ((campaignStatus === NEW_CREATION || campaignStatus === EXTERNAL_IN_PROGRESS)
            && (snapshotType === '2')) {
            return false
        }

        return true
    }

    const gotoReturn = (
        campaign: CampaignListByRoll,
        snapshot: Record<string, unknown>,
        isReadonly: boolean
    ) => {
        dispatch(setReadOnly(isReadonly))
        dispatch(resetGetProfileDetails())

        const choosedSnapshot = {
            campaignYear: campaign["year"],
            campaignId: snapshot["campaignId"] as number,
            snapshotId: snapshot["snapshotId"] as number,
        }
        setChoosedSnapshot(choosedSnapshot)
        console.log("goto", choosedSnapshot)

        localStorage.setItem("activeCampaignYear", String(choosedSnapshot.campaignYear))
        localStorage.setItem('campaignId', String(choosedSnapshot.campaignId))
        localStorage.setItem('currentSnapshotId', String(choosedSnapshot.snapshotId))
        navigate('/portal')
    }

    const downloadPdf = (
        campaign: CampaignListByRoll,
        snapshot: Record<string, unknown>,
    ) => {
        const choosedSnapshot = {
            campaignYear: campaign["year"],
            campaignId: snapshot["campaignId"] as number,
            snapshotId: snapshot["snapshotId"] as number,
        }
        setChoosedSnapshot(choosedSnapshot)
        console.log("download", choosedSnapshot)

        const payload: GeneratePDFPayload = {
            language: i18n.language,
            rollNumber: roll,
            profileId: profileOverview.profile.profile_id,
            campaignYear: choosedSnapshot.campaignYear,
            campaignId: choosedSnapshot.campaignId,
            snapshotId: choosedSnapshot.snapshotId,
            snapshotRequestType: String(snapshot["snapshotTypeCode"]),
        }
        dispatch(getIncomeFile(payload))
        setDownloading(true)
    }

    useEffect(() => {
        if (downloading && fileState.loading === false) {
            if (fileState.status === 200) {
                const base64string = "data:application/pdf;base64," + fileState.pdf
                const downloadLink = document.createElement("a")
                downloadLink.href = base64string
                downloadLink.download = roll + "_" + choosedSnapshot.campaignYear + "_PIER.pdf"
                downloadLink.click()
            } else if (fileState.message) {
                toast.error(fileState.message)
            }
            dispatch({ type: RESET_FILE })
            setDownloading(false)
        }
    }, [choosedSnapshot.campaignYear, dispatch, downloading, fileState.loading, fileState.message, fileState.pdf, fileState.status, roll])

    const displaySnapshotActions = (
        campaign: CampaignListByRoll,
        snapshotType: string
    ) => {
        const snapshots = campaign.snapshots
        if (snapshots) {
            const snapshot = snapshots.find((s) => s["snapshotTypeCode"] === snapshotType)
            if (snapshot) {
                const isReadonly = isReadOnly(campaign, snapshot)
                if (campaign.year === currentCampaignYear) {
                    return <>
                        <div className={'action-container-column'}>
                            {
                                (isReadonly === false) &&
                                <Button onClick={() => gotoReturn(campaign, snapshot, false)} type="submit" text={t("continue.continue")} />
                            }
                            <div className='action-container'>
                                <AnchorAsButton onClick={() => downloadPdf(campaign, snapshot)}>
                                    {t("overview.download")}
                                </AnchorAsButton>
                                |
                                <AnchorAsButton onClick={() => gotoReturn(campaign, snapshot, true)}>
                                    {t("overview.view-return")}
                                </AnchorAsButton>
                            </div>
                        </div>
                    </>
                } else {
                    return <>
                        <div className='action-container'>
                            <AnchorAsButton onClick={() => downloadPdf(campaign, snapshot)}>
                                {t("overview.download")}
                            </AnchorAsButton>
                            |
                            <AnchorAsButton onClick={() => gotoReturn(campaign, snapshot, true)}>
                                {t("overview.view-return")}
                            </AnchorAsButton>
                            {
                                (isReadonly === false) &&
                                <>
                                    |
                                    <AnchorAsButton onClick={() => gotoReturn(campaign, snapshot, false)}>
                                        {t("overview.edit")}
                                    </AnchorAsButton>
                                </>
                            }
                        </div>
                    </>
                }
            }
        }
        return null
    }

    return (
        <>
            <LoadingSpinner loading={searchingRoll || downloading}>
                <div className='flex flex-row justify-content-center'>
                    {(currentCampaign === undefined && (campaignList && campaignList?.length === 0))
                        ?
                        <h3>No previous returns found</h3>
                        :
                        <table className='campaign-list-table'>
                            <tbody>
                                {
                                    (currentCampaign || (campaignList && campaignList.length > 0)) &&
                                    <tr>
                                        <td></td>
                                        <td>Your Submission</td>
                                        <td>MPAC Reviewed Submission</td>
                                    </tr>
                                }
                                {
                                    currentCampaign &&
                                    <tr>
                                        <td> {currentCampaign['year']}</td>
                                        <td>{displaySnapshotActions(currentCampaign, "2")}</td>
                                        <td>{displaySnapshotActions(currentCampaign, "3")}</td>
                                    </tr>
                                }
                                {
                                    (campaignList && campaignList.length > 0)
                                    && campaignList.map((campaign, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {campaign['year']}
                                                </td>
                                                <td>
                                                    {
                                                        displaySnapshotActions(campaign, "2")
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        displaySnapshotActions(campaign, "3")
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                </div>
            </LoadingSpinner>
        </>
    )
}
