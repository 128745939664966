import { faBuilding, faCartShopping, faFileInvoiceDollar, faFilePdf, faMoneyBillTrendUp } from "@fortawesome/free-solid-svg-icons";
import MenuItem from "../../../types/menu/MenuItem";

const CIXNavList: MenuItem[] = [
  {
    id: 1,
    name: 'incomeExpense',
    complete: false,
    icon: faMoneyBillTrendUp,
    subItems: [
      {
        id: 12,
        name: 'contactInfo',
        complete: false,
        componentName: 'ContactInfo'
      },
      {
        id: 13,
        name: 'revenue',
        complete: false,
        hasTabs: true,
        componentName: 'RevenueContainer',
        subItems: [
          {
            id: 131,
            name: 'rents',
            complete: false
          },
          {
            id: 132,
            name: 'recoveries',
            complete: false
          },
          // {
          //   id: 133,
          //   name: 'capitalCostsSummary',
          //   complete: false
          // },
          {
            id: 134,
            name: 'otherRevenue',
            complete: false
          },
          {
            id: 135,
            name: 'rentalLoss',
            complete: false
          },
        ]
      },
      {
        id: 14,
        name: 'expenses',
        complete: false,
        hasTabs: true,
        componentName: 'ExpenseContainer',
        subItems: [
          {
            id: 141,
            name: 'general',
            complete: false,
          },
          {
            id: 142,
            name: 'admin',
            complete: false,
          },
          {
            id: 143,
            name: 'utilities',
            complete: false,
          },
          {
            id: 144,
            name: 'propMaintenance',
            complete: false,
          },
          {
            id: 145,
            name: 'other',
            complete: false,
          } 
        ],
      },
      {
        id: 15,
        name: 'capitalExpSummary',
        complete: false,
        componentName: 'CapitalCostsSummary',
      },
      {
        id: 16,
        name: 'comments',
        componentName: 'Comment',
        complete: false
      }
    ],
  },
  {
    id: 2,
    name: 'commercial',
    complete: false,
    icon: faCartShopping,
    subItems: [
      // {
      //   id: 21,
      //   name: 'LeaseImprovementSummary',
      //   forInternal: false,
      //   complete: false
      // },
      {
        id: 21,
        name: 'leaseInformationTable',
        complete: false,
        componentName: 'LeaseInfo',
      },
      {
        id: 22,
        name: 'parkingPropertySummary',
        complete: false,
        componentName: 'ParkingPropertySummary',
      }
    ]
  },
  {
    id: 3,
    name: 'apartment',
    complete: false,
    icon: faBuilding,
    subItems: [
      {
        id: 31,
        name: 'suiteBreakdownTable',
        complete: false,
        componentName: 'SuiteBreakdown'
      }
    ]
  },
  {
    id: 4,
    name: 'summaryReview',
    complete: false,
    componentName: 'Summary',
    icon: faFilePdf
  },
  {
    id: 5,
    name: 'submit',
    complete: false,
    componentName: 'Submit',
    icon: faFileInvoiceDollar
  }
];

export default CIXNavList;