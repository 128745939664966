/**
 * Update: 18/04/2024 - Update reducer logic by reusing default reducer, setting initial state to a safe state
 */
import IncomeAttributeLookupState from "../../../types/lookups/IncomeAttributeLookupState";
import { GET_LOOKUPS, RESET_LOOKUPS } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState } from "../../types";

export interface LookupsState extends BaseAsyncReducerState {
    lookups: IncomeAttributeLookupState
}

const initialState: LookupsState = {
    lookups: {
        1: {},
        2: {},
        3: {},
        4: {}
    },
    loading: false,
    error: false,
    success: false
}

function getLookupsReducer(state = initialState, action: AppAction) {
    if (action.type === RESET_LOOKUPS) {
        return { ...initialState };
    } else {
        return defaultAsyncReducer(state, action, GET_LOOKUPS);
    }
}

export default getLookupsReducer;