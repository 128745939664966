import React from "react";
import './Radio.css'
import { useReadOnly } from "../../../utils/ReadOnlyContext";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RadioProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    forceEnabled?: boolean;
}

export default function Radio(props: RadioProps): JSX.Element {
    const { isReadOnly } = useReadOnly()
    return (
        <>
            <label className="radio-container" aria-label={props.name ? props.name : props.id}>
                <input  {...props} type="radio"
                    disabled={props.forceEnabled ? false : isReadOnly}
                    aria-label={props.name ? props.name : props.id} />
                <span className="radiomark"></span>
            </label>
        </>
    )
}
