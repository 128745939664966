import React from 'react'
import './RowKey.css'

interface RowKeyProps {
    children: React.ReactNode,
    headers?: string
}

function RowKey(props: RowKeyProps): JSX.Element {
  return (
    <td headers={props.headers} className='table-row-key'>{props.children}</td>
  )
}

export default RowKey