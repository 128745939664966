import { UPDATE_COMMENT, RESET_UPDATE_COMMENT } from "../../actionTypes/actionsTypes";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types";
import { CommentDataClass } from "./getCommentReducer";

export interface UpdateCommentState extends BaseAsyncReducerState {
    success: boolean,
    message: string,
    data: CommentDataClass
}

// Define an initial state value
const initialState: UpdateCommentState = {
    success: false,
    message: "",
    loading: false,
    error: false,
    data: {
        commentId: null,
        commentDate: null,
        commentTypeCode: null,
        userName: null,
        profileId: null,
        campaignId: null,
        commentText: null,
        deletedFlag: null
    }
}

function updateCommentReducer(state = initialState, action: AppAction | LocalAction) {
    switch (action.type) {
        case UPDATE_COMMENT.PENDING:
            return {
                ...state,
                loading: true,
                error: false,
                success: false,
            }
        case UPDATE_COMMENT.SUCCESS:
            return {
                ...state,
                success: (action as AppAction).payload.success,
                data: (action as AppAction).payload.data,
                loading: false,
                error: false,
            }
        case UPDATE_COMMENT.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
            }
        case RESET_UPDATE_COMMENT:
            return {
                ...initialState
            }
        default:
            return state

    }
}

export default updateCommentReducer;