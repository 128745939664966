import { PUT_PROFILE_DETAILS, RESET_PUT_PROFILE_DETAILS } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types";

export interface PutProfileDetailsState extends BaseAsyncReducerState {
  updated: boolean,
  message: string,
  errors?: Record<string, string | string[]>[]
}

const initialState: PutProfileDetailsState = {
  updated: false,
  message: "",
  loading: false,
  error: false,
  errors: []
}

function putProfileDetailsReducer(state = initialState, action: AppAction | LocalAction) {
  if (action.type === RESET_PUT_PROFILE_DETAILS) {
    return initialState;
  } else {
    return defaultAsyncReducer(state, action as AppAction, PUT_PROFILE_DETAILS)
  }
}

export default putProfileDetailsReducer;