import React from 'react'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import SidebarItemSmall from '../SidebarItemSmall/SidebarItemSmall';
import './SidebarItemMedium.css'
import { SidebarItemProps } from '../../../layouts/Sidebar/Sidebar';

function SidebarItemMedium(props: SidebarItemProps) {

    const isActive = props.item.id === props.activeItemId;
    const isChildActive = (props.isChildActive) ? props.isChildActive(props.item, props.activeItemId) : false;
    const isAnyActive = isActive || isChildActive
    const hasChildren = props.item.subItems && props.item.subItems.length > 0
    const { t } = useTranslation('navigation');

    return (
        <div className='sidebar-item-medium-container'>
            <div onClick={() => { props.menuOnClick(props.item) }}
                className={`sidebar-medium-item ${isAnyActive ? 'sidebar-medium-item-active' : 'sidebar-medium-item-inactive'} ${hasChildren ? 'sidebar-medium-item-children' : ''}`}>
                <span className='sidebar-status-container'>
                    {!isAnyActive && props.item.complete && <FontAwesomeIcon icon={faCircleCheck} />}
                </span>
                <p className='sidebar-medium-item-text'>
                    {t(props.item.name)}
                </p>
            </div>
            {isAnyActive && props.item.subItems &&
                <div className='sidebar-item-small-root-container'>
                    {props.item.subItems.map((item, index) => (
                        <SidebarItemSmall key={item.id} item={item} activeItemId={props.activeItemId}
                            index={index} menuOnClick={props.menuOnClick} />
                    ))}
                </div>
            }
        </div>
    )
}

export default SidebarItemMedium