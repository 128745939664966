import { combineReducers } from "redux";
import { AppAction } from "../types";
import propertiesReducer from "./getProperties";
import menuReducer from "./getMenu";
import incomeUnitReducer from "./getIncomeUnit";
import lookupsReducer from "./getLookups";
import profileReducer from "./getProfile";
import incomeAttributesReducer from "./getIncomeAttributes";
import fileReducer from "./getFile";
import saveDataReducer from "./getSaveData";
import authReducer from "./getAuthReducer";
import globalSettingReducer from "./getGlobalSetting";
import campaignReducer from "./getCampaign";
import commentReducer from "./getComment";
import acknowledgementReducer from "./updateAcknowledgement";
import readOnlyReducer from "./getReadOnly";
import loadingReducer from "./getLoadingReducer";
import assessmentInfoReducer from "./getAssessmentInfo";

// export default combineReducers({ colourReducer, authReducer, formReducer, dashReducer });
export const combinedReducer = combineReducers({
    propertiesReducer,
    lookupsReducer,
    menuReducer,
    profileReducer,
    incomeUnitReducer,
    incomeAttributesReducer,
    fileReducer,
    saveDataReducer,
    globalSettingReducer,
    authReducer,
    campaignReducer,
    commentReducer,
    acknowledgementReducer,
    readOnlyReducer,
    assessmentInfoReducer,
    loadingReducer
});

export type RootState = ReturnType<typeof combinedReducer>;

export const rootReducer = (state: RootState, action: AppAction) => {
    // if (action.type === LOGIN_AUTH.SUCCESS) {
    //     state = {} as RootState;
    // }

    return combinedReducer(state, action);
}