import React, { useState, useEffect } from 'react'
import './TableRow.css'
import Input from '../Input/Input';
import RowKey from '../RowKey/RowKey';
import { useReadOnly } from '../../../utils/ReadOnlyContext';

export interface TableCellData {
    name: string;
    type?: "text" | "float" | "integer";
    header?: string;
    symbol?: string;
    forced?: boolean;
    positiveOnly?: boolean;
    inputType?: "input" | "checkbox"
}

interface TableRowProps<T> {
    rowLabel?: string;
    rowLabelHeader?: string
    cellData: TableCellData[];
    dataType: T;
    children?: JSX.Element
    onChange: <K extends keyof T>(name: K, value: string | boolean | number | null) => void;
    onErrorsChange?: (hasErrors: boolean) => void;
}

function TableRow<T>(props: TableRowProps<T>) {
    const { isReadOnly } = useReadOnly();
    const [errors, setErrors] = useState<Record<string, string>>({});

    useEffect(() => {
        if (props.onErrorsChange) {
          props.onErrorsChange(Object.keys(errors).length > 0);
        }
      }, [errors, props]);

    const modifyValue = (value: string, type: string, positiveOnly: boolean | undefined, cellName: string) => {
        if (value === '') {
            setErrors(prev => {
                const { [cellName]: _, ...rest } = prev;
                return rest;
            });
            return value;
        }
    
        if (positiveOnly && value.includes('-')) {
            setErrors(prev => ({ ...prev, [cellName]: 'Value must be positive' }));
        } else {
            setErrors(prev => {
                const { [cellName]: _, ...rest } = prev;
                return rest;
            });
        }
    
        if (type === 'integer') {
            const intValue = parseInt(value, 10);
            if (isNaN(intValue)) {
                return '';
            }
            return String(value);
        } else if (type === 'float') {
            return String(/^\d*\.?\d*$/.test(value) ? parseFloat(value) : value);
        }
    
        return value;
    }

    const parseValue = (type: string, value: string | boolean | number, parse = false) => {
        const asString = String(value)

        if(asString.length === 0) {
            return null;
        }


        switch(type) {
            case "text":
                return asString
                break;
            case "float":
                console.log(parse ? parseFloat(asString) : asString)
                return (parse ? parseFloat(asString) : asString);
                break;
            case "integer": 
                return Math.trunc(parseFloat(asString))
                break;
            default:
                return value
            
        }
    }

    const createInputEle = (type: string, cell: TableCellData): JSX.Element => {
        let inputType: "text" | "number" = "text"
        let valueType: "text" | "integer" | "float" = "text";

        if(cell.type === "float") {
            valueType = "float";
        } else if(cell.type === "integer") {
            valueType = "integer"
        }

        if(cell.type === "float" || cell.type === "integer") {
            inputType = "number"
        } 

        const isError = errors[cell.name];

        switch(type) {
            case "input":
                return (
                    <div className={`input-container ${isError ? 'input-error' : ''}`}>
                        <Input
                    table={true}
                    name={cell.name}
                    id={cell.name}
                    label={cell.header}
                    type={inputType}
                    value={String(props.dataType[cell.name as keyof T] ?? '')}
                    onChange={(event) =>
                        props.onChange(
                            cell.name as keyof T,
                            parseValue(valueType, modifyValue((event.target as HTMLInputElement).value, inputType, cell.positiveOnly, cell.name))
                        )
                    }
                    onBlur={(event) =>
                        props.onChange(
                            cell.name as keyof T,
                            parseValue(valueType, modifyValue((event.target as HTMLInputElement).value, inputType, cell.positiveOnly, cell.name), true)
                        )}
                    forcedType={cell.forced ? cell.type : undefined}
                    positiveOnly={cell.positiveOnly}
                    />
                </div>
            );
            case "checkbox":
                return <input 
                type='checkbox'
                aria-label={cell.name}
                id={cell.name} 
                name={cell.name} 
                onChange={(event) => props.onChange(cell.name as keyof T, (event.target as HTMLInputElement).checked)}
                checked={props.dataType[cell.name as keyof T] === true}
                disabled={isReadOnly}
                />
            default:
                return (
                    <div className={`input-container ${isError ? 'input-error' : ''}`}>
                        <Input
                    table={true}
                    name={cell.name}
                    id={cell.name}
                    label={cell.header}
                    type={inputType}
                    value={String(props.dataType[cell.name as keyof T] ?? '')}
                    onChange={(event) =>
                        props.onChange(
                            cell.name as keyof T,
                            parseValue(valueType, modifyValue((event.target as HTMLInputElement).value, inputType, cell.positiveOnly, cell.name))
                        )
                    }
                    onBlur={(event) =>
                        props.onChange(
                            cell.name as keyof T,
                            parseValue(valueType, modifyValue((event.target as HTMLInputElement).value, inputType, cell.positiveOnly, cell.name), true)
                        )}
                    forcedType={cell.forced ? cell.type : undefined}
                    positiveOnly={cell.positiveOnly}
                    />
                </div>
            );
        }
    }

    return (
        <tr className='pier-datatable-tablerow'>
            {props.rowLabel && 
                <RowKey headers={props.rowLabelHeader}>{props.rowLabel}</RowKey>
            }
            {props.cellData.map((cell, index) => (
                <td
                    key={index}
                    className={`${cell.inputType === "input" ? "full-input-data" : "normal-input"} ${errors[cell.name] ? 'cell-error' : ''}`}
                    headers={cell.header}
                >
                    {cell.symbol && <span className='dollar-sign'>{cell.symbol}</span>}
                    {createInputEle(cell.inputType ? cell.inputType : "", cell)}
                    {errors[cell.name] && <div className="error-message">{errors[cell.name]}</div>}
                </td>
            ))}
            {props.children}
        </tr>
    )    
}

export default TableRow