import React, { ReactElement } from "react";
import "./Switch.css";

interface SwitchProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    id: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}
function Switch(props: SwitchProps): ReactElement {

    return (
        <span>
            <input {...props} type="checkbox" className="checkbox"
                id={props.id} onChange={(e) => props.onChange(e)} />
            <label className="switch" aria-label={props.id}></label>
        </span>
    )
}

export default Switch;