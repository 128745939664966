import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Asterisk from '../Asterisk/Asterisk';
import Dropdown from '../Dropdown/Dropdown';
import Input from '../Input/Input';
import { faTrashCan, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button/Button';
import DataTable from '../DataTable/DataTable';
import { useReadOnly } from '../../../utils/ReadOnlyContext';

interface SuiteBreakdownTableProps {
    data: Record<string, string | number | boolean>[];
    setData: React.Dispatch<React.SetStateAction<Record<string, string | number | boolean>[]>>;
    title: string;
    onChange: (e: React.ChangeEvent, index: number, key: string, isNumber?: boolean) => void;
    onSave: (idx: number) => boolean;
    onDelete?: (idx: number) => void;
    isSuiteTypeMandatory?: boolean;
    isNumUnitsMandatory?: boolean;
    isNumBedsMandatory?: boolean;
    isAreaSFMandatory?: boolean;
    isActualRentMandatory?: boolean;
    isMarketRentMandatory?: boolean;
    isTypicalCareRateMandatory?: boolean;
    isSecondOccupantFeeMandatory?: boolean;
    unitTypeCode?: '1' | '2' | '3' | '4' | '5' | '6';
}

function SuiteBreakdownTable(props: SuiteBreakdownTableProps) {

    const { t } = useTranslation(['suite_breakdown_table']); 
    const [unlocked, setUnlocked] = useState<number[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [numberPerPage, setNumberPerPage] = useState(10);
    const { isReadOnly } = useReadOnly()

    const breakdownHeadings = [
        <th title={t("titleSuiteType")} className='pier-datatable-clear-th' key="column-1-header">{t("suiteBreakdownType")} {props.isSuiteTypeMandatory && <Asterisk/>}</th>,
        <th title={t("titleUnits")} className='pier-datatable-clear-th' key="column-2-header">{t("suiteBreakdownUnits")} {props.isNumUnitsMandatory &&<Asterisk/>}</th>,
        <th className='pier-datatable-clear-th' key="column-3-header">{t("suiteBreakdownBeds")} {props.isNumBedsMandatory && <Asterisk/>}</th>,
        <th title={t("titleSF")} className='pier-datatable-clear-th' key="column-4-header">{t("suiteBreakdownSf")} {props.isAreaSFMandatory && <Asterisk/>}</th>,
        <th title={t("titleActualRent")} className='pier-datatable-clear-th' key="column-5-header">{t("suiteBreakdownActualRent")} {props.isActualRentMandatory && <Asterisk/>}</th>,
        <th title={t("titleMarketRent")} className='pier-datatable-clear-th' key="column-6-header">{t("suiteBreakdownMarketRent")} {props.isMarketRentMandatory && <Asterisk/>}</th>,
        <th title={t("titleTypicalCare")} className='pier-datatable-clear-th' key="column-7-header">{t("suiteBreakdownTypicalCare")} {props.isTypicalCareRateMandatory && <Asterisk/>}</th>,
        <th title={t("titleSecondOcc")} className='pier-datatable-clear-th' key="column-8-header">{t("suiteBreakdownSecondOcc")} {props.isSecondOccupantFeeMandatory && <Asterisk/>}</th>,
        <th className='pier-datatable-clear-th' key="column-9-header"></th>,
        <th className='pier-datatable-clear-th' key="column-10-header"></th>
    ]

    const suiteOptions  = [
        {value: "", text: ""},
        {value: "401", text: "Studio - Small"},
        {value: "402", text: "Studio - Regular"},
        {value: "403", text: "Studio - Large"},
        {value: "404", text: "Studio - Semi-Private"},
        {value: "405", text: "1 Bedroom - Small"},
        {value: "406", text: "1 Bedroom - Regular"},
        {value: "407", text: "1 Bedroom - Large"},
        {value: "408", text: "1 Bedroom - Semi-Private"},
        {value: "409", text: "2 Bedroom - Small"},
        {value: "410", text: "2 Bedroom - Regular"},
        {value: "411", text: "2 Bedroom - Large"},
        {value: "412", text: "2 Bedroom - Semi-Private"},
        {value: "413", text: "Ward"},
        {value: "414", text: "Other"},
    ];

    const removeUnit = (e: React.MouseEvent, index: number) => {
        e.preventDefault();

        props.onDelete && props.onDelete(index);

        const dataSet = [...props.data];
        dataSet.splice(index, 1);

        const removedIdx = unlocked.filter(num => num !== index)
        const decreased = removedIdx.map(num => (num > index ? num - 1 : num))

        props.setData(dataSet);
        setUnlocked(decreased)
        
    }

    const addUnit = (e: React.MouseEvent) => {
        e.preventDefault();
        let newUnit;
        if (props.unitTypeCode) {
            newUnit = {
                unitSubtypeCode: '',
                tenantUnitNumber: '',
                unitCount: '',
                netLeasableArea: '',
                monthlyActualRent: '',
                monthlyMarketRent: '',
                typicalCareRate: '',
                secondOccupantFee: '',
                new: true,
                unitTypeCode: props.unitTypeCode
            }
        } else {
            newUnit = {
                suiteType: '',
                numUnits: '',
                numBeds: '',
                areaSF: '',
                actualRent: '',
                marketRent: '',
                typicalCareRate: '',
                secondOccupantFee: '',
                new: true
            }
        }
        

        const updatedData = [newUnit, ...props.data]
        const increased = unlocked.map(num => num + 1)
        setUnlocked(increased)
        props.setData(updatedData);
    }

    const toggleLocked = (e: React.MouseEvent, index: number) => {
        e.preventDefault()
        if(unlocked.includes(index)) {
            if(props.onSave(index)) {
                const updated = unlocked.filter(row => row !== index)
                setUnlocked(updated)
            }     
        } else if(!hasUnsavedRow()) {
            const updated = [...unlocked, index]
            setUnlocked(updated)
        }
    }

    const hasUnsavedRow = () => {
        return unlocked.length > 0
    }

    const buildTableContent = (): JSX.Element => {
        const tableBody: JSX.Element[] = [];
    
        // Helper function to check if the value is negative
        const isNegative = (value: string | number | boolean) => {
            if (typeof value === "number" && value < 0){
                return true;
            }
            else if (typeof value === "string" && Number(value)<0){
                return true;
            }
            else {
                return false;
            }
        };
    
        for (let index = numberPerPage * (currentPage - 1); index < Math.min(props.data.length, numberPerPage * currentPage); index++) {
            const isLocked = !unlocked.includes(index);
            
            tableBody.push(
                <tr key={`table-row-${index}`}>
                    <td>
                        {(props.unitTypeCode) ?
                            <Dropdown readOnly={isLocked} options={suiteOptions} value={props.data[index].unitSubtypeCode ? props.data[index].unitSubtypeCode as string : ""} onChange={e => props.onChange(e, index, "unitSubtypeCode")} ariaLabel={t("suiteBreakdownType") + " " + index}/>
                        :
                            <Dropdown readOnly={isLocked} options={suiteOptions} value={props.data[index].suiteType as string} onChange={e => props.onChange(e, index, "suiteType")} ariaLabel={t("suiteBreakdownType") + " " + index}/>
                        }
                    </td>
                    {/* Number of Units */}
                    <td className={isNegative(props.data[index].tenantUnitNumber) ? 'error-cell' : ''}>
                        {(props.unitTypeCode) ?
                            <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`num-of-units-${index}`} id={`num-of-units-${index}`} type='text' value={props.data[index].tenantUnitNumber ? props.data[index].tenantUnitNumber as string : ""} onChange={e => props.onChange(e, index, "tenantUnitNumber")} ariaLabel={t("suiteBreakdownUnits") + " " + index} positiveOnly={true}/>
                        :
                            <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`num-of-units-${index}`} id={`num-of-units-${index}`} type='number' value={props.data[index].tenantUnitNumber.toString()} onChange={e => props.onChange(e, index, "tenantUnitNumber")} ariaLabel={t("suiteBreakdownUnits") + " " + index} positiveOnly={true}/>
                        }
                        {isNegative(Number(props.data[index].tenantUnitNumber)) && <div className="error-message">Value cannot be negative</div>}
                    </td>
                    {/* Number of Beds */}
                    <td className={isNegative(props.data[index].unitCount) ? 'error-cell' : ''}>
                        {(props.unitTypeCode) ?
                            <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`num-of-beds-${index}`} id={`num-of-beds-${index}`} type='number' value={props.data[index].unitCount ? props.data[index].unitCount.toString() : ""} onChange={e => props.onChange(e, index, "unitCount", true)} ariaLabel={t("suiteBreakdownBeds") + " " + index} positiveOnly={true}/>
                        :
                            <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`num-of-beds-${index}`} id={`num-of-beds-${index}`} type='number' value={props.data[index].unitCount.toString()} onChange={e => props.onChange(e, index, "unitCount")} ariaLabel={t("suiteBreakdownBeds") + " " + index} positiveOnly={true}/>
                        }
                        {isNegative(Number(props.data[index].unitCount)) && <div className="error-message">Value cannot be negative</div>}
                    </td>
                    {/* Area in SF */}
                    <td className={isNegative(props.data[index].netLeasableArea) ? 'error-cell' : ''}>
                        {(props.unitTypeCode) ?
                            <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`area-sf-${index}`} id={`area-sf-${index}`} type='number' value={props.data[index].netLeasableArea ? props.data[index].netLeasableArea.toString() : ""} onChange={e => props.onChange(e, index, "netLeasableArea", true)} ariaLabel={t("suiteBreakdownSF") + " " + index} positiveOnly={true}/>
                        :
                            <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`area-sf-${index}`} id={`area-sf-${index}`} type='number' value={props.data[index].areaSF.toString()} onChange={e => props.onChange(e, index, "netLeasableArea")} ariaLabel={t("suiteBreakdownSF") + " " + index} positiveOnly={true}/>
                        }
                        {isNegative(Number(props.data[index].netLeasableArea)) && <div className="error-message">Value cannot be negative</div>}
                    </td>
                    {/* Actual Rent */}
                    <td className={isNegative(props.data[index].monthlyActualRent) ? 'error-cell' : ''}>
                        {(props.unitTypeCode) ?
                            <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`actual-rent-${index}`} id={`actual-rent-${index}`} type='number' value={props.data[index].monthlyActualRent ? props.data[index].monthlyActualRent.toString() : ""} onChange={e => props.onChange(e, index, "monthlyActualRent", true)} ariaLabel={t("suiteBreakdownActualRent") + " " + index} positiveOnly={true}/>
                        :
                            <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`actual-rent-${index}`} id={`actual-rent-${index}`} type='number' value={props.data[index].actualRent.toString()} onChange={e => props.onChange(e, index, "monthlyActualRent")} ariaLabel={t("suiteBreakdownActualRent") + " " + index} positiveOnly={true}/>
                        }
                        {isNegative(Number(props.data[index].monthlyActualRent)) && <div className="error-message">Value cannot be negative</div>}
                    </td>
                    {/* Market Rent */}
                    <td className={isNegative(props.data[index].monthlyMarketRent) ? 'error-cell' : ''}>
                        {(props.unitTypeCode) ?
                            <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`market-rent-${index}`} id={`market-rent-${index}`} type='number' value={props.data[index].monthlyMarketRent ? props.data[index].monthlyMarketRent.toString() : ""} onChange={e => props.onChange(e, index, "monthlyMarketRent", true)} ariaLabel={t("suiteBreakdownMarketRent") + " " + index} positiveOnly={true}/>
                        :
                            <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`market-rent-${index}`} id={`market-rent-${index}`} type='number' value={props.data[index].monthlyMarketRent.toString()} onChange={e => props.onChange(e, index, "monthlyMarketRent")} ariaLabel={t("suiteBreakdownMarketRent") + " " + index} positiveOnly={true}/>
                        }
                        {isNegative(Number(props.data[index].monthlyMarketRent)) && <div className="error-message">Value cannot be negative</div>}
                    </td>
                    {/* Typical Care Rate */}
                    <td className={isNegative(Number(props.data[index].typicalCareRate)) ? 'error-cell' : ''}>
                        <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`typical-care-rate-${index}`} id={`typical-care-rate-${index}`} type='number' value={props.data[index].typicalCareRate ? props.data[index].typicalCareRate.toString() : ""} onChange={e => props.onChange(e, index, "typicalCareRate", true)} ariaLabel={t("suiteBreakdownTypicalCare") + " " + index} positiveOnly={true}/>
                        {isNegative(Number(props.data[index].typicalCareRate)) && <div className="error-message">Value cannot be negative</div>}
                    </td>
                    {/* Second Occupant Fee */}
                    <td className={isNegative(Number(props.data[index].secondOccupantFee)) ? 'error-cell' : ''}>
                        <Input readOnly={isLocked} className={isLocked ? 'locked-input' : ''} name={`second-occupant-fee-${index}`} id={`second-occupant-fee-${index}`} type='number' value={props.data[index].secondOccupantFee ? props.data[index].secondOccupantFee.toString() : ""} onChange={e => props.onChange(e, index, "secondOccupantFee", true)} ariaLabel={t("suiteBreakdownSecondOcc") + " " + index} positiveOnly={true}/>
                        {isNegative(Number(props.data[index].secondOccupantFee)) && <div className="error-message">Value cannot be negative</div>}
                    </td>
                    <td>
                        <Button onClick={(e) => toggleLocked(e, index)} text={<FontAwesomeIcon icon={isLocked ? faLock : faLockOpen} className="trash" />} ariaLabel={"lock " + index} />
                    </td>
                    <td>
                        <Button onClick={(e) => removeUnit(e, index)} text={<FontAwesomeIcon icon={faTrashCan} className="trash" />} ariaLabel={t("remove") + " " + index} />
                    </td>
                </tr>
            );
        }
    
        return (<>{tableBody}</>);
    };
    

    const tableTitle = <div className="pier-datatable-title-container">
                            <span>{t(props.title)}</span>
                            <p className='suite-breakdown-desc'>{t("suiteBreakdownDesc")}</p>
                        </div>

    return (
        <>
            <div className='assisted-suite-breakdown-header-row'>
                <div className='suite-breakdown-btn-container'>
                    {!isReadOnly && <Button type="submit" onClick={e => addUnit(e)} text={t("add") as string} />}
                </div>
            </div>

            <DataTable 
            totalPages={Math.ceil(props.data.length / numberPerPage)} 
            titleEle={tableTitle} 
            headings={breakdownHeadings} 
            className="assisted-suite-breakdown-table"
            currentPage={currentPage}
            changePage={setCurrentPage}
            numberPerPage={numberPerPage}
            changeNumberPerPage={setNumberPerPage}>
                {buildTableContent()}
            </DataTable>
        </>
    )
}

export default SuiteBreakdownTable