export default function setSessionExpiry() : number {
  const expiryKey = localStorage.getItem("sessionExp");
  if (expiryKey) {
      const expTime = parseInt(expiryKey);
      if (!isNaN(expTime)) {
          const currentTime = Date.now();
          if (currentTime < (expTime * 1000)) {
              return expTime * 1000 - currentTime;
          }
      }        
  }
  return -1;
}