import React, { ReactElement } from "react";
import "./Avatar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";


function Avatar(): ReactElement {
    return (<>
        <div className="avatar-card">
            <span className="avatar-content"><FontAwesomeIcon icon={faUser}/></span>
        </div>
    </>)
}

export default Avatar;