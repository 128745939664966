import React from 'react';
import "./GuidedExperienceWizard.css";
import { useTranslation } from 'react-i18next';
import DataTable from '../common/DataTable/DataTable';
import RadioButton from '../common/RadioGroup/RadioButton';
import Checkbox from '../common/Checkbox/Checkbox';

interface LandLeaseWizardProps {
    updateWizardFunction : (e: React.ChangeEvent<Element>, i: number, f: string, n?: boolean, c?: boolean) => void;
    tenantList: Record<string, unknown>[];
    floorDescriptionLookup: Record<string, unknown>;
    hasLandLease: boolean | undefined;
    setHasLandLease: (value: boolean) => void;
    // for pagination
    totalPages: number | undefined;
    currentPage: number;
    changePage: (e: number) => void;
}

export default function LandLeaseWizard(props: LandLeaseWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");

    const headings = [<th key="land-lease-wizard-heading-1">{t("unit-heading.floor-level")}</th>,
        <th key="land-lease-wizard-heading-2">{t("unit-heading.tenant-name")}</th>,
        <th key="land-lease-wizard-heading-3">{t("unit-heading.unit-number")}</th>,
        <th key="land-lease-wizard-heading-4">{t("unit-heading.land-lease")}</th>]

        const buildTableContent = (): JSX.Element => {
            const tableBody: JSX.Element[] = [];
            for (let index = 10 * (props.currentPage - 1); index < Math.min(props.tenantList.length, 10 * props.currentPage); index++) {
                tableBody.push (<tr key={`land-lease-wizard-row-${index}`}>
                    <td className='no-input-cell'><label>{props.floorDescriptionLookup[props.tenantList[index]["floorLevel"] as string] as string}</label></td>
                    <td className='no-input-cell tenant-name-cell'><p>{props.tenantList[index]["tenantName"] as string}</p></td>
                    <td className='no-input-cell tenant-unit-cell'><p>{props.tenantList[index]["tenantUnitNumber"] as string}</p></td>
                    <td className='center-cell wizard-grey-out-cell'>
                        {(props.tenantList[index]["leaseStatusCode"] as string) !== "1" && <Checkbox key={`land-lease-wizard-${index}`} name={`land-lease-wizard-${index}`} id={`land-lease-wizard-${index}`}
                            defaultChecked={props.tenantList[index]["landLeaseFlag"] === true} onChange={(e) => props.updateWizardFunction(e, index, "landLeaseFlag", false, true)}/>}
                    </td>
                </tr>)
            }
            return (<>{tableBody}</>);
        }

    return (<div>
        <div className='wizard-radio-cell wizard-question'>
            <fieldset>
                <legend>{t("land-lease-wizard.land-lease-question")}</legend>
                <RadioButton name="land-lease-question" id="land-lease-question-yes" text={t("yes")} textPosition='right' value="true" currentValue={props.hasLandLease ?? ""} onChange={() => props.setHasLandLease(true)} />
                <RadioButton name="land-lease-question" id="land-lease-question-no" text={t("no")} textPosition='right' value="false" currentValue={props.hasLandLease ?? ""} onChange={() => props.setHasLandLease(false)} />
            </fieldset>
        </div>
        {props.hasLandLease === true && <DataTable headings={headings} className='wizard-table' totalPages={props.totalPages} currentPage={props.currentPage} changePage={props.changePage}>
            {buildTableContent()}
        </DataTable>}
    </div>)
}