export default function parseStatus (status: string) {
  let parsedStatus = "";
  switch (status) {
    case "NEW_CREATION":
    parsedStatus = "Start";
    break;
    case "EXTERNAL_IN_PROGRESS":
    parsedStatus = "Work in Progress";
    break;
    case "EXTERNAL_SUBMITTED":
    parsedStatus = "Submitted";
    break;
    case "INTERNAL_IN_PROGRESS":
    parsedStatus = "Internal In Progress";
    break;
    default:
    parsedStatus = "N/A";
  }
  return parsedStatus;
}