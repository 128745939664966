import React, { ReactElement, useEffect, useState } from "react";
import "./PercentInput.css"
import { useReadOnly } from "../../../utils/ReadOnlyContext";

interface PercentInputProps {
    id?: string,
    name?: string,
    value?: number | null | undefined,
    onChange?: (newAmt: number | undefined | null, name: string, oldAmt: number | undefined | null) => void,
    errormessage?: string | null | undefined,
    className?: string,
    placeholder?: string,
    title?: string,
}

function PercentInput(props: PercentInputProps): ReactElement {
    const name = props.name ? props.name : (props.id ? props.id : new Date().getTime().toString());
    const [value, setValue] = useState(props.value)
    const [displayValue, setDisplayValue] = useState(props.value?.toString());
    const { isReadOnly } = useReadOnly()

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetValue = e.target.value;
        const pattern = /^-?[0-9]*\.?[0-9]{0,2}$/;
        pattern.test(targetValue) ? setDisplayValue(targetValue) : setDisplayValue(""); 
        e.stopPropagation();
    }

    const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        const targetValue = e.target.value;
        const pattern = /^-?[0-9]*\.?[0-9]{0,2}$/;
        if (targetValue.trim() === "" || !pattern.test(targetValue)) {
            if (props.onChange) {
                props.onChange(undefined, name, value);
            }
            setValue(undefined);
            setDisplayValue("");
        }
        else {
            if (props.onChange) {
                props.onChange(parseFloat(targetValue), name, value)
            }
            setValue(parseFloat(targetValue))
            setDisplayValue(targetValue)
        }
    }

    useEffect(() => {
        setValue(props.value)
        setDisplayValue(props.value?.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value])

    return (
        <div className={"percent-wrapper"}>
            <div id={name + "-container"} className={(props.className ? props.className + " " : "") + "percent-container"}>
                <input {...props} type="text"
                    id={name} name={name} aria-label={name}
                    className={"percent-input"}
                    onChange={(e) => onChange(e)}
                    onBlur={(e) => onBlur(e)}
                    value={displayValue}
                    autoComplete="off"
                    disabled={isReadOnly}
                />
                <div className="percent-symbol">%</div>
            </div>
            {props.errormessage && (
                <p id={name + "-error"} className="form-error-message">
                    {props.errormessage}
                </p>
            )}
        </div>
    )
}

export default PercentInput;